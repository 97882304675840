<template>
  <div>
    <section
      class="intro-md"
      data-background="assets/img/bg-1.png"
      style="background-image: url('assets/img/bg-1.png')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7 m-auto">
            <div class="block-text pt-lg-5">
              <p class="section-subheading">WELCOME TO</p>
              <h2 class="section-heading">NASMOCO CLUB</h2>
              <p class="section-description-sm">
                Berbagi cerita perjalanan Nasmoco dengan berbagai klub nasmoco
                dimanapun berada.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pricelist-section section-md pt-lg-0 section-lg-top--5">
      <div class="container">
        <div class="card card-style rounded-15">
          <div class="card-body px-4">
            <div class="box-section-title mt-4">
              <h2 class="section-heading-sm">
                <span>Daftar</span> club Nasmoco
              </h2>
            </div>

            <div class="flex-3-column justify-content-start">
              <div
                class="flex-col align-self-stretch p-md-2 p-1 mb-md-0 mb-3"
                v-for="(row, index) in nasmoco_club.data"
                :key="index"
              >
                <div class="card card-product-3 club">
                  <div class="card-header">
                    <img :src="row.logo" class="img-fluid lazyloaded" />
                  </div>
                  <div class="card-body">
                    <router-link
                      :to="{
                        name: 'nasmoco_club_detail',
                        params: { link: row.seo },
                      }"
                    >
                      <h2 class="heading">{{ row.club_name }}</h2>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12 d-flex justify-content-center">
                <b-pagination
                  pills
                  v-model="page"
                  :total-rows="nasmoco_club.meta.total"
                  :per-page="nasmoco_club.meta.per_page"
                  aria-controls="nasmoco_club"
                  v-if="nasmoco_club.data && nasmoco_club.data.length > 0"
                  class="text-warning"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Nasmoco_club",
  metaInfo() {
    return {
      title:
        "Nasmoco Club - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {};
  },
  created() {
    this.getNacmocoClub();
    window.scrollTo(0, 0);
  },
  mounted() {},
  computed: {
    ...mapState("nasmoco_club", {
      loading: (state) => state.loading,
      nasmoco_club: (state) => state.nasmoco_club,
    }),
    page: {
      get() {
        return this.$store.state.nasmoco_club.page;
      },
      set(val) {
        this.$store.commit("nasmoco_club/SET_PAGE", val);
      },
    },
  },
  methods: {
    ...mapActions("nasmoco_club", ["getNacmocoClub"]),
  },
};
</script>