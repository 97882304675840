<template>
  <div>
    <section class="intro-md" data-background="assets/img/bg-1.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text pt-lg-5">
              <p class="section-subheading">FIND DEALER</p>
              <h2 class="section-heading">
                Temukan Dealer Resmi Nasmoco Terdekat
              </h2>
              <p class="section-description-sm">
                Temukan Dealer Resmi Nasmoco Terdekat Dengan jaringan dealer
                yang tersebar dengan 22 Layanan Penjualan, 23 General Repair dan
                11 Body Paint, kami siap melayani kebutuhan kendaraan Anda.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-sm journey-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 pr-lg-0">
            <div class="journey container">
              <div class="box-journey card rounded-10">
                <form method="post" class="form-style">
                  <v-select
                    class="vselect-custome trans"
                    style="width: 100%"
                    :options="kota_cabang"
                    label="city_name"
                    placeholder="Pilih Kota Cabang"
                    @input="setValIdKotaCabang"
                    :clearable="false"
                  >
                    <template slot="no-options">Kota Tidak Ditemukan</template>
                    <template slot="option" slot-scope="option">{{
                      option.city_name
                    }}</template>
                  </v-select>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="find-dealer section-md pt-lg-4">
      <div class="container">
        <div v-if="loading">
          <div class="box-find-dealer">
            <div class="row">
              <div class="col-lg-5 pr-lg-0">
                <div class="dealer-address-container">
                  <div class="wrap-dealer-address">
                    <h4 class="section-description font-weight-bold">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </h4>
                    <p>
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </p>
                    <div class="row mb-2">
                      <div class="col-md-6 mb-1">
                        <a href="">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                      <div class="col-md-6 mb-1">
                        <a href="'">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <b-skeleton type="button" class="mr-2"></b-skeleton>
                      </div>
                      <div class="col-lg-6">
                        <b-skeleton type="button"></b-skeleton>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-dealer-address">
                    <h4 class="section-description font-weight-bold">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </h4>
                    <p>
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </p>
                    <div class="row mb-2">
                      <div class="col-md-6 mb-1">
                        <a href="">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                      <div class="col-md-6 mb-1">
                        <a href="'">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <b-skeleton type="button" class="mr-2"></b-skeleton>
                      </div>
                      <div class="col-lg-6">
                        <b-skeleton type="button"></b-skeleton>
                      </div>
                    </div>
                  </div>
                  <div class="wrap-dealer-address">
                    <h4 class="section-description font-weight-bold">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </h4>
                    <p>
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </p>
                    <div class="row mb-2">
                      <div class="col-md-6 mb-1">
                        <a href="">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                      <div class="col-md-6 mb-1">
                        <a href="'">
                          <b-skeleton animation="wave" width="50%"></b-skeleton>
                        </a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <b-skeleton type="button" class="mr-2"></b-skeleton>
                      </div>
                      <div class="col-lg-6">
                        <b-skeleton type="button"></b-skeleton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 pl-lg-0">
                <div class="dealer-maps-container">
                  <b-skeleton-img no-aspect height="450px"></b-skeleton-img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="box-find-dealer">
            <div
              class="row mt-3"
              v-if="
                data_dealer.length == 0 || data_dealer == 'no data recorded'
              "
            >
              <div class="col-lg-12">
                <div class="not-found-items">
                  <svg
                    viewBox="0 0 126 128"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2612 60.4219H49.7881C51.344 60.4219 52.3813 59.3846 52.3813 57.8287V39.1577C52.3813 37.6017 51.344 36.5645 49.7881 36.5645H14.2612C12.7053 36.5645 11.668 37.6017 11.668 39.1577V57.8287C11.668 59.1253 12.7053 60.4219 14.2612 60.4219ZM16.8544 41.7509H47.1949V55.2355H16.8544V41.7509Z"
                    />
                    <path
                      d="M61.4604 60.4219H95.95C97.5059 60.4219 98.5432 59.3846 98.5432 57.8287V39.1577C98.5432 37.6017 97.5059 36.5645 95.95 36.5645H61.4604C59.9045 36.5645 58.8672 37.6017 58.8672 39.1577V57.8287C58.8672 59.1253 59.9045 60.4219 61.4604 60.4219ZM64.0536 41.7509H93.3568V55.2355H64.0536V41.7509Z"
                    />
                    <path
                      d="M13.4827 22.3021H18.6691C21.003 22.3021 23.0775 20.2276 23.0775 17.8937V12.7073C23.0775 10.1141 21.003 8.29883 18.6691 8.29883H13.4827C11.1488 8.29883 9.07422 10.3734 9.07422 12.7073V17.8937C9.07422 20.4869 10.8895 22.3021 13.4827 22.3021ZM14.2606 13.4852H17.8911V17.375H14.2606V13.4852Z"
                    />
                    <path
                      d="M30.3381 22.3021H35.5245C37.8584 22.3021 39.933 20.2276 39.933 17.8937V12.7073C39.933 10.1141 37.8584 8.29883 35.5245 8.29883H30.3381C28.0043 8.29883 25.9297 10.3734 25.9297 12.7073V17.8937C25.9297 20.4869 28.0043 22.3021 30.3381 22.3021ZM31.1161 13.4852H34.7466V17.375H31.1161V13.4852Z"
                    />
                    <path
                      d="M46.6752 17.8934H98.7986C100.355 17.8934 101.392 16.8562 101.392 15.3002C101.392 13.7443 100.355 12.707 98.7986 12.707H46.6752C45.1193 12.707 44.082 13.7443 44.082 15.3002C44.082 16.8562 45.1193 17.8934 46.6752 17.8934Z"
                    />
                    <path
                      d="M74.9428 66.9043H10.1127C8.55681 66.9043 7.51953 67.9416 7.51953 69.4975C7.51953 71.0534 8.55681 72.0907 10.1127 72.0907H74.9428C76.4988 72.0907 77.536 71.0534 77.536 69.4975C77.536 68.2009 76.2394 66.9043 74.9428 66.9043Z"
                    />
                    <path
                      d="M54.9752 80.1299H10.1127C8.55681 80.1299 7.51953 81.1672 7.51953 82.7231C7.51953 84.279 8.55681 85.3163 10.1127 85.3163H55.2345C56.7904 85.3163 57.8277 84.279 57.8277 82.7231C57.5684 81.4265 56.5311 80.1299 54.9752 80.1299Z"
                    />
                    <path
                      d="M54.9752 93.3555H10.1127C8.55681 93.3555 7.51953 94.3928 7.51953 95.9487C7.51953 97.5046 8.55681 98.5419 10.1127 98.5419H55.2345C56.7904 98.5419 57.8277 97.5046 57.8277 95.9487C57.5684 94.3928 56.5311 93.3555 54.9752 93.3555Z"
                    />
                    <path
                      d="M124.992 116.435L110.211 92.5774V9.33553C110.211 4.14913 106.062 0 100.876 0H9.33553C4.14913 0 0 4.14913 0 9.33553V102.95C0 106.84 3.37117 110.211 7.77961 110.211H64.3115L60.4217 116.435C59.125 118.509 58.8657 121.362 60.1623 123.437C61.4589 125.77 63.7928 127.067 66.386 127.067H119.028C121.621 127.067 123.955 125.77 125.252 123.437C126.289 121.103 126.289 118.509 124.992 116.435ZM9.33553 5.18641H100.876C103.21 5.18641 105.025 7.00165 105.025 9.33553V25.6727H5.18641V9.33553C5.18641 7.00165 7.00165 5.18641 9.33553 5.18641ZM7.77961 105.025C6.48301 105.025 5.44573 103.987 5.44573 102.95V30.5998H105.284V84.2791L98.5418 73.9063C97.2452 71.8318 94.9113 70.5352 92.5774 70.5352C90.2435 70.5352 87.9096 71.8318 86.613 73.9063L67.4233 105.025H7.77961ZM120.584 120.843C120.325 121.103 120.065 121.881 119.028 121.881H66.386C65.3487 121.881 64.8301 121.103 64.8301 120.843C64.5708 120.584 64.3115 119.806 64.8301 119.028L91.2808 76.4995C91.7994 75.7216 92.5774 75.7216 92.8367 75.7216C93.096 75.7216 93.874 75.7216 94.3926 76.4995L120.843 118.769C121.103 119.806 120.843 120.584 120.584 120.843Z"
                    />
                    <path
                      d="M95.4293 83.502H89.9836C88.9463 83.502 87.909 84.0206 87.131 84.7986C86.3531 85.5765 86.0938 86.6138 86.0938 87.9104L87.6497 101.654C87.909 103.729 89.4649 105.285 91.5395 105.285H93.8734C95.9479 105.285 97.7632 103.729 97.7632 101.654L99.3191 87.6511C99.3191 86.6138 99.0598 85.5765 98.2818 84.7986C97.5038 84.0206 96.4666 83.502 95.4293 83.502ZM92.8361 100.098L91.5395 88.6884H94.1327L92.8361 100.098Z"
                    />
                    <path
                      d="M92.5753 107.618C89.2041 107.618 86.3516 110.211 86.3516 113.842C86.3516 117.213 89.2041 120.066 92.5753 120.066C95.9464 120.066 98.7989 117.213 98.7989 113.842C98.7989 110.211 96.2057 107.618 92.5753 107.618ZM92.5753 114.879C92.0566 114.879 91.538 114.36 91.538 113.842C91.538 113.323 92.0566 112.805 92.5753 112.805C93.0939 112.805 93.6125 113.323 93.6125 113.842C93.6125 114.36 93.3532 114.879 92.5753 114.879Z"
                    />
                  </svg>
                  <h3>Not Found Items</h3>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-5 pr-lg-0">
                <div class="dealer-address-container">
                  <div
                    class="wrap-dealer-address"
                    v-for="(row, index) in data_dealer"
                    :key="index"
                  >
                    <h4 class="section-description font-weight-bold">
                      {{ row.cabang_name }}
                    </h4>
                    <p>
                      <i class="fa fa-map-marker-alt"></i
                      ><span> {{ row.address }}</span>
                    </p>
                    <div class="row mb-2">
                      <div class="col-md-6 mb-1">
                        <a :href="row.phone">
                          <i class="fa fa-phone"></i
                          ><span> {{ row.phone }}</span>
                        </a>
                      </div>
                      <div class="col-md-6 mb-1">
                        <a :href="'https://wa.me/' + row.whatsapp">
                          <i class="fa fa-envelope"></i
                          ><span> {{ row.whatsapp }}</span>
                        </a>
                      </div>
                    </div>
                    <a
                      href=""
                      @click.prevent="toMaps(row.maps)"
                      class="btn btn-padd btn-dark rounded mr-2"
                      ><span>FIND DEALER</span>
                      <i class="fa fa-caret-right ml-3"></i
                    ></a>
                    <a
                      @click.prevent="toProfileDealer(row.seo)"
                      class="btn btn-padd btn-green rounded"
                      ><span>PROFILE</span>
                      <i class="fa fa-caret-right ml-4"></i
                    ></a>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 pl-lg-0">
                <div class="dealer-maps-container">
                  <iframe
                    :src="loadMaps"
                    frameborder="0"
                    style="border: 0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-bottom-question">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 block-text section-md">
            <div class="box-section-title">
              <h2 class="section-heading">Have a question?</h2>
              <p class="section-description-sm">
                Apabila Anda memiliki pertanyaan seputar kendaraan Anda,
                silahkan hubungi kami melalui WhatsApp atau kunjungi dealer
                Nasmoco terdekat.
              </p>

              <div class="find-call">
                <!-- <a href="find_dealer.html" class="btn btn-padd btn-dark rounded"
                  ><span>FIND DEALER</span>
                  <i class="fa fa-caret-right ml-3"></i
                ></a> -->
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-4"
                  ><i class="fab fa-whatsapp font-weight-400 mr-2"></i
                  >WHATSAPP</a
                >
                <!-- <a href="https://wa.me/628981260060" target="_blank" class="btn btn-padd btn-white rounded"><i class="fa fa-phone mr-2"></i> <span>0898 - 1260060</span></a> -->
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 block-thumbnails bg-image center"
            data-background="assets/img/bg-2.png"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "FindDealer",
  metaInfo() {
    return {
      title:
        "Find Dealer - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {};
  },
  created() {
    this.getKotaCabang();
    // .then((res) => {
    //   this.form_find_dealer.kota_cabang = this.kota_cabang[39].c;
    // })
    // .catch((error) => {});
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState("cabang", {
      kota_cabang: (state) => state.kota_cabang,
    }),
    ...mapState("find_dealer", {
      loading: (state) => state.loading,
      form_find_dealer: (state) => state.form_find_dealer,
      loadMaps: (state) => state.loadMaps,
      data_dealer: (state) => state.data_dealer,
    }),
    loadMaps: {
      get() {
        return this.$store.state.find_dealer.loadMaps;
      },
      set(val) {
        this.$store.commit("find_dealer/SET_LOAD_MAPS", val);
      },
    },
  },
  mounted() {
    // attr data-background
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
  },
  watch: {
    form_find_dealer: {
      handler(newValue) {
        if (newValue.kota_cabang != "") {
          this.getDealer({
            city_code: newValue.kota_cabang,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("cabang", ["getKotaCabang"]),
    ...mapActions("find_dealer", ["getDealer"]),
    setValIdKotaCabang(val) {
      this.form_find_dealer.kota_cabang = val.city_code;
    },
    toMaps(maps) {
      this.loadMaps = maps;
    },
    toProfileDealer(seo) {
      this.$Progress.start();
      this.$router.push({
        name: "Find_dealer_detail",
        params: {
          link: seo,
        },
      });
      this.$Progress.finish();
    },
  },
};
</script>