<template>
  <div>
    <!-- loading slider  -->
    <div v-if="loading_car_id">
      <section class="intro-detail-product" v-if="car_sliders.length > 0">
        <b-skeleton-img no-aspect></b-skeleton-img>
        <div class="excellent container" v-if="car_featured.length > 0">
          <div class="flex-3-column">
            <div class="flex-col">
              <b-skeleton animation="wave" width="75%"></b-skeleton>
            </div>
            <div class="flex-col">
              <b-skeleton animation="wave" width="75%"></b-skeleton>
            </div>
            <div class="flex-col">
              <b-skeleton animation="wave" width="75%"></b-skeleton>
            </div>
          </div>
        </div>
      </section>
      <section class="top-heading-product">
        <!-- price car model  -->
        <div class="heading-product mt-4">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 align-self-center mb-lg-0 mb-3">
                <b-skeleton animation="wave" width="75%"></b-skeleton>
              </div>

              <div class="col-lg-4 px-lg-0 mb-lg-0 mb-3">
                <div class="price-display-2">
                  <a class="box-price simulation">
                    <b-skeleton animation="wave" width="75%"></b-skeleton>
                  </a>
                  <a class="box-price simulation">
                    <h3 class="section-description">
                      <b-skeleton animation="wave" width="75%"></b-skeleton>
                    </h3>

                    <p class="section-description-sm mb-0">
                      <b-skeleton animation="wave" width="75%"></b-skeleton>
                    </p>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 align-self-center text-sm-center text-lg-right wrap-btn-mn"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="btn-block">
                      <b-skeleton type="button"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="product-video-preview section-sm">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 m-auto">
              <b-skeleton-img no-aspect height="300px"></b-skeleton-img>
            </div>
          </div>
        </div>
      </section>
      <section class="preview-car section-sm">
        <div class="container">
          <div class="row">
            <!-- color pallete  -->
            <div class="col-lg-12">
              <div class="preview-color-car">
                <div class="nav-tabs-custome">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      class="nav-item"
                      v-for="(row_nav, index_nav) in car_color"
                      :key="'cv' + index_nav"
                    >
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href=""
                        role="tab"
                        aria-selected="true"
                      >
                        <b-skeleton type="avatar"></b-skeleton>

                        <b-skeleton animation="wave" width="55%"></b-skeleton>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- eksterior & interior  -->
            <div class="col-lg-12">
              <div class="preview-features">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="card card-style card-features rounded-10">
                      <div class="card-body">
                        <b-skeleton-img
                          no-aspect
                          height="200px"
                        ></b-skeleton-img>
                        <div class="py-5 p-relative" style="height: 65px"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card card-style card-features rounded-10">
                      <div class="card-body">
                        <b-skeleton-img
                          no-aspect
                          height="200px"
                        ></b-skeleton-img>
                        <div class="py-5 p-relative" style="height: 65px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <section class="intro-detail-product" v-if="car_sliders.length > 0">
        <!-- desktop -->
        <img
          v-for="(row_dekstop, index_desktop) in car_sliders"
          :key="'sp' + index_desktop"
          :data-src="row_dekstop.image"
          class="img-fluid lazyload img-product d-none d-md-block"
        />

        <!-- mobile -->
        <img
          v-for="(row_mobile, index_mobile) in car_sliders_mobile"
          :key="'sm' + index_mobile"
          :data-src="row_mobile.image"
          class="img-fluid lazyload img-product d-block d-md-none"
        />

        <div class="excellent container" v-if="car_featured.length > 0">
          <div class="flex-3-column">
            <div
              class="flex-col"
              v-for="(row_featured, index_featured) in car_featured"
              :key="'fr' + index_featured"
            >
              <div class="items">
                <img
                  :data-src="row_featured.icon"
                  class="img-fluid lazyload icon"
                />
                <div class="">
                  <h4>{{ row_featured.subtitle }}</h4>
                  <p class="section-description">{{ row_featured.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="top-heading-product">
        <!-- price car model  -->
        <div class="heading-product mt-4">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-4 align-self-center mb-lg-0 mb-3"
                v-if="car_model_name !== ''"
              >
                <h2 class="section-heading-xs mb-0">{{ car_model_name }}</h2>
              </div>

              <div
                class="col-lg-4 px-lg-0 mb-lg-0 mb-3"
                v-if="car_price.length != 0"
              >
                <div class="price-display-2">
                  <a
                    v-b-toggle.collapse-credit-simulation
                    class="box-price simulation"
                  >
                    <h3 class="section-description">
                      {{ car_price.price_otr | currency }}
                    </h3>
                    <p class="section-description-sm mb-0">Harga OTR</p>
                  </a>
                  <a
                    v-if="car_price.price_credit != 0"
                    v-b-toggle.collapse-credit-simulation
                    class="box-price simulation"
                  >
                    <h3
                      class="section-description"
                      v-if="loading_result_credit"
                    >
                      <b-skeleton animation="wave" width="75%"></b-skeleton>
                    </h3>
                    <h3 v-else>
                      {{ car_price.price_credit | currency }}
                    </h3>
                    <p class="section-description-sm mb-0">
                      per bulan | {{ form_credit_simulation.tenor }} Bulan
                      Cicilan
                    </p>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 px-lg-0 mb-lg-0 mb-3"
                v-if="result_credit.length != 0"
              >
                <div class="price-display-2">
                  <a
                    v-b-toggle.collapse-credit-simulation
                    class="box-price simulation"
                  >
                    <h3 class="section-description">
                      {{ result_credit.price_otr | currency }}
                    </h3>
                    <p class="section-description-sm mb-0">Harga OTR</p>
                  </a>
                  <a
                    v-b-toggle.collapse-credit-simulation
                    class="box-price simulation"
                  >
                    <h3 class="section-description">
                      {{ result_credit.angsuran | currency }}
                    </h3>
                    <p class="section-description-sm mb-0">
                      per bulan | {{ result_credit.tenor }} Bulan Cicilan
                    </p>
                  </a>
                </div>
              </div>

              <div
                class="col-lg-4 align-self-center text-sm-center text-lg-right wrap-btn-mn"
              >
                <router-link
                  :to="{ name: 'Test_drive' }"
                  :class="{
                    disabled: $v.form_credit_simulation.$invalid,
                  }"
                  class="btn btn-lg btn-accent px-3 rounded-10"
                >
                  TEST DRIVE
                  <span
                    class="icon trans-4 iconify"
                    data-inline="false"
                    data-icon="tabler:steering-wheel"
                  ></span>
                </router-link>
                <router-link
                  :to="{ name: 'Order' }"
                  :class="{
                    disabled: $v.form_credit_simulation.$invalid,
                  }"
                  class="btn btn-lg btn-yellow px-3 rounded-10 ml-2"
                >
                  BUY A CAR
                  <span
                    class="icon trans-4 iconify"
                    data-inline="false"
                    data-icon="heroicons-outline:shopping-cart"
                  ></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <b-collapse id="collapse-credit-simulation" class="wrap-simulation">
          <div class="container position-relative">
            <p class="section-description font-weight-600 opacity-7">
              Credit Simulation
            </p>

            <form method="post" class="form-style">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="control-label">Transmisi</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="Car_Transmision"
                          label="label"
                          placeholder="Pilih Transmisi Mobil"
                          @input="setValIdTransmisi"
                          :value="form_credit_simulation.car_transmision"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Transmisi tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.label
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="control-label">Tipe Mobil</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="car_type"
                          label="car_type_name"
                          placeholder="Pilih Tipe Mobil"
                          @input="setValIdCarType"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Tipe Mobil tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.car_type_name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="control-label">Uang Muka</label>
                        <money
                          class="form-control text-right equipCatValidation"
                          v-model="form_credit_simulation.uang_muka"
                        />
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="form-group">
                        <label class="control-label">Pilih Tenor</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="tenor_option"
                          label="text"
                          placeholder="Pilih Tenor"
                          @input="setValIdTenor"
                          :value="form_credit_simulation.tenor"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Tenor tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.text
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </b-collapse>

        <div class="container wrap-btn-mb">
          <div class="row">
            <div
              class="col-lg-4 align-self-center text-sm-center text-lg-right"
            >
              <router-link
                :to="{ name: 'Test_drive' }"
                :class="{
                  disabled: $v.form_credit_simulation.$invalid,
                }"
                class="btn btn-lg btn-accent px-3 rounded-10"
              >
                TEST DRIVE
                <span
                  class="icon trans-4 iconify"
                  data-inline="false"
                  data-icon="tabler:steering-wheel"
                ></span>
              </router-link>
              <router-link
                :to="{ name: 'Order' }"
                :class="{
                  disabled: $v.form_credit_simulation.$invalid,
                }"
                class="btn btn-lg btn-yellow px-3 rounded-10 ml-2"
              >
                BUY A CAR
                <span
                  class="icon trans-4 iconify"
                  data-inline="false"
                  data-icon="heroicons-outline:shopping-cart"
                ></span>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section
        class="product-video-preview section-sm"
        v-if="car_youtube.length != 0"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-10 m-auto">
              <div
                class="card-video m-auto bg-image center d-flex align-items-center justify-content-center"
              >
                <img
                  :data-src="car_youtube.cover"
                  class="img-fluid img-cover-video lazyload"
                />
                <a :href="car_youtube.url" class="popup-video">
                  <div class="play-button"><i class="fa fa-play"></i></div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="preview-car section-sm" v-if="car_color.length != 0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <div class="box-section-title">
                <h2 class="section-heading"><span>Make</span> it yours</h2>
              </div>
            </div>

            <!-- color pallete  -->
            <div class="col-lg-12">
              <div class="preview-color-car">
                <div class="nav-tabs-custome">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      class="nav-item"
                      v-for="(row_nav, index_nav) in car_color"
                      :key="'cv' + index_nav"
                    >
                      <a
                        :class="{
                          active: index_nav == 0,
                        }"
                        class="nav-link"
                        data-toggle="tab"
                        :href="'#' + row_nav.car_seo + row_nav.color"
                        role="tab"
                        aria-selected="true"
                      >
                        <div
                          class="color-product"
                          :style="{ background: row_nav.color_palette }"
                        ></div>
                        <p>{{ row_nav.color_name }}</p>
                      </a>
                    </li>
                  </ul>
                  <!-- image car  -->
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade"
                      v-for="(row_image_color, index_image_color) in car_color"
                      :key="'im' + index_image_color"
                      :class="{
                        active: index_image_color == 0,
                        show: index_image_color == 0,
                      }"
                      :id="row_image_color.car_seo + row_image_color.color"
                      role="tabpanel"
                      aria-labelledby="color1-tab"
                    >
                      <img
                        :data-src="row_image_color.image"
                        class="img-fluid lazyload"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- eksterior & interior  -->
            <div class="col-lg-12">
              <div class="preview-features" v-if="car_exterior.length != 0">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="card card-style card-features rounded-10">
                      <img
                        :src="car_exterior.cover"
                        class="img-fluid img-cover"
                      />
                      <div class="card-body">
                        <h2 class="section-heading-xs text-white mb-1">
                          Eksterior
                        </h2>
                        <div class="py-5 p-relative" style="height: 65px"></div>
                      </div>
                      <div class="card-footer">
                        <CoolLightBox
                          :items="car_exterior.gallery"
                          :index="index"
                          @close="index = null"
                        >
                        </CoolLightBox>
                        <div class="images-wrapper">
                          <div
                            class="image"
                            v-for="(
                              row_eksterior, imageIndexExterior
                            ) in car_exterior.galery"
                            :key="'ex' + imageIndexExterior"
                            :style="{
                              backgroundImage: 'url(' + row_eksterior + ')',
                            }"
                          ></div>
                          <button
                            @click="index = 0"
                            type="button"
                            class="btn btn-padd btn-yellow rounded px-4"
                          >
                            <span>LEARN MORE</span>
                            <i class="fa fa-caret-right ml-3"> </i>
                          </button>
                        </div>
                        <a
                          v-if="car_exterior_360.length != 0"
                          data-target="#eksterior360-popup"
                          data-dismiss="modal"
                          data-toggle="modal"
                        >
                          <span class="text-white mr-2"
                            ><u>Take a Tour</u></span
                          >
                          <img
                            data-src="assets/img/icon/icon-360.png"
                            class="img-fluid icon-360 lazyloaded"
                            src="assets/img/icon/icon-360.png"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6" v-if="car_interior.length != 0">
                    <div class="card card-style card-features rounded-10">
                      <img
                        :src="car_interior.cover"
                        class="img-fluid img-cover"
                      />
                      <div class="card-body">
                        <h2 class="section-heading-xs text-white mb-1">
                          Interior
                        </h2>
                        <div class="py-5 p-relative" style="height: 65px"></div>
                      </div>
                      <div class="card-footer">
                        <CoolLightBox
                          :items="car_interior.gallery"
                          :index="index_interior"
                          @close="index_interior = null"
                        >
                        </CoolLightBox>
                        <div class="images-wrapper">
                          <div
                            class="image"
                            v-for="(
                              row_interior, imageIndexInterior
                            ) in car_interior.galery"
                            :key="'in' + imageIndexInterior"
                            :style="{
                              backgroundImage: 'url(' + row_interior + ')',
                            }"
                          ></div>
                          <button
                            @click="index_interior = 0"
                            type="button"
                            class="btn btn-padd btn-yellow rounded px-4"
                          >
                            <span>LEARN MORE</span>
                            <i class="fa fa-caret-right ml-3"> </i>
                          </button>
                        </div>

                        <a
                          v-if="car_interior_360.length != 0"
                          data-target="#interior360-popup"
                          data-dismiss="modal"
                          data-toggle="modal"
                        >
                          <span class="text-white mr-2"
                            ><u>Take a Tour</u></span
                          >
                          <img
                            data-src="assets/img/icon/icon-360.png"
                            class="img-fluid icon-360 ls-is-cached lazyloaded"
                            src="assets/img/icon/icon-360.png"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-sm" v-if="owl_slider_product.length != 0">
        <div class="container">
          <div class="top-heading choose-type">
            <div class="box-section-title mb-0 text-center">
              <h2 class="section-heading mb-lg-0">
                Choose <span>a Type</span>
              </h2>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="slider-container product-slider-container">
            <div
              class="owl-carousel owl-theme product-slider"
              v-if="loaded_slider_product"
            >
              <div
                class="owl-item"
                v-for="(row_at, index_at) in owl_slider_product"
                :key="'cp' + index_at"
              >
                <div class="card card-product">
                  <div class="card-header">
                    <div class="ribbon-category">
                      {{ row_at.car_kategori }} - {{ row_at.transmision }}
                    </div>
                    <img :data-src="row_at.image" class="img-fluid lazyload" />
                  </div>

                  <div class="card-body">
                    <div class="wrap-padd">
                      <a href="">
                        <h2 class="heading">{{ row_at.car_type_name }}</h2>
                      </a>

                      <div
                        class="excellence"
                        v-for="(row_specs, index_specs) in row_at.spesification
                          .spesifikasi"
                        :key="index_specs"
                      >
                        <div class="box-excellence">
                          <p class="label">{{ row_specs.title }}</p>
                          <p class="value">{{ row_specs.value }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <router-link
                      :to="{ name: 'Order' }"
                      class="btn btn-block btn-lg btn-outline-dark font-weight-600 rounded"
                      >BUY A CAR
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="nav-dots">
              <div
                class="slider-dots slider-dots-style product-slider-dots"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section class="compare-car light">
        <!-- <div class="head-compare-car">
        <div class="container">
          <div
            class="wrap-head-compare d-md-flex align-items-center justify-content-between"
          >
            <div
              class="box-section-title mb-lg-0 mb-3 text-lg-left text-center"
            >
              <h2 class="section-heading-sm text-white mb-0">
                Compare Your Dream Car
              </h2>
            </div>
            <div class="text-lg-right text-center">
              <button
                v-b-toggle.collapse-compare-now
                type="button"
                id="btn-compare"
                class="btn btn-padd btn-yellow"
              >
                COMPARE NOW
              </button>
            </div>
          </div>
        </div>
      </div>

      <b-collapse
        class="wrap-box-compare section-sm hide-items"
        style="display: none"
        id="collapse-compare-now"
      >
        <div class="container">
          <div class="box-compare">
            <a
              class="box-compare-dismiss"
              href="javascript:void(0)"
              v-b-toggle.collapse-compare-now
              ><span aria-hidden="true">×</span></a
            >
            <div class="flex-2-column">
              <div class="flex-col">
                <div class="box-container">
                  <div class="img-product-container">
                    <img
                      data-src="assets/img/product/product-7.png"
                      class="img-fluid lazyload img-product"
                    />
                  </div>
                  <div class="form-group">
                    <p class="label">Tipe Mobil</p>
                    <select
                      name=""
                      class="select-custome opt1hid"
                      placeholder="Pilih tipe mobil"
                      style="display: none"
                    >
                      <option value="">New Fortuner</option>
                      <option value="">All New Corolla Cross</option>
                      <option value="">All New C-HR</option>
                      <option value="">Land Cruiser</option>
                      <option value="">All New Rush</option>
                    </select>
                    <div
                      class="nice-select select-custome opt1hid"
                      tabindex="0"
                    >
                      <span class="current">Pilih tipe mobil</span>
                      <div class="nice-select-dropdown">
                        <ul class="list">
                          <li data-value="" class="option null">
                            New Fortuner
                          </li>
                          <li data-value="" class="option null">
                            All New Corolla Cross
                          </li>
                          <li data-value="" class="option null">
                            All New C-HR
                          </li>
                          <li data-value="" class="option null">
                            Land Cruiser
                          </li>
                          <li data-value="" class="option null">
                            All New Rush
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-col">
                <div class="box-container">
                  <div class="img-product-container">
                    <div class="default-image">
                      <img
                        data-src="assets/img/icon/icon-default-compare.png"
                        class="img-fluid lazyload"
                      />
                      <p class="section-description-sm">
                        Pilih Mobil untuk diperbandingkan
                      </p>
                    </div>
                  </div>
                  <div class="form-group">
                    <p class="label">Tipe Mobil</p>
                    <select
                      name=""
                      class="select-custome opt1hid"
                      placeholder="Pilih tipe mobil"
                      style="display: none"
                    >
                      <option value="">New Fortuner</option>
                      <option value="">All New Corolla Cross</option>
                      <option value="">All New C-HR</option>
                      <option value="">Land Cruiser</option>
                      <option value="">All New Rush</option>
                    </select>
                    <div
                      class="nice-select select-custome opt1hid"
                      tabindex="0"
                    >
                      <span class="current">Pilih tipe mobil</span>
                      <div class="nice-select-dropdown">
                        <ul class="list">
                          <li data-value="" class="option null">
                            New Fortuner
                          </li>
                          <li data-value="" class="option null">
                            All New Corolla Cross
                          </li>
                          <li data-value="" class="option null">
                            All New C-HR
                          </li>
                          <li data-value="" class="option null">
                            Land Cruiser
                          </li>
                          <li data-value="" class="option null">
                            All New Rush
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box-bottom">
              <a
                href="#result-compare-modal"
                data-dismiss="modal"
                data-toggle="modal"
                class="btn btn-padd btn-yellow"
                >COMPARE NOW</a
              >
            </div>
          </div>
        </div>
      </b-collapse>

      <hr /> -->

        <div class="head-compare-car" v-if="cta != ''">
          <div class="container">
            <div
              class="wrap-head-compare d-md-flex align-items-center justify-content-between"
            >
              <div
                class="box-section-title mb-lg-0 mb-3 text-lg-left text-center"
              >
                <h2 class="section-heading-sm text-white mb-0">
                  Do you have some questions about our service?
                </h2>
              </div>
              <div class="text-lg-right text-center">
                <a
                  :href="cta"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp py-3 px-4"
                  ><i class="fab fa-whatsapp font-weight-200 mr-2"></i>WHATSAPP
                  MARKETING</a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="modal fade modal-style" id="eksterior360-popup">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style p-0">
            <div id="eksterior360" style="width: 100%; height: 400px">
              <vue-three-sixty
                autoplay
                :amount="18"
                :imagePath="car_exterior_360.path"
                :fileName="car_exterior_360.filename"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-style"
      id="interior360-popup"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style p-0">
            <div style="height: 400px" id="interior360"></div>
          </div>
          <!-- <v-pannellum
            :src="this.car_interior_360"
            style="height: 500px"
          ></v-pannellum> -->
        </div>
      </div>
    </div>

    <div class="modal fade modal-style style-3" id="result-compare-modal">
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
        style="max-width: 1000px"
      >
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close text-dark"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>

            <div class="specification-product pt-3">
              <table class="table table-striped table-specification">
                <tbody>
                  <tr class="header">
                    <td></td>
                    <td>
                      <div class="img-wrap">
                        <img
                          data-src="assets/img/product/product-5.png"
                          class="img-fluid lazyload"
                        />
                      </div>
                      <h3 class="section-description-md">New Fortuner</h3>
                      <p class="section-description-sm">Rp 504.900.000</p>
                    </td>
                    <td>
                      <div class="img-wrap">
                        <img
                          data-src="assets/img/product/product-6.png"
                          class="img-fluid lazyload"
                        />
                      </div>
                      <h3 class="section-description-md">New Fortuner</h3>
                      <p class="section-description-sm">Rp 504.900.000</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div id="accordion" class="accordion wrap-specification-collapse">
                <div class="card">
                  <a
                    class="card-header"
                    data-toggle="collapse"
                    href="#spesifikasi0"
                    aria-expanded="false"
                  >
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      DIMENSI
                    </h6>
                  </a>
                  <div
                    id="spesifikasi0"
                    class="collapse show"
                    data-parent="#accordion"
                    style=""
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody>
                          <tr>
                            <td>Panjang x Lebar x Tinggi Keseluruhan</td>
                            <td>4.190 x 1.660 x 1.695 mm</td>
                            <td>4.190 x 1.660 x 1.695 mm</td>
                          </tr>
                          <tr>
                            <td>Jarak Sumbu Roda</td>
                            <td>2655 mm</td>
                            <td>2655 mm</td>
                          </tr>
                          <tr>
                            <td>Jarak Pijak Roda Depan</td>
                            <td>1,425 mm</td>
                            <td>1,425 mm</td>
                          </tr>
                          <tr>
                            <td>Jarak Pijak Roda Belakang</td>
                            <td>1435 mm</td>
                            <td>1435 mm</td>
                          </tr>
                          <tr>
                            <td>Tinggi dari Tanah</td>
                            <td>200 mm</td>
                            <td>200 mm</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <a
                    class="card-header collapsed"
                    data-toggle="collapse"
                    href="#spesifikasi1"
                    aria-expanded="false"
                  >
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      SPESIFIKASI MESIN
                    </h6>
                  </a>
                  <div
                    id="spesifikasi1"
                    class="collapse"
                    data-parent="#accordion"
                    style=""
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody>
                          <tr>
                            <td>Tipe Mesin / Engine Type</td>
                            <td>
                              2TR-FE 4 Silinder Segaris, 4 Katup, DOHC, Dual
                              VVT-i / 2TR FE In-Line 4 Cylinders
                            </td>
                            <td>
                              2TR-FE 4 Silinder Segaris, 4 Katup, DOHC, Dual
                              VVT-i / 2TR FE In-Line 4 Cylinders
                            </td>
                          </tr>
                          <tr>
                            <td>Isi Silinder / Displacement (cc)</td>
                            <td>2,694</td>
                            <td>2,694</td>
                          </tr>
                          <tr>
                            <td>Diameter x Langkah / Bore x Stroke</td>
                            <td>95.0 X 95.0</td>
                            <td>95.0 X 95.0</td>
                          </tr>
                          <tr>
                            <td>Daya Maksimum / Max. Power (ps/rpm)</td>
                            <td>163/3,400</td>
                            <td>163/3,400</td>
                          </tr>
                          <tr>
                            <td>Torsi Maksimum / Max. Torque (kgm/rpm)</td>
                            <td>24.7/4,000</td>
                            <td>24.7/4,000</td>
                          </tr>
                          <tr>
                            <td>Jenis Bahan Bakar / Fuel Type</td>
                            <td>Bensin Tanpa Timbal / Unleaded Gasoline</td>
                            <td>Bensin Tanpa Timbal / Unleaded Gasoline</td>
                          </tr>
                          <tr>
                            <td>Sistem Bahan Bakar / Fuel System</td>
                            <td>Sistem Injeksi / Fuel Injection</td>
                            <td>Sistem Injeksi / Fuel Injection</td>
                          </tr>
                          <tr>
                            <td>
                              Kapasitas Bahan Bakar / Fuel Tank Capacity (ltr)
                            </td>
                            <td>80</td>
                            <td>80</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <a
                    class="card-header collapsed"
                    data-toggle="collapse"
                    href="#spesifikasi2"
                    aria-expanded="false"
                  >
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      KESELAMATAN DAN KEAMANAN
                    </h6>
                  </a>
                  <div
                    id="spesifikasi2"
                    class="collapse"
                    data-parent="#accordion"
                    style=""
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody>
                          <tr>
                            <td>Smart Access Card Entry</td>
                            <td><span class="ion-close"></span></td>
                            <td><span class="ion-close"></span></td>
                          </tr>
                          <tr>
                            <td>Alarm Mobil</td>
                            <td><span class="ion-checkmark"></span></td>
                            <td><span class="ion-checkmark"></span></td>
                          </tr>
                          <tr>
                            <td>Power Door Lock</td>
                            <td><span class="ion-close"></span></td>
                            <td><span class="ion-close"></span></td>
                          </tr>
                          <tr>
                            <td>Engine Immobilizer</td>
                            <td><span class="ion-checkmark"></span></td>
                            <td><span class="ion-checkmark"></span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <a
                    class="card-header collapsed"
                    data-toggle="collapse"
                    href="#spesifikasi3"
                    aria-expanded="false"
                  >
                    <h6 class="section-description-sm mb-0 d-inline-block">
                      CHASSIS
                    </h6>
                  </a>
                  <div
                    id="spesifikasi3"
                    class="collapse"
                    data-parent="#accordion"
                    style=""
                  >
                    <div class="card-body p-0">
                      <table class="table table-striped table-specification">
                        <tbody>
                          <tr>
                            <td>Sistem Penggerak Roda / Drive System</td>
                            <td>Sistem Penggerak 2 Roda / Two-Wheel Drive</td>
                            <td>Sistem Penggerak 2 Roda / Two-Wheel Drive</td>
                          </tr>
                          <tr>
                            <td>Transmisi / Transmission</td>
                            <td>
                              Otomatis 6 Tingkat Kecepatan dengan Sport
                              Sequential Switchmatic / 6 Speed Automatic with
                              Sport Sequential Switchmatic
                            </td>
                            <td>
                              Otomatis 6 Tingkat Kecepatan dengan Sport
                              Sequential Switchmatic / 6 Speed Automatic with
                              Sport Sequential Switchmatic
                            </td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (1st)</td>
                            <td>3.600</td>
                            <td>3.600</td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (2nd)</td>
                            <td>2.090</td>
                            <td>2.090</td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (3rd)</td>
                            <td>1.488</td>
                            <td>1.488</td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (4th)</td>
                            <td>1.000</td>
                            <td>1.000</td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (5th)</td>
                            <td>0.687</td>
                            <td>0.687</td>
                          </tr>
                          <tr>
                            <td>Perbandingan Gigi / Gear Ratio (6th)</td>
                            <td>0.580</td>
                            <td>0.580</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://ufotostudio.com/nsmc/assets/vendors/360viewer/js-cloudimage-360-view.js"></script>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "DetailCar",
  metaInfo() {
    return {
      title: `${this.meta_title} - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta`,
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  components: {
    CoolLightBox,
  },

  data() {
    return {
      index: null,
      index_interior: null,
      filter_car_transmisi: "AT",
      tenor_option: [
        { text: "12 bulan", value: 12 },
        { text: "24 bulan", value: 24 },
        { text: "36 bulan", value: 36 },
        { text: "48 bulan", value: 48 },
        { text: "60 bulan", value: 60 },
      ],
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      // sliders product
      loaded_slider_product: false,
      owl_slider_product: [],
    };
  },
  validations: {
    form_credit_simulation: {
      car_model_id: { required },
      car_model_name: { required },
      car_transmision: { required },
      car_model_type: { required },
      car_model_type_name: { required },
      tenor: { required },
      uang_muka: { required },
    },
  },
  watch: {
    form_credit_simulation: {
      handler(newValue) {
        if (newValue.car_transmision != "" && newValue.car_model_id) {
          this.getCarType({
            car_model_id: newValue.car_model_id,
            car_transmission: newValue.car_transmision,
          });
        }
        if (
          newValue.car_transmision != "" &&
          newValue.car_model_id != "" &&
          newValue.car_model_type != "" &&
          newValue.uang_muka != 0 &&
          newValue.tenor != 0
        ) {
          this.getSimulationCar({
            car_model_id: newValue.car_model_id,
            car_model_transmission: newValue.car_transmision,
            car_model_type: newValue.car_model_type,
            uang_muka: newValue.uang_muka,
            tenor: newValue.tenor,
          });
        }
        // $(".equipCatValidation").on("keyup keydown", function (e) {
        //   if (
        //     newValue.jumlah_bayar > newValue.setelah_diskon &&
        //     e.keyCode !== 46 &&
        //     e.keyCode !== 8
        //   ) {
        //     e.preventDefault();
        //     d.jumlah_bayar = d.setelah_diskon;
        //     d.jumlah_bayar > d.setelah_diskon;
        //   }
        // });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("detail_car", {
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,

      loading_car_id: (state) => state.loading_car_id,
      meta_title: (state) => state.meta_title,
      car_model_name: (state) => state.car_model_name,
      car_model_seo: (state) => state.car_model_seo,
      car_model_id: (state) => state.car_model_id,
      car_sliders: (state) => state.car_sliders,
      car_sliders_mobile: (state) => state.car_sliders_mobile,
      car_price: (state) => state.car_price,
      car_featured: (state) => state.car_featured,
      car_youtube: (state) => state.car_youtube,
      car_color: (state) => state.car_color,
      car_exterior: (state) => state.car_exterior,
      car_exterior_360: (state) => state.car_exterior_360,
      car_interior: (state) => state.car_interior,
      car_interior_360: (state) => state.car_interior_360,

      loading_car_type: (state) => state.loading_car_type,
      car_detail_type: (state) => state.car_detail_type,
      cta: (state) => state.cta,

      form_credit_simulation: (state) => state.form_credit_simulation,
      loading_result_credit: (state) => state.loading_result_credit,
      result_credit: (state) => state.result_credit,
    }),
  },
  methods: {
    ...mapActions("detail_car", [
      "getDetailCarByID",
      "getCarModel",
      "getCarType",
      "getSimulationCar",
      "getDetailCarByIDType",
      "getDetailCarByIDEksteriorGalery",
      "Clear_State",
    ]),
    indexGalery(index) {
      this.index = index;
    },
    setValIdTransmisi(val) {
      this.form_credit_simulation.car_transmision = val.value;
    },
    setValIdTransmisiFilter(val) {
      this.filter_car_transmisi = val.value;
    },
    setValIdCarType(val) {
      this.form_credit_simulation.car_model_type = val.car_type_id;
      this.form_credit_simulation.car_model_type_name = val.car_type_name;
    },
    setValIdTenor(val) {
      this.form_credit_simulation.tenor = val.value;
    },
    gotoEksteriorModal() {
      $("#eksterior-popup").modal("show");
    },
    // produk slider
    OwlCarouselRecomendedProduct: function () {
      if ($(".product-slider").length) {
        var productSlider = $(".product-slider");

        productSlider.owlCarousel({
          loop: true,
          autoplay: true,
          speed: 3800,
          smartSpeed: 2000,
          nav: false,
          autoWidth: false,
          // dots   	  : false,
          dots: true,
          dotsContainer: ".slider-dots",
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              margin: 20,
              stagePadding: 40,
            },
            576: {
              items: 2,
              margin: 20,
            },
            768: {
              items: 3,
              margin: 20,
            },
            992: {
              items: 3,
              margin: 20,
            },
            1200: {
              items: 4,
              margin: 20,
            },
          },
        });

        if ($(".product-slider-prev").length) {
          var prev = $(".product-slider-prev");
          prev.on("click", function () {
            productSlider.trigger("prev.owl.carousel");
          });
        }

        if ($(".product-slider-next").length) {
          var next = $(".product-slider-next");
          next.on("click", function () {
            productSlider.trigger("next.owl.carousel");
          });
        }
      }
    },

    panellumViewer: function () {
      pannellum.viewer("interior360", {
        type: "cubemap",
        cubeMap: this.car_interior_360,
      });
    },

    PopupVideo: function () {
      $(".popup-video").magnificPopup({
        disableOn: 320,
        type: "iframe",
        removalDelay: 150,
        preloader: false,
        fixedContentPos: false,
      });
    },
  },
  mounted() {
    // car product
    var vm = this;
    this.getDetailCarByIDType({
      car_seo: this.car_model_seo,
    })
      .then((res) => {
        vm.owl_slider_product = res.data.car_type;
        if (vm.owl_slider_product.length > 0) {
          vm.loaded_slider_product = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselRecomendedProduct();
              vm.panellumViewer();
              vm.PopupVideo();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });
    $("[background-color]").each(function () {
      $(this).css("background-color", $(this).attr("background-color"));
    });
  },
  created() {
    this.getDetailCarByID(this.$route.params.link);
    this.getCarType({
      car_model_id: this.form_credit_simulation.car_model_id,
      car_transmission: this.form_credit_simulation.car_transmision,
    });
    window.scrollTo(0, 0);
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>