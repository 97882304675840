<template>
  <div>
    <header id="header" class="header-main">
      <div class="main-header-menu-wrap fixed-top">
        <div class="container">
          <nav class="js-mega-menu navbar navbar-expand-md header-nav">
            <router-link class="navbar-brand" :to="{ name: 'Home' }">
              <img
                src="/assets/img/logo-nasmoco.png"
                width="250"
                alt=""
                class="img-fluid header-logo"
              />
            </router-link>

            <button
              type="button"
              class="navbar-toggler btn"
              aria-expanded="false"
              aria-controls="navBar"
              data-toggle="collapse"
              data-target="#navBar"
            >
              <span id="hamburgerTrigger"><span class="ti-menu"></span></span>
            </button>

            <div id="navBar" class="collapse navbar-collapse">
              <ul class="navbar-nav ml-auto main-navbar-nav">
                <li
                  class="nav-item hs-has-mega-menu custom-nav-item"
                  data-position="center"
                >
                  <a
                    id="homeMegaMenu"
                    class="nav-link custom-nav-link main-link-toggle"
                    href="javascript:void(0);"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >VEHICLES</a
                  >

                  <div
                    class="hs-mega-menu main-sub-menu w-100"
                    aria-labelledby="homeMegaMenu"
                  >
                    <div class="row no-gutters">
                      <div class="col-lg-12">
                        <div class="menu-item-wrap">
                          <div class="nav-tabs-style p-0">
                            <!-- navtab -->
                            <ul
                              class="nav nav-tabs nav-tabs-sm"
                              id="myTab"
                              role="tablist"
                            >
                              <li
                                class="nav-item"
                                v-for="(row, index) in vehicles"
                                :key="index"
                              >
                                <a
                                  :class="{
                                    active: index == 0,
                                  }"
                                  class="nav-link"
                                  data-toggle="tab"
                                  :href="'#head' + row.category_name"
                                  role="tab"
                                  aria-selected="true"
                                  >{{ row.category_name }}</a
                                >
                              </li>
                            </ul>
                            <!-- tab content loading -->
                            <div
                              class="tab-content nav-tabs-content"
                              id="v-pills-tabContent"
                              rounded="sm"
                            >
                              <div
                                v-for="(row, index) in vehicles"
                                :key="index"
                                :class="{
                                  active: index == 0,
                                  show: index == 0,
                                }"
                                class="tab-pane fade"
                                :id="'head' + row.category_name"
                                role="tabpanel"
                              >
                                <div
                                  class="flex-4-column mt-2 justify-content-start"
                                >
                                  <div
                                    v-if="row.car_model === 'no data recorded'"
                                    class="not-found-items"
                                  >
                                    <svg
                                      viewBox="0 0 126 128"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M14.2612 60.4219H49.7881C51.344 60.4219 52.3813 59.3846 52.3813 57.8287V39.1577C52.3813 37.6017 51.344 36.5645 49.7881 36.5645H14.2612C12.7053 36.5645 11.668 37.6017 11.668 39.1577V57.8287C11.668 59.1253 12.7053 60.4219 14.2612 60.4219ZM16.8544 41.7509H47.1949V55.2355H16.8544V41.7509Z"
                                      />
                                      <path
                                        d="M61.4604 60.4219H95.95C97.5059 60.4219 98.5432 59.3846 98.5432 57.8287V39.1577C98.5432 37.6017 97.5059 36.5645 95.95 36.5645H61.4604C59.9045 36.5645 58.8672 37.6017 58.8672 39.1577V57.8287C58.8672 59.1253 59.9045 60.4219 61.4604 60.4219ZM64.0536 41.7509H93.3568V55.2355H64.0536V41.7509Z"
                                      />
                                      <path
                                        d="M13.4827 22.3021H18.6691C21.003 22.3021 23.0775 20.2276 23.0775 17.8937V12.7073C23.0775 10.1141 21.003 8.29883 18.6691 8.29883H13.4827C11.1488 8.29883 9.07422 10.3734 9.07422 12.7073V17.8937C9.07422 20.4869 10.8895 22.3021 13.4827 22.3021ZM14.2606 13.4852H17.8911V17.375H14.2606V13.4852Z"
                                      />
                                      <path
                                        d="M30.3381 22.3021H35.5245C37.8584 22.3021 39.933 20.2276 39.933 17.8937V12.7073C39.933 10.1141 37.8584 8.29883 35.5245 8.29883H30.3381C28.0043 8.29883 25.9297 10.3734 25.9297 12.7073V17.8937C25.9297 20.4869 28.0043 22.3021 30.3381 22.3021ZM31.1161 13.4852H34.7466V17.375H31.1161V13.4852Z"
                                      />
                                      <path
                                        d="M46.6752 17.8934H98.7986C100.355 17.8934 101.392 16.8562 101.392 15.3002C101.392 13.7443 100.355 12.707 98.7986 12.707H46.6752C45.1193 12.707 44.082 13.7443 44.082 15.3002C44.082 16.8562 45.1193 17.8934 46.6752 17.8934Z"
                                      />
                                      <path
                                        d="M74.9428 66.9043H10.1127C8.55681 66.9043 7.51953 67.9416 7.51953 69.4975C7.51953 71.0534 8.55681 72.0907 10.1127 72.0907H74.9428C76.4988 72.0907 77.536 71.0534 77.536 69.4975C77.536 68.2009 76.2394 66.9043 74.9428 66.9043Z"
                                      />
                                      <path
                                        d="M54.9752 80.1299H10.1127C8.55681 80.1299 7.51953 81.1672 7.51953 82.7231C7.51953 84.279 8.55681 85.3163 10.1127 85.3163H55.2345C56.7904 85.3163 57.8277 84.279 57.8277 82.7231C57.5684 81.4265 56.5311 80.1299 54.9752 80.1299Z"
                                      />
                                      <path
                                        d="M54.9752 93.3555H10.1127C8.55681 93.3555 7.51953 94.3928 7.51953 95.9487C7.51953 97.5046 8.55681 98.5419 10.1127 98.5419H55.2345C56.7904 98.5419 57.8277 97.5046 57.8277 95.9487C57.5684 94.3928 56.5311 93.3555 54.9752 93.3555Z"
                                      />
                                      <path
                                        d="M124.992 116.435L110.211 92.5774V9.33553C110.211 4.14913 106.062 0 100.876 0H9.33553C4.14913 0 0 4.14913 0 9.33553V102.95C0 106.84 3.37117 110.211 7.77961 110.211H64.3115L60.4217 116.435C59.125 118.509 58.8657 121.362 60.1623 123.437C61.4589 125.77 63.7928 127.067 66.386 127.067H119.028C121.621 127.067 123.955 125.77 125.252 123.437C126.289 121.103 126.289 118.509 124.992 116.435ZM9.33553 5.18641H100.876C103.21 5.18641 105.025 7.00165 105.025 9.33553V25.6727H5.18641V9.33553C5.18641 7.00165 7.00165 5.18641 9.33553 5.18641ZM7.77961 105.025C6.48301 105.025 5.44573 103.987 5.44573 102.95V30.5998H105.284V84.2791L98.5418 73.9063C97.2452 71.8318 94.9113 70.5352 92.5774 70.5352C90.2435 70.5352 87.9096 71.8318 86.613 73.9063L67.4233 105.025H7.77961ZM120.584 120.843C120.325 121.103 120.065 121.881 119.028 121.881H66.386C65.3487 121.881 64.8301 121.103 64.8301 120.843C64.5708 120.584 64.3115 119.806 64.8301 119.028L91.2808 76.4995C91.7994 75.7216 92.5774 75.7216 92.8367 75.7216C93.096 75.7216 93.874 75.7216 94.3926 76.4995L120.843 118.769C121.103 119.806 120.843 120.584 120.584 120.843Z"
                                      />
                                      <path
                                        d="M95.4293 83.502H89.9836C88.9463 83.502 87.909 84.0206 87.131 84.7986C86.3531 85.5765 86.0938 86.6138 86.0938 87.9104L87.6497 101.654C87.909 103.729 89.4649 105.285 91.5395 105.285H93.8734C95.9479 105.285 97.7632 103.729 97.7632 101.654L99.3191 87.6511C99.3191 86.6138 99.0598 85.5765 98.2818 84.7986C97.5038 84.0206 96.4666 83.502 95.4293 83.502ZM92.8361 100.098L91.5395 88.6884H94.1327L92.8361 100.098Z"
                                      />
                                      <path
                                        d="M92.5753 107.618C89.2041 107.618 86.3516 110.211 86.3516 113.842C86.3516 117.213 89.2041 120.066 92.5753 120.066C95.9464 120.066 98.7989 117.213 98.7989 113.842C98.7989 110.211 96.2057 107.618 92.5753 107.618ZM92.5753 114.879C92.0566 114.879 91.538 114.36 91.538 113.842C91.538 113.323 92.0566 112.805 92.5753 112.805C93.0939 112.805 93.6125 113.323 93.6125 113.842C93.6125 114.36 93.3532 114.879 92.5753 114.879Z"
                                      />
                                    </svg>
                                    <h3>Not Found Items</h3>
                                  </div>
                                  <div
                                    v-else
                                    class="flex-col"
                                    v-for="(
                                      row_content, index_content
                                    ) in row.car_model"
                                    :key="index_content"
                                  >
                                    <div
                                      class="card card-product-2"
                                      v-if="
                                        row.category_name ==
                                        row_content.car_category
                                      "
                                    >
                                      <div class="card-header">
                                        <a
                                          @click.prevent="
                                            toDetailCar(row_content.car_seo)
                                          "
                                        >
                                          <img
                                            :data-src="row_content.image"
                                            class="img-fluid lazyload"
                                          />
                                        </a>
                                      </div>
                                      <div class="card-body">
                                        <div class="wrap-padd">
                                          <a
                                            @click.prevent="
                                              toDetailCar(row_content.car_seo)
                                            "
                                          >
                                            <h2 class="heading mb-0">
                                              {{ row_content.name }}
                                            </h2>
                                          </a>
                                          <hr />
                                          <p
                                            class="section-description-xs font-weight-bold mb-1"
                                          >
                                            MULAI DARI
                                          </p>
                                          <div class="price-display-1">
                                            <div class="box-price">
                                              <p class="price">
                                                {{
                                                  row_content.price_otr
                                                    | currency
                                                }}
                                              </p>
                                              <!-- <del>{{
                                                row_content.price_disc | currency
                                              }}</del> -->
                                            </div>
                                            <a
                                              @click.prevent="
                                                toDetailCar(row_content.car_seo)
                                              "
                                              class="link"
                                              >PREVIEW
                                              <i
                                                class="fa fa-chevron-right text-accent"
                                              ></i
                                            ></a>
                                          </div>

                                          <hr class="mb-0" />

                                          <!-- <div class="price-display-2">
                                            <div class="box-price">
                                              <p class="price">
                                                {{
                                                  row_content.price_otr
                                                    | currency
                                                }}
                                              </p>
                                              <p
                                                class="section-description-xs mb-0"
                                              >
                                                Harga OTR
                                              </p>
                                            </div>
                                            <div class="box-price">
                                              <p class="price">
                                                {{
                                                  row_content.price_credit
                                                    | currency
                                                }}
                                              </p>
                                              <p
                                                class="section-description-xs mb-0"
                                              >
                                                per bulan | 60 Bulan Cicilan
                                              </p>
                                            </div>
                                          </div> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="text-center mt-2">
                                  <a
                                    href="pricelist_page.html"
                                    class="btn btn-padd btn-outline-dark btn-expl"
                                    >Explore More
                                    <i
                                      class="font-14 ml-2 fa fa-arrow-right"
                                    ></i
                                  ></a>
                                </div> -->
                              </div>
                            </div>
                            <!-- tab content not loading-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li class="nav-item custom-nav-item">
                  <router-link
                    @click="closeNavbar"
                    :to="{ name: 'After-sale' }"
                    class="nav-link custom-nav-link"
                  >
                    AFTER SALES
                  </router-link>
                </li>

                <li class="nav-item custom-nav-item">
                  <router-link
                    @click="closeNavbar"
                    :to="{ name: 'Promo' }"
                    class="nav-link custom-nav-link"
                  >
                    NASMOCO UPDATE
                  </router-link>
                </li>

                <li class="nav-item custom-nav-item">
                  <router-link
                    @click="closeNavbar"
                    :to="{ name: 'Trade_in' }"
                    class="nav-link custom-nav-link"
                  >
                    TRADE IN
                  </router-link>
                </li>

                <li class="nav-item hs-has-sub-menu custom-nav-item">
                  <a
                    id="pagesMegaMenu"
                    class="nav-link custom-nav-link main-link-toggle"
                    href="javascript:void(0);"
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="pagesSubMenu"
                  >
                    <div>
                      <img
                        src="/assets/img/nav-nasmoco-journey-white.png"
                        alt=""
                        class="img-fluid icon-nav"
                      /><br />
                      NASMOCO JOURNEY
                    </div>
                  </a>

                  <ul
                    id="pagesSubMenu"
                    class="hs-sub-menu main-sub-menu"
                    aria-labelledby="pagesMegaMenu"
                    style="min-width: 180px"
                  >
                    <li class="nav-item submenu-item">
                      <router-link
                        @click="closeNavbar"
                        :to="{ name: 'nasmoco_club' }"
                        class="nav-link sub-menu-nav-link"
                        >Nasmoco Club
                      </router-link>
                    </li>
                    <li class="nav-item submenu-item">
                      <router-link
                        @click="closeNavbar"
                        :to="{ name: 'Nasmoco_journey' }"
                        class="nav-link sub-menu-nav-link"
                        >Find Dream Car
                      </router-link>
                    </li>
                    <li class="nav-item submenu-item">
                      <a
                        @click="ToDashboardMember()"
                        class="nav-link sub-menu-nav-link"
                        >Member Area</a
                      >
                    </li>
                  </ul>
                </li>

                <!-- logout  -->
                <li
                  v-if="isAuth"
                  class="nav-item header-nav-last-item d-flex align-items-center"
                >
                  <a class="btn btn-auth btn-sm" @click="logout">
                    <i class="fas font-20 fa-sign-out-alt"></i>
                  </a>
                </li>

                <!-- login  -->
                <li
                  v-if="!isAuth"
                  class="nav-item header-nav-last-item d-flex align-items-center"
                >
                  <a
                    class="btn btn-auth btn-sm"
                    data-toggle="modal"
                    @click="Modalogin()"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.6667 18.6667V20.0834C19.6667 21.2106 19.2189 22.2916 18.4219 23.0886C17.6248 23.8856 16.5438 24.3334 15.4167 24.3334H5.5C4.37283 24.3334 3.29183 23.8856 2.4948 23.0886C1.69777 22.2916 1.25 21.2106 1.25 20.0834V5.91675C1.25 4.78958 1.69777 3.70857 2.4948 2.91154C3.29183 2.11451 4.37283 1.66675 5.5 1.66675H15.4167C16.5438 1.66675 17.6248 2.11451 18.4219 2.91154C19.2189 3.70857 19.6667 4.78958 19.6667 5.91675V7.33341M12.5833 18.6667L6.91667 13.0001L12.5833 18.6667ZM6.91667 13.0001L12.5833 7.33341L6.91667 13.0001ZM6.91667 13.0001H26.75H6.91667Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <span>Login/Register</span>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!-- login modal  -->
    <div class="modal fade modal-style" id="login-modal">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>

            <h3 class="section-heading-xs">Login:</h3>
            <p class="section-description-sm">
              Gabung bersama Nasmoco Group dan dapatkan update informsai terkini
            </p>

            <p class="section-description-sm">
              <b
                >Login or
                <a
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#registrasi-modal"
                  class="text-blue"
                  @click="ModalRegister()"
                  >Create an Account?</a
                ></b
              >
            </p>
            <form @submit.prevent="submit" class="form-style mb-4">
              <div class="form-group mb-1">
                <input
                  type="text"
                  v-model.trim="$v.form_login.email.$model"
                  :class="{ 'is-invalid': $v.form_login.email.$error }"
                  name=""
                  class="form-control form-control-lg"
                  placeholder="Your email"
                />
                <div class="invalid-feedback" v-if="!$v.form_login.email.email">
                  *Email tidak valid!.
                </div>
                <div
                  class="invalid-feedback"
                  v-if="!$v.form_login.email.required"
                >
                  *Masukkan Email!.
                </div>
              </div>
              <div class="form-group">
                <input
                  id="InputPassword"
                  v-model.trim="$v.form_login.password.$model"
                  :class="{ 'is-invalid': $v.form_login.password.$error }"
                  type="password"
                  name=""
                  class="form-control form-control-lg"
                  placeholder="Password"
                />
                <span
                  toggle="#InputPassword"
                  class="fa fa-fw fa-eye field-icon toggle-password"
                ></span>
                <div
                  class="invalid-feedback"
                  v-if="!$v.form_login.password.required"
                >
                  *Masukkan Password!.
                </div>
                <div
                  class="invalid-feedback"
                  v-if="!$v.form_login.password.minLength"
                >
                  *Password minimal 8 karakter!.
                </div>
              </div>
              <button
                v-if="!isLogin"
                type="submit"
                class="btn btn-accent btn-block py-2 font-weight-600"
                :disabled="$v.form_login.$invalid"
                @click.prevent="postLogin"
              >
                LOGIN <i class="fa fa-arrow-right ml-2"></i>
              </button>
              <button
                v-if="isLogin"
                :disabled="isLogin"
                type="submit"
                class="btn btn-accent btn-block py-2 font-weight-600"
              >
                <span class="spinner-border spinner-border-sm"></span>
              </button>
            </form>

            <p class="section-description-sm mb-1">
              <b>
                <!-- <a href="" class="text-blue"
                  >Reset Your Password by e-mail?
                </a> -->
              </b>
            </p>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  value=""
                  id="check-1"
                />
                <label class="custom-control-label" for="check-1"
                  ><small
                    >Save my name, email, and website in this browser for the
                    next time I comment</small
                  ></label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- register modal  -->
    <div class="modal fade modal-style" id="registrasi-modal">
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
        style="max-width: 850px"
      >
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>

            <div class="d-md-flex justify-content-between">
              <h3 class="section-heading-xs">Register</h3>
              <p class="section-description-sm">
                <b
                  >Already have account?
                  <a
                    @click="Modalogin()"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#login-modal"
                    class="text-blue"
                    >Log in</a
                  ></b
                >
              </p>
            </div>

            <form @submit.prevent="submit" class="form-style mb-4">
              <div class="wrap-content">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mb-2">
                      <input
                        type="text"
                        name=""
                        class="form-control"
                        placeholder="Your name *"
                        v-model.trim="$v.form_register.name.$model"
                        :class="{
                          'is-invalid': $v.form_register.name.$error,
                          'is-valid':
                            !$v.form_register.name.$error &&
                            form_register.name != '',
                        }"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.name.required"
                      >
                        *Masukkan Nama Lengkap!.
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <input
                        v-model.trim="$v.form_register.email.$model"
                        :class="{
                          'is-invalid': $v.form_register.email.$error,
                          'is-valid':
                            !$v.form_register.email.$error &&
                            form_register.email != '',
                        }"
                        type="text"
                        name=""
                        class="form-control"
                        placeholder="E-mail *"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.email.email"
                      >
                        *Email tidak valid!.
                      </div>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.email.required"
                      >
                        *Masukkan Email!.
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <input
                        v-model.trim="$v.form_register.phone.$model"
                        :class="{
                          'is-invalid': $v.form_register.phone.$error,
                          'is-valid':
                            !$v.form_register.phone.$error &&
                            form_register.phone != '',
                        }"
                        type="number"
                        name=""
                        class="form-control"
                        placeholder="Phone Number *"
                        @keypress="isNumber($event)"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.phone.required"
                      >
                        *Masukkan Nomor Telepon!.
                      </div>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.phone.minLength"
                      >
                        *No. Telp minimal 10 Digit!.
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <date-picker
                        :class="{
                          'form-control': 'form-control',
                        }"
                        v-model="form_register.birth"
                        :config="options"
                      ></date-picker>
                    </div>
                    <div class="form-group mb-2 form-style">
                      <label class="control-label text-2">Jenis Kelamin</label>
                      <div class="d-flex">
                        <b-form-radio-group
                          button-variant="danger"
                          v-model.trim="$v.form_register.gender.$model"
                          :options="gender_option"
                          name="radio-inline"
                        ></b-form-radio-group>
                        <div
                          class="invalid-feedback"
                          v-if="!$v.form_register.gender.required"
                        >
                          *Pilih Gender!.
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <v-select
                        class="vselect-custome"
                        style="width: 100%"
                        :options="province"
                        label="provinsi"
                        placeholder="Pilih Provinsi"
                        @input="setValIdProvince"
                        :value="form_register.province_name"
                        :clearable="false"
                      >
                        <template slot="no-options"
                          >Provinsi tidak ditemukan</template
                        >
                        <template slot="option" slot-scope="option">{{
                          option.provinsi
                        }}</template>
                      </v-select>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.province.required"
                      >
                        *Pilih Propinsi!.
                      </div>
                    </div>
                    <div class="form-group">
                      <v-select
                        class="vselect-custome"
                        style="width: 100%"
                        :options="kota"
                        label="nama"
                        placeholder="Pilih Kota"
                        @input="setValIdKota"
                        :value="form_register.city_name"
                        :clearable="false"
                      >
                        <template slot="no-options"
                          >Kota tidak ditemukan</template
                        >
                        <template slot="option" slot-scope="option">{{
                          option.nama
                        }}</template>
                      </v-select>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.city.required"
                      >
                        *Pilih Kota!.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mb-2">
                      <textarea
                        v-model.trim="$v.form_register.address.$model"
                        :class="{
                          'is-invalid': $v.form_register.address.$error,
                          'is-valid':
                            !$v.form_register.address.$error &&
                            form_register.address != '',
                        }"
                        name=""
                        rows="2"
                        class="form-control"
                        placeholder="Address"
                      ></textarea>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.address.required"
                      >
                        *Masukkan Alamat!.
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div class="input-group">
                            <input
                              v-model="form_register.no_rangka"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Input Nomor Rangka"
                            />
                            <div class="input-group-append">
                              <button
                                :disabled="form_register.no_rangka == ''"
                                v-if="!isCekRangka"
                                type="submit"
                                class="btn btn-sm btn-outline-dark"
                                @click.prevent="cekStatusNorangka"
                              >
                                <i class="fa fa-search"></i> CEK NOMOR RANGKA
                              </button>
                              <button
                                v-if="isCekRangka"
                                type="submit"
                                class="btn btn-sm btn-outline-dark"
                              >
                                <span
                                  class="spinner-border spinner-border-sm"
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <b-alert
                          v-if="message_no_rangka.status_tidak_valid == true"
                          v-model="showDismissibleAlertRangka"
                          variant="danger"
                          >{{ message_no_rangka.message }}</b-alert
                        >
                        <b-alert
                          v-if="message_no_rangka.status_valid == true"
                          v-model="showDismissibleAlertRangka"
                          variant="success"
                          >{{ message_no_rangka.message }}</b-alert
                        >
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <input
                        v-model.trim="$v.form_register.password.$model"
                        :class="{
                          'is-invalid': $v.form_register.password.$error,
                          'is-valid':
                            !$v.form_register.password.$error &&
                            form_register.password != '',
                        }"
                        type="password"
                        name=""
                        class="form-control"
                        placeholder="Password *"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.password.required"
                      >
                        *Masukkan Password!.
                      </div>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.password.minLength"
                      >
                        *Password Minimal 8 karakter!.
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <input
                        v-model.trim="$v.form_register.confirm_password.$model"
                        :class="{
                          'is-invalid':
                            $v.form_register.confirm_password.$error,
                          'is-valid':
                            !$v.form_register.confirm_password.$error &&
                            form_register.confirm_password != '',
                        }"
                        type="password"
                        name=""
                        class="form-control"
                        placeholder="Confirm Password *"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_register.confirm_password.sameAsPassword"
                      >
                        *Password Belum sama!.
                      </div>
                    </div>
                    <div class="form-group mb-1 mt-lg-3">
                      <p class="mb-0">
                        <small
                          >Your personal data will be used in mapping with the
                          vehicles you added to the website, to manage access to
                          your account, and for other purposes described.</small
                        >
                      </p>
                    </div>
                    <div class="form-group mb-1">
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="$v.form_register.cek_list_agree.$model"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value=""
                      >
                        <small
                          >Saya setuju dengan syarat dan ketentuan berlaku
                          Nasmoco</small
                        >
                      </b-form-checkbox>
                    </div>
                    <div class="form-group">
                      <b-form-checkbox
                        id="checkbox-2"
                        v-model="$v.form_register.cek_list_penawaran.$model"
                        name="checkbox-2"
                        value="accepted"
                        unchecked-value=""
                      >
                        <small
                          >Saya bersedia menerima informasi dan penawaran
                          terbaru</small
                        >
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      v-if="!isRegister"
                      type="submit"
                      class="btn btn-accent btn-block py-2 font-weight-600"
                      :disabled="$v.form_register.$invalid"
                      @click.prevent="postRegister"
                    >
                      CREATE ACCOUNT <i class="fa fa-arrow-right ml-2"></i>
                    </button>
                    <button
                      v-if="isRegister"
                      :disabled="isRegister"
                      type="submit"
                      class="btn btn-accent btn-block py-2 font-weight-600"
                    >
                      <span class="spinner-border spinner-border-sm"></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
export default {
  name: "Header",
  created() {
    this.getVehicles();
    this.getProvince();
  },
  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      showPass: false,
      isLogin: false,
      isRegister: false,
      gender_option: [
        { text: "Laki-laki", value: "Laki-laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],

      isCekRangka: false,
      showDismissibleAlertRangka: false,
      message_no_rangka: {
        status_valid: false,
        status_tidak_valid: false,
        message: "",
      },
    };
  },
  validations: {
    form_login: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(4),
      },
    },
    form_register: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        sameAsPassword: sameAs("password"),
      },
      birth: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      address: {
        required,
      },
      gender: {
        required,
      },
      province: {
        required,
      },
      city: {
        required,
      },
      cek_list_agree: {
        required,
      },
      cek_list_penawaran: {
        required,
      },
    },
  },
  mounted() {
    $(".toggle-password").on("click", function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      let input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  },
  watch: {
    form_register: {
      handler(newValue) {
        if (newValue.province != "") {
          this.getKota({
            province_code: newValue.province,
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("detail_car", {
      car_by_id: (state) => state.car_by_id,
    }),
    ...mapGetters(["isAuth", "isToken", "isSharedPreference", "isErrors"]),
    ...mapState("regencies", {
      province: (state) => state.province,
      kota: (state) => state.kota,
    }),
    ...mapState(["errors"]),
    ...mapState("header", {
      loading: (state) => state.loading,
      category: (state) => state.category,
      vehicles: (state) => state.vehicles,
    }),
    ...mapState("auth", {
      message_rangka: (state) => state.message_rangka,
      loading_auth: (state) => state.loading,
      form_login: (state) => state.form_login,
      form_register: (state) => state.form_register,
    }),
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("regencies", ["getProvince", "getKota"]),
    ...mapActions("header", ["getVehicles"]),
    ...mapActions("auth", ["submit", "submitRegister", "cekNoRangka"]),
    ...mapActions("detail_car", ["getDetailCarByID", "Clear_State"]),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // regencies
    setValIdProvince(val) {
      this.form_register.province = val.code;
      this.form_register.province_name = val.provinsi;
    },
    setValIdKota(val) {
      this.form_register.city = val.kode;
      this.form_register.city_name = val.nama;
    },
    cekStatusNorangka() {
      this.isCekRangka = true;
      this.cekNoRangka({
        no_rangka: this.form_register.no_rangka,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          if (this.message_rangka == "valid") {
            this.form_register.no_rangka_status = "valid";
            this.message_no_rangka.status_valid = true;
            this.message_no_rangka.status_tidak_valid = false;
            this.message_no_rangka.message = "Anda Adalah Member Lama Nasmoco";
            this.showDismissibleAlertRangka = true;
          } else if (this.message_rangka == "tidak valid") {
            this.form_register.no_rangka_status = "not valid";
            this.message_no_rangka.status_valid = false;
            this.message_no_rangka.status_tidak_valid = true;
            this.message_no_rangka.message = "Anda Adalah Member Baru Nasmoco";
            this.showDismissibleAlertRangka = true;
          }
          this.isCekRangka = false;
        })
        .catch((error) => {
          this.isCekRangka = false;
        });
    },
    postLogin() {
      this.isLogin = true;
      this.$Progress.start();
      this.submit()
        .then((res) => {
          this.CLEAR_ERRORS();
          this.isLogin = false;
          $("#login-modal").modal("hide");
          this.$router.push({
            name: "Dashboard_member",
          });
          swal.fire({
            title: "Anda berhasil login",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.isLogin = false;
          this.$Progress.fail();
        });
    },
    postRegister() {
      this.isRegister = true;
      this.$Progress.start();
      this.submitRegister()
        .then((res) => {
          this.CLEAR_ERRORS();
          this.isRegister = false;
          $("#registrasi-modal").modal("hide");
          this.$router.push({
            name: "Dashboard_member",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.isRegister = false;
          this.$Progress.fail();
          // this.$router.push({ name: "login" });
        });
    },
    ToDashboardMember() {
      if (this.isAuth) {
        var navbar = document.querySelector("#navBar");
        navbar.classList.remove("show");
        this.$router.push({ name: "Dashboard_member" });
      }
      if (!this.isAuth) {
        var navbar = document.querySelector("#navBar");
        navbar.classList.remove("show");
        $("#login-modal").modal("show");
      }
    },
    Modalogin() {
      var navbar = document.querySelector("#navBar");
      navbar.classList.remove("show");
      $("#login-modal").modal("show");
    },
    ModalRegister() {
      $("#registrasi-modal").modal("show");
    },
    closeNavbar() {
      var navbar = document.querySelector("#navBar");
      navbar.classList.remove("show");
    },
    toDetailCar(seo) {
      var navbar = document.querySelector("#navBar");
      navbar.classList.remove("show");

      this.$Progress.start();
      this.Clear_State();
      this.getDetailCarByID(seo)
        .then((res) => {
          this.$router.push({
            name: "Detail_car",
            params: {
              link: seo,
            },
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log("Gagal Mengambil Data");
          this.$Progress.fail();
        });
    },
    async logout() {
      try {
        localStorage.removeItem("token");
        localStorage.removeItem("sharedPreference");
        this.$store.state.token = localStorage.getItem("token");
        this.$router.push({
          name: "Home",
        });
        swal.fire({
          title: "Anda berhasil logout",
          icon: "success",
          confirmButtonColor: "#27ae60",
          confirmButtonText: "OK!",
        });
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style >
.field-icon {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  right: 1.2rem;
  top: 0.8rem;
}
</style>