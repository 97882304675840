import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    form_credit_simulation: {
        car_category: "",
        car_model: "",
        car_transmission: "",
        car_type: "",
        uang_muka: "",
        tenor: 12,
        email: "",
        ktp: "",
    },
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.form_credit_simulation = {
                car_category: "",
                car_model: "",
                car_transmission: "",
                car_type: "",
                uang_muka: "",
                tenor: 12,
                email: "",
                ktp: "",
            }
    },
    CLEAR_FORM(state) {
        state.form_credit_simulation = {
            car_category: "",
            car_model: "",
            car_transmission: "",
            car_type: "",
            uang_muka: "",
            tenor: 12,
            email: "",
            ktp: "",
        }
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    insertDataInstanApprove({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`instantapprove`, state.form_credit_simulation)
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    commit('CLEAR_FORM')
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    if (error.response.status == 401) {
                        commit('SET_ERRORS', error.response.data.message, {
                            root: true
                        })
                    } else if (error.response.status == 422) {
                        commit('SET_ERRORS', "Periksa kembali semua form.", {
                            root: true
                        })
                    } else if (error.response.status == 400) {
                        commit('SET_ERRORS', "Server Bermasalah", {
                            root: true
                        })
                    }
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
