<template>
  <div>
    <section class="section-lg pb-lg-5 dashboard-step">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 m-auto">
            <div class="nav-tabs-step">
              <ul class="nav nav-tabs nav-step" id="nav-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link nav-step-1"
                    :class="{
                      active: step == 1,
                    }"
                  >
                    <img
                      data-src="assets/img/icon/icon-step-0.png"
                      class="img-fluid lazyload"
                    />
                    <span>Registrasi</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-step-2"
                    :class="{
                      active: step == 2,
                    }"
                  >
                    <img
                      data-src="assets/img/icon/icon-step-1.png"
                      class="img-fluid lazyload"
                    />
                    <span>Pilih Mobil</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-step-3"
                    :class="{
                      active: step == 3,
                    }"
                  >
                    <img
                      data-src="assets/img/icon/icon-step-2.png"
                      class="img-fluid lazyload"
                    />
                    <span>Pilih Warna</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-step-4"
                    :class="{
                      active: step == 4,
                    }"
                  >
                    <img
                      data-src="assets/img/icon/icon-step-3.png"
                      class="img-fluid lazyload"
                    />
                    <span>Jenis Pembayaran</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link nav-step-5"
                    :class="{
                      active: step == 5,
                    }"
                  >
                    <img
                      data-src="assets/img/icon/icon-step-4.png"
                      class="img-fluid lazyload"
                    />
                    <span>Ringkasan</span>
                  </a>
                </li>
              </ul>

              <div class="tab-content form-step" id="nav-tabContent">
                <!-- step register  -->
                <div v-if="step == 1">
                  <div class="form-style">
                    <div class="wrap-content">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group mb-2">
                            <input
                              :disabled="isAuth"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="Your name *"
                              v-model.trim="
                                $v.form_booking_order_profile.name.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.name.$error,
                                'is-valid':
                                  !$v.form_booking_order_profile.name.$error &&
                                  form_booking_order_profile.name != '',
                              }"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.name.required
                              "
                            >
                              *Masukkan Nama Lengkap!.
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <input
                              :disabled="isAuth"
                              v-model.trim="
                                $v.form_booking_order_profile.email.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.email.$error,
                                'is-valid':
                                  !$v.form_booking_order_profile.email.$error &&
                                  form_booking_order_profile.email != '',
                              }"
                              type="text"
                              name=""
                              class="form-control"
                              placeholder="E-mail *"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="!$v.form_booking_order_profile.email.email"
                            >
                              *Email tidak valid!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.email.required
                              "
                            >
                              *Masukkan Email!.
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <input
                              :disabled="isAuth"
                              v-model.trim="
                                $v.form_booking_order_profile.phone.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.phone.$error,
                                'is-valid':
                                  !$v.form_booking_order_profile.phone.$error &&
                                  form_booking_order_profile.phone != '',
                              }"
                              type="number"
                              name=""
                              class="form-control"
                              placeholder="Phone Number *"
                              @keypress="isNumber($event)"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.phone.required
                              "
                            >
                              *Masukkan Nomor Telepon!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.phone.minLength
                              "
                            >
                              *No. Telp minimal 10 Digit!.
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <date-picker
                              :disabled="isAuth"
                              :class="{
                                'form-control': 'form-control',
                              }"
                              v-model="form_booking_order_profile.birth"
                              :config="options"
                            ></date-picker>
                          </div>
                          <div class="form-group mb-2 form-style">
                            <label class="control-label text-2"
                              >Jenis Kelamin</label
                            >
                            <div class="d-flex">
                              <b-form-radio-group
                                :disabled="isAuth"
                                button-variant="danger"
                                v-model.trim="
                                  $v.form_booking_order_profile.gender.$model
                                "
                                :options="gender_option"
                                name="radio-inline"
                              ></b-form-radio-group>
                              <div
                                class="invalid-feedback"
                                v-if="
                                  !$v.form_booking_order_profile.gender.required
                                "
                              >
                                *Pilih Gender!.
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <v-select
                              :disabled="isAuth"
                              class="vselect-custome"
                              style="width: 100%"
                              :options="province"
                              label="provinsi"
                              placeholder="Pilih Provinsi"
                              @input="setValIdProvince"
                              :value="form_booking_order_profile.province_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Provinsi tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.provinsi
                              }}</template>
                            </v-select>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.province.required
                              "
                            >
                              *Pilih Propinsi!.
                            </div>
                          </div>
                          <div class="form-group">
                            <v-select
                              :disabled="isAuth"
                              class="vselect-custome"
                              style="width: 100%"
                              :options="kota"
                              label="nama"
                              placeholder="Pilih Kota"
                              @input="setValIdKota"
                              :value="form_booking_order_profile.city_name"
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Kota tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.nama
                              }}</template>
                            </v-select>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.city.required
                              "
                            >
                              *Pilih Kota!.
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group mb-2">
                            <textarea
                              :disabled="isAuth"
                              v-model.trim="
                                $v.form_booking_order_profile.address.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.address.$error,
                                'is-valid':
                                  !$v.form_booking_order_profile.address
                                    .$error &&
                                  form_booking_order_profile.address != '',
                              }"
                              name=""
                              rows="2"
                              class="form-control"
                              placeholder="Address"
                            ></textarea>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.address.required
                              "
                            >
                              *Masukkan Alamat!.
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-12">
                              <div class="form-group">
                                <div class="input-group">
                                  <input
                                    :disabled="isAuth"
                                    v-model="
                                      form_booking_order_profile.no_rangka
                                    "
                                    type="text"
                                    name=""
                                    class="form-control"
                                    placeholder="Input Nomor Rangka"
                                  />
                                  <div
                                    class="input-group-append"
                                    v-if="!isAuth"
                                  >
                                    <button
                                      :disabled="
                                        form_booking_order_profile.no_rangka ==
                                        ''
                                      "
                                      v-if="!isCekRangka"
                                      type="submit"
                                      class="btn btn-sm btn-outline-dark"
                                      @click.prevent="cekStatusNorangka"
                                    >
                                      <i class="fa fa-search"></i> CEK NOMOR
                                      RANGKA
                                    </button>
                                    <button
                                      v-if="isCekRangka"
                                      type="submit"
                                      class="btn btn-sm btn-outline-dark"
                                    >
                                      <span
                                        class="spinner-border spinner-border-sm"
                                      ></span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <b-alert
                                v-if="
                                  message_no_rangka.status_tidak_valid == true
                                "
                                v-model="showDismissibleAlertRangka"
                                variant="danger"
                                >{{ message_no_rangka.message }}</b-alert
                              >
                              <b-alert
                                v-if="message_no_rangka.status_valid == true"
                                v-model="showDismissibleAlertRangka"
                                variant="success"
                                >{{ message_no_rangka.message }}</b-alert
                              >
                            </div>
                          </div>
                          <div class="form-group mb-2" v-if="!isAuth">
                            <input
                              v-model.trim="
                                $v.form_booking_order_profile.password.$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.password.$error,
                                'is-valid':
                                  !$v.form_booking_order_profile.password
                                    .$error &&
                                  form_booking_order_profile.password != '',
                              }"
                              type="password"
                              name=""
                              class="form-control"
                              placeholder="Password *"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.password.required
                              "
                            >
                              *Masukkan Password!.
                            </div>
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.password
                                  .minLength
                              "
                            >
                              *Password Minimal 8 karakter!.
                            </div>
                          </div>
                          <div class="form-group mb-2" v-if="!isAuth">
                            <input
                              v-model.trim="
                                $v.form_booking_order_profile.confirm_password
                                  .$model
                              "
                              :class="{
                                'is-invalid':
                                  $v.form_booking_order_profile.confirm_password
                                    .$error,
                                'is-valid':
                                  !$v.form_booking_order_profile
                                    .confirm_password.$error &&
                                  form_booking_order_profile.confirm_password !=
                                    '',
                              }"
                              type="password"
                              name=""
                              class="form-control"
                              placeholder="Confirm Password *"
                            />
                            <div
                              class="invalid-feedback"
                              v-if="
                                !$v.form_booking_order_profile.confirm_password
                                  .sameAsPassword
                              "
                            >
                              *Password Belum sama!.
                            </div>
                          </div>

                          <div class="form-group mb-1 mt-lg-3">
                            <p class="mb-0">
                              <small
                                >Your personal data will be used in mapping with
                                the vehicles you added to the website, to manage
                                access to your account, and for other purposes
                                described.</small
                              >
                            </p>
                          </div>
                          <div class="form-group mb-1">
                            <b-form-checkbox
                              v-model="
                                $v.form_booking_order_profile.cek_list_agree
                                  .$model
                              "
                              value="accepted"
                              unchecked-value=""
                            >
                              <small
                                >Saya setuju dengan syarat dan ketentuan berlaku
                                Nasmoco</small
                              >
                            </b-form-checkbox>
                          </div>
                          <div class="form-group">
                            <b-form-checkbox
                              v-model="
                                $v.form_booking_order_profile.cek_list_penawaran
                                  .$model
                              "
                              value="accepted"
                              unchecked-value=""
                            >
                              <small
                                >Saya bersedia menerima informasi dan penawaran
                                terbaru</small
                              >
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="box-actions justify-content-end">
                    <button
                      :disabled="$v.form_booking_order_profile.$invalid"
                      class="btn btn-lg btn-yellow font-weight-bold btn-next"
                      @click.prevent="nextStep()"
                    >
                      Next <span class="ion-arrow-right-c"></span>
                    </button>
                  </div>
                </div>
                <!-- step pick car type  -->
                <div v-if="step == 2">
                  <form class="form-style">
                    <div class="row">
                      <div class="col-lg-4 pr-lg-0">
                        <div class="welcome-step">
                          <div class="row">
                            <div class="col-lg-8">
                              <h4 class="section-description font-weight-bold">
                                Selamat Datang,
                                {{ form_booking_order_profile.name }}
                              </h4>
                              <p class="section-description-xs mb-0">
                                Langkah pertama, pilih mobil dan variant impian
                                pertama Anda dengan memilih salah satu dari
                                pilihan dibawah.
                              </p>
                            </div>
                          </div>
                          <img
                            data-src="assets/img/thumbnails-1.png"
                            class="img-fluid lazyload d-none d-lg-block"
                          />
                        </div>

                        <div class="wrap-content border-right">
                          <form method="post" class="form-style">
                            <div class="form-group">
                              <v-select
                                class="vselect-custome"
                                style="width: 100%"
                                :options="kota_cabang"
                                label="city_name"
                                placeholder="Pilih Kota Cabang"
                                @input="setValIdKotaCabang"
                                :value="
                                  form_booking_order_pick_car.kota_cabang_name
                                "
                                :clearable="false"
                              >
                                <template slot="no-options"
                                  >Kota Tidak Ditemukan</template
                                >
                                <template slot="option" slot-scope="option">{{
                                  option.city_name
                                }}</template>
                              </v-select>
                            </div>

                            <div class="form-group">
                              <v-select
                                class="vselect-custome"
                                style="width: 100%"
                                :options="cabang"
                                label="cabang_name"
                                placeholder="Pilih Cabang"
                                @input="setValIdCabang"
                                :value="
                                  form_booking_order_pick_car.cabang_id_name
                                "
                                :clearable="false"
                              >
                                <template slot="no-options"
                                  >Cabang Tidak Ditemukan</template
                                >
                                <template slot="option" slot-scope="option">{{
                                  option.cabang_name
                                }}</template>
                              </v-select>
                            </div>
                            <div class="form-group">
                              <v-select
                                class="vselect-custome"
                                style="width: 100%"
                                :options="car_category"
                                label="category_name"
                                placeholder="Pilih Kategori Mobil"
                                @input="setValIdkategoriMobil"
                                :value="
                                  form_booking_order_pick_car.car_category_name
                                "
                                :clearable="false"
                              >
                                <template slot="no-options"
                                  >Kategori Mobil tidak ditemukan</template
                                >
                                <template slot="option" slot-scope="option">{{
                                  option.category_name
                                }}</template>
                              </v-select>
                            </div>

                            <div class="form-group">
                              <v-select
                                class="vselect-custome"
                                style="width: 100%"
                                :options="car_model"
                                label="car_mode_name"
                                placeholder="Pilih Model Mobil"
                                @input="setValIdCarModel"
                                :value="
                                  form_booking_order_pick_car.car_model_name
                                "
                                :clearable="false"
                              >
                                <template slot="no-options"
                                  >Model Mobil tidak ditemukan</template
                                >
                                <template slot="option" slot-scope="option">{{
                                  option.car_mode_name
                                }}</template>
                              </v-select>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="col-lg-8 pl-lg-0">
                        <div
                          class="welcome-step-sm d-flex align-items-center justify-content-between"
                        >
                          <h4
                            class="section-description-md font-weight-bold mb-0"
                          >
                            Pilih tipe mobil impian Anda
                          </h4>

                          <div class="form-group mb-0">
                            <v-select
                              class="vselect-custome"
                              style="width: 100%"
                              :options="Car_Transmision"
                              label="label"
                              placeholder="Pilih Transmisi Mobil"
                              @input="setValIdTransmisi"
                              :value="
                                form_booking_order_pick_car.car_transmission_name
                              "
                              :clearable="false"
                            >
                              <template slot="no-options"
                                >Transmisi tidak ditemukan</template
                              >
                              <template slot="option" slot-scope="option">{{
                                option.label
                              }}</template>
                            </v-select>
                          </div>
                        </div>

                        <div class="wrap-content">
                          <form method="post" class="form-style">
                            <div
                              v-if="loading_car_type"
                              class="flex-3-column flex-radio justify-content-start"
                            >
                              <div class="flex-col p-1 mb-lg-0 mb-2">
                                <div class="radio-tile-group hv-yellow">
                                  <div class="input-container">
                                    <div class="radio-tile">
                                      <div class="card card-product-2">
                                        <div class="card-header">
                                          <div class="col-lg-12">
                                            <b-skeleton-img></b-skeleton-img>
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <div class="wrap-padd pb-2">
                                            <b-skeleton
                                              animation="wave"
                                              width="85%"
                                            ></b-skeleton>
                                            <hr class="my-1" />
                                            <div class="box-price">
                                              <b-skeleton
                                                animation="wave"
                                                width="55%"
                                              ></b-skeleton>
                                              <b-skeleton
                                                animation="wave"
                                                width="70%"
                                              ></b-skeleton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-col p-1 mb-lg-0 mb-2">
                                <div class="radio-tile-group hv-yellow">
                                  <div class="input-container">
                                    <div class="radio-tile">
                                      <div class="card card-product-2">
                                        <div class="card-header">
                                          <div class="col-lg-12">
                                            <b-skeleton-img></b-skeleton-img>
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <div class="wrap-padd pb-2">
                                            <b-skeleton
                                              animation="wave"
                                              width="85%"
                                            ></b-skeleton>
                                            <hr class="my-1" />
                                            <div class="box-price">
                                              <b-skeleton
                                                animation="wave"
                                                width="55%"
                                              ></b-skeleton>
                                              <b-skeleton
                                                animation="wave"
                                                width="70%"
                                              ></b-skeleton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-col p-1 mb-lg-0 mb-2">
                                <div class="radio-tile-group hv-yellow">
                                  <div class="input-container">
                                    <div class="radio-tile">
                                      <div class="card card-product-2">
                                        <div class="card-header">
                                          <div class="col-lg-12">
                                            <b-skeleton-img></b-skeleton-img>
                                          </div>
                                        </div>
                                        <div class="card-body">
                                          <div class="wrap-padd pb-2">
                                            <b-skeleton
                                              animation="wave"
                                              width="85%"
                                            ></b-skeleton>
                                            <hr class="my-1" />
                                            <div class="box-price">
                                              <b-skeleton
                                                animation="wave"
                                                width="55%"
                                              ></b-skeleton>
                                              <b-skeleton
                                                animation="wave"
                                                width="70%"
                                              ></b-skeleton>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="flex-3-column flex-radio justify-content-start"
                            >
                              <div
                                v-if="car_type.length == 0"
                                class="not-found-items"
                              >
                                <svg
                                  viewBox="0 0 126 128"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.2612 60.4219H49.7881C51.344 60.4219 52.3813 59.3846 52.3813 57.8287V39.1577C52.3813 37.6017 51.344 36.5645 49.7881 36.5645H14.2612C12.7053 36.5645 11.668 37.6017 11.668 39.1577V57.8287C11.668 59.1253 12.7053 60.4219 14.2612 60.4219ZM16.8544 41.7509H47.1949V55.2355H16.8544V41.7509Z"
                                  />
                                  <path
                                    d="M61.4604 60.4219H95.95C97.5059 60.4219 98.5432 59.3846 98.5432 57.8287V39.1577C98.5432 37.6017 97.5059 36.5645 95.95 36.5645H61.4604C59.9045 36.5645 58.8672 37.6017 58.8672 39.1577V57.8287C58.8672 59.1253 59.9045 60.4219 61.4604 60.4219ZM64.0536 41.7509H93.3568V55.2355H64.0536V41.7509Z"
                                  />
                                  <path
                                    d="M13.4827 22.3021H18.6691C21.003 22.3021 23.0775 20.2276 23.0775 17.8937V12.7073C23.0775 10.1141 21.003 8.29883 18.6691 8.29883H13.4827C11.1488 8.29883 9.07422 10.3734 9.07422 12.7073V17.8937C9.07422 20.4869 10.8895 22.3021 13.4827 22.3021ZM14.2606 13.4852H17.8911V17.375H14.2606V13.4852Z"
                                  />
                                  <path
                                    d="M30.3381 22.3021H35.5245C37.8584 22.3021 39.933 20.2276 39.933 17.8937V12.7073C39.933 10.1141 37.8584 8.29883 35.5245 8.29883H30.3381C28.0043 8.29883 25.9297 10.3734 25.9297 12.7073V17.8937C25.9297 20.4869 28.0043 22.3021 30.3381 22.3021ZM31.1161 13.4852H34.7466V17.375H31.1161V13.4852Z"
                                  />
                                  <path
                                    d="M46.6752 17.8934H98.7986C100.355 17.8934 101.392 16.8562 101.392 15.3002C101.392 13.7443 100.355 12.707 98.7986 12.707H46.6752C45.1193 12.707 44.082 13.7443 44.082 15.3002C44.082 16.8562 45.1193 17.8934 46.6752 17.8934Z"
                                  />
                                  <path
                                    d="M74.9428 66.9043H10.1127C8.55681 66.9043 7.51953 67.9416 7.51953 69.4975C7.51953 71.0534 8.55681 72.0907 10.1127 72.0907H74.9428C76.4988 72.0907 77.536 71.0534 77.536 69.4975C77.536 68.2009 76.2394 66.9043 74.9428 66.9043Z"
                                  />
                                  <path
                                    d="M54.9752 80.1299H10.1127C8.55681 80.1299 7.51953 81.1672 7.51953 82.7231C7.51953 84.279 8.55681 85.3163 10.1127 85.3163H55.2345C56.7904 85.3163 57.8277 84.279 57.8277 82.7231C57.5684 81.4265 56.5311 80.1299 54.9752 80.1299Z"
                                  />
                                  <path
                                    d="M54.9752 93.3555H10.1127C8.55681 93.3555 7.51953 94.3928 7.51953 95.9487C7.51953 97.5046 8.55681 98.5419 10.1127 98.5419H55.2345C56.7904 98.5419 57.8277 97.5046 57.8277 95.9487C57.5684 94.3928 56.5311 93.3555 54.9752 93.3555Z"
                                  />
                                  <path
                                    d="M124.992 116.435L110.211 92.5774V9.33553C110.211 4.14913 106.062 0 100.876 0H9.33553C4.14913 0 0 4.14913 0 9.33553V102.95C0 106.84 3.37117 110.211 7.77961 110.211H64.3115L60.4217 116.435C59.125 118.509 58.8657 121.362 60.1623 123.437C61.4589 125.77 63.7928 127.067 66.386 127.067H119.028C121.621 127.067 123.955 125.77 125.252 123.437C126.289 121.103 126.289 118.509 124.992 116.435ZM9.33553 5.18641H100.876C103.21 5.18641 105.025 7.00165 105.025 9.33553V25.6727H5.18641V9.33553C5.18641 7.00165 7.00165 5.18641 9.33553 5.18641ZM7.77961 105.025C6.48301 105.025 5.44573 103.987 5.44573 102.95V30.5998H105.284V84.2791L98.5418 73.9063C97.2452 71.8318 94.9113 70.5352 92.5774 70.5352C90.2435 70.5352 87.9096 71.8318 86.613 73.9063L67.4233 105.025H7.77961ZM120.584 120.843C120.325 121.103 120.065 121.881 119.028 121.881H66.386C65.3487 121.881 64.8301 121.103 64.8301 120.843C64.5708 120.584 64.3115 119.806 64.8301 119.028L91.2808 76.4995C91.7994 75.7216 92.5774 75.7216 92.8367 75.7216C93.096 75.7216 93.874 75.7216 94.3926 76.4995L120.843 118.769C121.103 119.806 120.843 120.584 120.584 120.843Z"
                                  />
                                  <path
                                    d="M95.4293 83.502H89.9836C88.9463 83.502 87.909 84.0206 87.131 84.7986C86.3531 85.5765 86.0938 86.6138 86.0938 87.9104L87.6497 101.654C87.909 103.729 89.4649 105.285 91.5395 105.285H93.8734C95.9479 105.285 97.7632 103.729 97.7632 101.654L99.3191 87.6511C99.3191 86.6138 99.0598 85.5765 98.2818 84.7986C97.5038 84.0206 96.4666 83.502 95.4293 83.502ZM92.8361 100.098L91.5395 88.6884H94.1327L92.8361 100.098Z"
                                  />
                                  <path
                                    d="M92.5753 107.618C89.2041 107.618 86.3516 110.211 86.3516 113.842C86.3516 117.213 89.2041 120.066 92.5753 120.066C95.9464 120.066 98.7989 117.213 98.7989 113.842C98.7989 110.211 96.2057 107.618 92.5753 107.618ZM92.5753 114.879C92.0566 114.879 91.538 114.36 91.538 113.842C91.538 113.323 92.0566 112.805 92.5753 112.805C93.0939 112.805 93.6125 113.323 93.6125 113.842C93.6125 114.36 93.3532 114.879 92.5753 114.879Z"
                                  />
                                </svg>
                                <h3>Not Found Items</h3>
                              </div>
                              <div
                                class="flex-col p-1 mb-lg-0 mb-2"
                                v-for="(row, index) in car_type"
                                :key="index"
                              >
                                <div class="radio-tile-group hv-yellow">
                                  <div class="input-container">
                                    <input
                                      @click="
                                        pickCarType(
                                          row.car_type_name,
                                          row.price_otr
                                        )
                                      "
                                      v-model="
                                        form_booking_order_pick_car.car_type
                                      "
                                      type="radio"
                                      :name="row.car_type_name"
                                      :id="row.car_type_id"
                                      :value="row.car_type_id"
                                      class="radio-button"
                                      :checked="index == 0"
                                    />
                                    <div class="radio-tile">
                                      <div class="card card-product-2">
                                        <div class="card-header">
                                          <img
                                            :src="row.image"
                                            class="img-fluid lazyload"
                                          />
                                        </div>
                                        <div class="card-body">
                                          <div class="wrap-padd pb-2">
                                            <h2 class="heading mb-0">
                                              {{ row.car_type_name }}
                                            </h2>

                                            <hr class="my-1" />

                                            <div class="price-display-1">
                                              <div class="box-price">
                                                <!-- <del>{{
                                                row.price | currency
                                              }}</del> -->
                                                <p class="price">
                                                  {{ row.price_otr | currency }}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="box-actions">
                    <a
                      href="javascript:void(0)"
                      class="text-accent font-weight-bold btn-prev"
                      @click.prevent="prevStep()"
                    >
                      <span class="ion-arrow-left-c"></span> Previous
                    </a>
                    <button
                      :disabled="$v.form_booking_order_pick_car.$invalid"
                      class="btn btn-lg btn-yellow font-weight-bold btn-next"
                      @click.prevent="nextStep()"
                    >
                      Next <span class="ion-arrow-right-c"></span>
                    </button>
                  </div>
                </div>
                <!-- step pick color  -->
                <div v-if="step == 3">
                  <div class="row">
                    <div class="col-lg-4 pr-lg-0">
                      <div class="welcome-step-sm bg-dark">
                        <p
                          class="section-description-xxs text-white mb-0 d-flex"
                        >
                          <span
                            class="ion-information-circled text-yellow mr-2 font-18"
                          ></span>
                          Langkah kedua, pilih warna mobil impian Anda sesuai
                          dengan yang di kehendaki.
                        </p>
                      </div>

                      <div
                        class="wrap-content border-right"
                        v-if="car_color.length != 0"
                      >
                        <h4 class="section-description-md font-weight-bold">
                          Warna yang tersedia:
                        </h4>

                        <form method="post" class="form-style">
                          <div
                            class="flex-2-column flex-radio justify-content-start"
                          >
                            <div
                              class="flex-col"
                              v-for="(row, index) in car_color"
                              :key="index"
                            >
                              <div class="radio-tile-group radio-color-product">
                                <div class="input-container">
                                  <input
                                    @click="
                                      pickedColor(row.image, row.color_name)
                                    "
                                    v-model="
                                      form_booking_order_pick_color.car_color
                                    "
                                    type="radio"
                                    name="color"
                                    :id="row.color_id"
                                    :value="row.color_id"
                                    class="radio-button"
                                    :checked="index == 0"
                                  />
                                  <div class="radio-tile">
                                    <div
                                      class="color-product"
                                      :style="{ background: row.color_palette }"
                                    ></div>
                                    <label
                                      for="color1"
                                      class="radio-tile-label section-description-xs"
                                      >{{ row.color_name }}</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="col-lg-8 pl-lg-0">
                      <div
                        class="welcome-step-sm d-flex align-items-center justify-content-between"
                      >
                        <h4
                          class="section-description-md font-weight-bold mb-0"
                        >
                          Tipe mobil pilihan Anda
                        </h4>
                        <h4
                          class="section-description-md font-weight-bold mb-0"
                        >
                          {{ info_selected.car_pick_name }} -
                          {{ info_selected.Transmisi }}
                        </h4>
                      </div>
                      <div class="wrap-content text-center">
                        <img
                          :src="info_selected.image_pick_color"
                          class="img-fluid lazyload img-car-preview m-auto"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="box-actions">
                    <a
                      class="text-accent font-weight-bold btn-prev"
                      @click.prevent="prevStep()"
                      ><span class="ion-arrow-left-c"></span> Previous</a
                    >
                    <button
                      :disabled="$v.form_booking_order_pick_color.$invalid"
                      class="btn btn-lg btn-yellow font-weight-bold btn-next"
                      @click.prevent="nextStep()"
                    >
                      Next <span class="ion-arrow-right-c"></span>
                    </button>
                  </div>
                </div>
                <!-- jenis pembayaran  -->
                <div v-if="step == 4">
                  <div class="welcome-step-sm">
                    <h4 class="section-description">Jenis Pembayaran</h4>
                  </div>

                  <div class="row">
                    <div class="col-lg-6 border-right">
                      <div class="wrap-content">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Kategori Mobil
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.category_car }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Model
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.car_model_name }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Transmisi
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Transmisi }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Tipe
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Tipe_mobil }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Pilihan Warna
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Pilihan_Warna }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <p class="section-description-sm text-2">Cabang</p>
                            <p class="section-description-sm text-3">
                              {{ info_selected.Cabang }}
                            </p>
                          </div>
                        </div>

                        <div
                          class="card card-style h-dark shadow-none border mt-3"
                        >
                          <div class="card-header">
                            <p class="section-description-sm">HARGA MOBIL</p>
                          </div>
                          <div class="card-body">
                            <p class="section-description-sm text-2">
                              Total Biaya
                            </p>
                            <h4 class="section-description-lg">
                              {{ info_selected.Harga | currency }}
                            </h4>
                          </div>
                        </div>

                        <form class="form-style mt-3">
                          <div class="form-group mb-2">
                            <label class="control-label font-weight-600"
                              >Pilih Jenis Pembayaran</label
                            >
                            <b-form-radio-group
                              button-variant="danger"
                              v-model="
                                form_booking_order_payment_cash.payment_type
                              "
                              :options="payment_option"
                              name="radio-inline"
                            ></b-form-radio-group>
                          </div>

                          <div
                            class="hide-items payment-cash"
                            v-if="
                              form_booking_order_payment_cash.payment_type ==
                              'cash'
                            "
                          >
                            <div class="form-group">
                              <p class="section-description-xs opacity-7 mb-2">
                                Apakah Anda memiliki Kode Promo? (Opsional)
                              </p>

                              <div class="flex-2-column align-items-center">
                                <div class="flex-col align-self-stretch">
                                  <div class="form-group">
                                    <input
                                      v-model="
                                        form_booking_order_payment_cash.promotion_code
                                      "
                                      type="text"
                                      name=""
                                      class="form-control mb-1"
                                      placeholder="Masukkan Kode Promo"
                                    />
                                    <b-alert
                                      v-if="
                                        message_promo_code.status_valid == true
                                      "
                                      v-model="showDismissibleAlertPromo"
                                      variant="success"
                                      >{{ message_promo_code.message }}</b-alert
                                    >
                                    <b-alert
                                      v-if="
                                        message_promo_code.status_tidak_valid ==
                                        true
                                      "
                                      v-model="showDismissibleAlertPromo"
                                      variant="danger"
                                      >{{ message_promo_code.message }}</b-alert
                                    >
                                  </div>
                                </div>
                                <div class="flex-col align-self-stretch">
                                  <button
                                    :disabled="
                                      form_booking_order_payment_cash.promotion_code ==
                                      ''
                                    "
                                    v-if="!isSave"
                                    type="submit"
                                    class="btn btn-md btn-outline-dark py-2 font-weight-600 ml-1"
                                    @click.prevent="cekKodePromoCash()"
                                  >
                                    Gunakan Kode
                                    <i class="fa fa-arrow-right ml-2"></i>
                                  </button>
                                  <button
                                    v-if="isSave"
                                    type="submit"
                                    class="btn btn-md btn-outline-dark py-2 font-weight-600 ml-1"
                                  >
                                    <span
                                      class="spinner-border spinner-border-sm"
                                    ></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="wrap-content">
                        <!-- payment cash  -->
                        <div
                          class="hide-items payment-cash"
                          v-if="
                            form_booking_order_payment_cash.payment_type ==
                            'cash'
                          "
                        >
                          <div class="card card-style h-dark shadow-none mt-3">
                            <div class="card-header">
                              <p class="section-description-sm">
                                PENGHITUNGAN JENIS PEMBAYARAN CASH
                              </p>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                <div class="col-lg-12">
                                  <p class="section-description-sm text-2">
                                    Total Biaya
                                  </p>
                                  <h4 class="section-description-lg">
                                    {{
                                      form_booking_order_payment_cash.price_otr
                                        | currency
                                    }}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card-body"
                              v-if="message_promo == 'Valid'"
                            >
                              <div class="row">
                                <div
                                  class="col-lg-6"
                                  v-if="result_promotion_code.length != 0"
                                >
                                  <p class="section-description-sm text-2">
                                    Total Biaya
                                  </p>
                                  <h4
                                    class="section-description-lg text-accent"
                                  >
                                    <del>{{
                                      form_booking_order_payment_cash.price_otr
                                        | currency
                                    }}</del>
                                  </h4>
                                </div>
                                <div
                                  class="col-lg-6 pr-lg-1 mt-2 mt-lg-0"
                                  v-if="result_promotion_code.length != 0"
                                >
                                  <p class="section-description-sm text-2">
                                    Total Biaya dengan Kode Promo
                                    <b class="text-dark">{{
                                      result_promotion_code.promotion_code
                                    }}</b>
                                  </p>
                                  <h4 class="section-description-lg">
                                    {{
                                      form_booking_order_payment_cash.price_otr_promo
                                        | currency
                                    }}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- payment credit  -->
                        <div
                          class="hide-items payment-credit"
                          v-if="
                            form_booking_order_payment_cash.payment_type ==
                            'kredit'
                          "
                        >
                          <div class="card card-style h-dark shadow-none mt-3">
                            <div class="card-header">
                              <p class="section-description-sm">
                                PENGHITUNGAN JENIS PEMBAYARAN KREDIT
                              </p>
                            </div>
                            <div class="card-body">
                              <form method="post" class="form-style mb-2">
                                <div class="row">
                                  <div class="col-lg-6">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Uang Muka</label
                                      >
                                      <money
                                        class="form-control text-right"
                                        v-model="
                                          form_booking_order_payment_credit.uang_muka
                                        "
                                      />
                                    </div>
                                  </div>
                                  <div class="col-lg-6">
                                    <div class="form-group">
                                      <label class="control-label"
                                        >Pilih Tenor / Jangka Waktu</label
                                      >
                                      <v-select
                                        class="vselect-custome"
                                        style="width: 100%"
                                        :options="tenor_option"
                                        label="text"
                                        placeholder="Pilih Tenor"
                                        @input="setValIdTenor"
                                        :value="
                                          form_booking_order_payment_credit.tenor
                                        "
                                        :clearable="false"
                                      >
                                        <template slot="no-options"
                                          >Tenor tidak ditemukan</template
                                        >
                                        <template
                                          slot="option"
                                          slot-scope="option"
                                          >{{ option.text }}</template
                                        >
                                      </v-select>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mb-2">
                                  <div class="col-lg-12">
                                    <div class="custome-input-file">
                                      <div class="wrap-input">
                                        <input
                                          accept="image/jpg, image/jpeg, image/png"
                                          type="file"
                                          name=""
                                          class="btn"
                                          @change="uploadKTP"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p
                                  class="section-description-sm text-2"
                                  v-if="loading_result_credit"
                                >
                                  <b-skeleton
                                    animation="wave"
                                    width="70%"
                                  ></b-skeleton>
                                </p>
                                <p class="section-description-sm text-2" v-else>
                                  Angsuran per Bulan
                                </p>
                                <h4
                                  class="section-description-lg"
                                  v-if="loading_result_credit"
                                >
                                  <b-skeleton
                                    animation="wave"
                                    width="85%"
                                  ></b-skeleton>
                                </h4>
                                <h4 class="section-description-lg" v-else>
                                  {{ result_credit.angsuran | currency }}
                                </h4>
                                <div class="form-group mt-2">
                                  <p
                                    class="section-description-xs opacity-7 mb-2"
                                  >
                                    Apakah Anda memiliki Kode Promo? (Opsional)
                                  </p>

                                  <div class="flex-2-column align-items-center">
                                    <div class="flex-col align-self-stretch">
                                      <div class="form-group">
                                        <input
                                          v-model="
                                            form_booking_order_payment_cash.promotion_code
                                          "
                                          type="text"
                                          name=""
                                          class="form-control mb-1"
                                          placeholder="Masukkan Kode Promo"
                                        />
                                        <b-alert
                                          v-if="
                                            message_promo_code_credit.status_valid ==
                                            true
                                          "
                                          v-model="
                                            showDismissibleAlertPromoCredit
                                          "
                                          variant="success"
                                          >{{
                                            message_promo_code_credit.message
                                          }}</b-alert
                                        >
                                        <b-alert
                                          v-if="
                                            message_promo_code_credit.status_tidak_valid ==
                                            true
                                          "
                                          v-model="
                                            showDismissibleAlertPromoCredit
                                          "
                                          variant="danger"
                                          >{{
                                            message_promo_code_credit.message
                                          }}</b-alert
                                        >
                                      </div>
                                    </div>
                                    <div class="flex-col align-self-stretch">
                                      <button
                                        :disabled="
                                          form_booking_order_payment_cash.promotion_code ==
                                          ''
                                        "
                                        v-if="!isSave"
                                        type="submit"
                                        class="btn btn-md btn-outline-dark py-2 font-weight-600 ml-1"
                                        @click.prevent="cekKodePromoCredit()"
                                      >
                                        Gunakan Kode
                                        <i class="fa fa-arrow-right ml-2"></i>
                                      </button>
                                      <button
                                        v-if="isSave"
                                        type="submit"
                                        class="btn btn-md btn-outline-dark py-2 font-weight-600 ml-1"
                                      >
                                        <span
                                          class="spinner-border spinner-border-sm"
                                        ></span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>

                              <div class="row">
                                <div
                                  class="col-lg-5"
                                  v-if="result_promotion_credit.length != 0"
                                >
                                  <p class="section-description-sm text-2">
                                    Angsuran per Bulan
                                  </p>
                                  <h4
                                    class="section-description-lg text-accent"
                                  >
                                    <del>{{
                                      result_promotion_credit.angsuran
                                        | currency
                                    }}</del>
                                  </h4>
                                </div>
                                <div
                                  class="col-lg-7 px-lg-1 mt-2 mt-lg-0"
                                  v-if="result_promotion_credit.length != 0"
                                >
                                  <p class="section-description-sm text-2">
                                    Angsuran per Bulan dengan kode promo
                                    <b class="text-dark">{{
                                      form_booking_order_payment_cash.promotion_code
                                    }}</b>
                                  </p>
                                  <h4 class="section-description-lg">
                                    {{
                                      result_promotion_credit.angsuran_promo
                                        | currency
                                    }}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="box-actions">
                    <a
                      class="text-accent font-weight-bold btn-prev"
                      @click.prevent="prevStep()"
                      ><span class="ion-arrow-left-c"></span> Previous</a
                    >
                    <button
                      v-if="
                        form_booking_order_payment_cash.payment_type == 'cash'
                      "
                      :disabled="$v.form_booking_order_payment_cash.$invalid"
                      class="btn btn-lg btn-yellow font-weight-bold btn-next"
                      @click.prevent="nextStep()"
                    >
                      Next <span class="ion-arrow-right-c"></span>
                    </button>
                    <button
                      v-if="
                        form_booking_order_payment_cash.payment_type == 'kredit'
                      "
                      :disabled="$v.form_booking_order_payment_credit.$invalid"
                      class="btn btn-lg btn-yellow font-weight-bold btn-next"
                      @click.prevent="nextStep()"
                    >
                      Next <span class="ion-arrow-right-c"></span>
                    </button>
                  </div>
                </div>
                <!-- final result  -->
                <div v-if="step == 5">
                  <div class="row">
                    <div class="col-lg-6 border-right">
                      <div class="wrap-content">
                        <div class="row mb-2">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Kategori Mobil
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.category_car }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Model
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.car_model_name }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Transmisi
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Transmisi }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Tipe
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Tipe_mobil }}
                                </p>
                              </div>
                              <div class="col-lg-4 col-6 mb-2">
                                <p class="section-description-sm text-2">
                                  Pilihan Warna
                                </p>
                                <p class="section-description-sm text-3">
                                  {{ info_selected.Pilihan_Warna }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <p class="section-description-sm text-2">Cabang</p>
                            <p class="section-description-sm text-3">
                              {{ info_selected.Cabang }}
                            </p>
                          </div>
                        </div>
                        <!-- cash  -->
                        <div
                          class="card card-style h-dark shadow-none border mt-3"
                          v-if="
                            form_booking_order_payment_cash.payment_type ==
                            'cash'
                          "
                        >
                          <div
                            class="card-header d-lg-flex align-items-center justify-content-between"
                          >
                            <p class="section-description-sm">PENGHITUNGAN :</p>
                            <span
                              class="badge badge-tipe-pembayaran section-description-sm py-1 px-lg-5 px-4"
                              >CASH</span
                            >
                          </div>
                          <div class="card-body">
                            <div class="row">
                              <div
                                class="col-lg-6"
                                v-if="
                                  form_booking_order_payment_cash.price_otr != 0
                                "
                              >
                                <p class="section-description-sm text-2">
                                  Total Biaya
                                </p>
                                <h4
                                  class="section-description-lg text-accent"
                                  v-if="message_promo == 'Valid'"
                                >
                                  <del>{{
                                    form_booking_order_payment_cash.price_otr
                                      | currency
                                  }}</del>
                                </h4>
                                <h4 v-else class="section-description-lg">
                                  {{
                                    form_booking_order_payment_cash.price_otr
                                      | currency
                                  }}
                                </h4>
                              </div>
                              <div
                                class="col-lg-6 pr-lg-1 mt-2 mt-lg-0"
                                v-if="message_promo == 'Valid'"
                              >
                                <p class="section-description-sm text-2">
                                  Total Biaya dengan Kode Promo
                                  <b class="text-dark">{{
                                    result_promotion_code.promotion_code
                                  }}</b>
                                </p>
                                <h4 class="section-description-lg">
                                  {{
                                    form_booking_order_payment_cash.price_otr_promo
                                      | currency
                                  }}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- credit  -->
                        <div
                          class="card card-style h-dark shadow-none border mt-3"
                          v-if="
                            form_booking_order_payment_cash.payment_type ==
                            'kredit'
                          "
                        >
                          <div
                            class="card-header d-lg-flex align-items-center justify-content-between"
                          >
                            <p class="section-description-sm">PENGHITUNGAN :</p>
                            <span
                              class="badge badge-tipe-pembayaran section-description-sm py-1 px-lg-5 px-4"
                              >KREDIT</span
                            >
                          </div>
                          <div class="card-body">
                            <p class="section-description-sm text-2">
                              Total Biaya
                            </p>
                            <h4 class="section-description-lg">
                              {{
                                form_booking_order_payment_cash.price_otr
                                  | currency
                              }}
                            </h4>

                            <div
                              class="price-display-2 mt-2"
                              v-if="
                                form_booking_order_payment_credit.uang_muka !=
                                  0 &&
                                form_booking_order_payment_credit.tenor != 0
                              "
                            >
                              <div class="box-price">
                                <h5
                                  class="section-description-sm font-weight-600 mb-0"
                                >
                                  {{
                                    form_booking_order_payment_credit.uang_muka
                                      | currency
                                  }}
                                </h5>
                                <p
                                  class="section-description-xs mb-0 opacity-7"
                                >
                                  Uang Muka
                                </p>
                              </div>
                              <div class="box-price">
                                <h5
                                  class="section-description-sm font-weight-600 mb-0"
                                >
                                  {{ form_booking_order_payment_credit.tenor }}
                                </h5>
                                <p
                                  class="section-description-xs mb-0 opacity-7"
                                >
                                  Tenor / Jangka Waktu
                                </p>
                              </div>
                            </div>

                            <div class="row mt-2">
                              <div
                                class="col-lg-6 mb-2"
                                v-if="
                                  form_booking_order_payment_cash.promotion_code ==
                                  ''
                                "
                              >
                                <p class="section-description-sm text-2">
                                  Angsuran per bulan harga normal
                                </p>
                                <h4
                                  class="section-description font-weight-800 text-accent"
                                >
                                  <h4
                                    class="section-description-md font-weight-800"
                                  >
                                    {{ result_credit.angsuran | currency }}
                                  </h4>
                                </h4>
                              </div>

                              <div class="col-lg-6 mb-2" v-else>
                                <p class="section-description-sm text-2">
                                  Angsuran per bulan harga normal
                                </p>
                                <h4
                                  v-if="result_promotion_credit.length != 0"
                                  class="section-description font-weight-800 text-accent"
                                >
                                  <del>{{
                                    result_credit.angsuran | currency
                                  }}</del>
                                </h4>
                                <h4
                                  v-else
                                  class="section-description font-weight-800"
                                >
                                  {{ result_credit.angsuran | currency }}
                                </h4>
                              </div>

                              <div
                                class="col-lg-6 mb-2"
                                v-if="result_promotion_credit.length != 0"
                              >
                                <p class="section-description-sm text-2">
                                  Angsuran per bulan promo
                                  <span class="font-weight-bold text-dark">{{
                                    form_booking_order_payment_cash.promotion_code
                                  }}</span>
                                </p>
                                <h4
                                  class="section-description-md font-weight-800"
                                >
                                  {{
                                    result_promotion_credit.angsuran_promo
                                      | currency
                                  }}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="wrap-content">
                        <img
                          :data-src="info_selected.image_pick_color"
                          class="img-fluid lazyload img-car-preview"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="box-actions">
                    <a
                      class="text-accent font-weight-bold btn-prev"
                      @click.prevent="prevStep()"
                      ><span class="ion-arrow-left-c"></span> Previous</a
                    >
                    <a
                      v-if="!isSave"
                      type="submit"
                      class="btn btn-lg btn-yellow font-weight-bold btn-booking"
                      @click.prevent="submitOrder()"
                    >
                      Booking Fee<span class="ion-arrow-right-c"></span>
                    </a>
                    <a
                      v-if="isSave"
                      :disabled="isSave"
                      type="submit"
                      class="btn btn-lg btn-yellow font-weight-bold btn-booking"
                    >
                      <span class="spinner-border spinner-border-sm"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal fade modal-style" id="bookingfee-modal">
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <h3 class="section-heading-xs">Booking Fee</h3>
            <p class="section-description-sm">
              Anda selanjutnya akan diarahkan pada halaman pembayaran booking
              fee, Apakah Anda akan meneruskan ke step berikutnya?
            </p>
            <button
              type="button"
              class="btn btn-md btn-yellow font-weight-600 px-4 float-right btn-proses"
            >
              PROSES <i class="fa fa-arrow-right ml-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
export default {
  name: "Order",
  metaInfo() {
    return {
      title: "Order - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {
      step: 1,
      totalsteps: 5,
      isCekRangka: false,
      options: {
        format: "YYYY-MM-DD",
        useCurrent: true,
      },
      tenor_option: [
        { text: "12 bulan", value: 12 },
        { text: "24 bulan", value: 24 },
        { text: "36 bulan", value: 36 },
        { text: "48 bulan", value: 48 },
        { text: "60 bulan", value: 60 },
      ],
      gender_option: [
        { text: "Laki-laki", value: "Laki-laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      payment_option: [
        { text: "cash", value: "cash" },
        { text: "kredit", value: "kredit" },
      ],
      jenis_service: [
        { text: "General Service", value: "general-service" },
        { text: "Body & Paint", value: "body-paint" },
      ],
      Car_Transmision: [
        { label: "AT", value: "AT" },
        { label: "MT", value: "MT" },
      ],
      tahun: [
        {
          value: "",
        },
      ],
      showDismissibleAlertRangka: false,
      message_no_rangka: {
        status_valid: false,
        status_tidak_valid: false,
        message: "",
      },
      showDismissibleAlertPromo: false,
      message_promo_code: {
        status_valid: false,
        status_tidak_valid: false,
        message: "",
      },
      showDismissibleAlertPromoCredit: false,
      message_promo_code_credit: {
        status_valid: false,
        status_tidak_valid: false,
        message: "",
      },
    };
  },
  validations() {
    if (!this.isAuth) {
      return {
        form_booking_order_profile: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(8),
          },
          confirm_password: {
            sameAsPassword: sameAs("password"),
          },
          birth: {
            required,
          },
          phone: {
            required,
            minLength: minLength(10),
          },
          address: {
            required,
          },
          gender: {
            required,
          },
          province: {
            required,
          },
          city: {
            required,
          },
          cek_list_agree: {
            required,
          },
          cek_list_penawaran: {
            required,
          },
        },
        form_booking_order_pick_car: {
          car_category: {
            required,
          },
          car_model: {
            required,
          },
          car_transmission: {
            required,
          },
          car_type: {
            required,
          },
          kota_cabang: {
            required,
          },
          cabang_id: {
            required,
          },
        },
        form_booking_order_pick_color: {
          car_color: {
            required,
          },
        },
        form_booking_order_payment_credit: {
          ktp: {
            required,
          },
          uang_muka: {
            required,
          },
          tenor: {
            required,
          },
          angsuran: {
            required,
          },
        },
        form_booking_order_payment_cash: {
          payment_type: {
            required,
          },
          price_otr: {
            required,
          },
        },
      };
    } else {
      return {
        form_booking_order_profile: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          birth: {
            required,
          },
          phone: {
            required,
            minLength: minLength(10),
          },
          address: {
            required,
          },
          gender: {
            required,
          },
          province: {
            required,
          },
          city: {
            required,
          },
          cek_list_agree: {
            required,
          },
          cek_list_penawaran: {
            required,
          },
        },
        form_booking_order_pick_car: {
          car_category: {
            required,
          },
          car_model: {
            required,
          },
          car_transmission: {
            required,
          },
          car_type: {
            required,
          },
          kota_cabang: {
            required,
          },
          cabang_id: {
            required,
          },
        },
        form_booking_order_pick_color: {
          car_color: {
            required,
          },
        },
        form_booking_order_payment_credit: {
          ktp: {
            required,
          },
          uang_muka: {
            required,
          },
          tenor: {
            required,
          },
          angsuran: {
            required,
          },
        },
        form_booking_order_payment_cash: {
          payment_type: {
            required,
          },
          price_otr: {
            required,
          },
        },
      };
    }
  },
  created() {
    this.info_selected.Transmisi = this.form_booking_order_pick_car.car_transmission;
    this.getProvince();
    this.getCarCategory();
    this.getYear();
    this.getKotaCabang();
    if (this.isAuth) {
      this.form_booking_order_profile.name = this.isSharedPreference.full_name;
      this.form_booking_order_profile.email = this.isSharedPreference.member_email;
      this.form_booking_order_profile.phone = this.isSharedPreference.phone;
      this.form_booking_order_profile.birth = this.isSharedPreference.birth;
      this.form_booking_order_profile.gender = this.isSharedPreference.gender;
      this.form_booking_order_profile.no_rangka = this.isSharedPreference.no_rangka;
      this.form_booking_order_profile.province = this.isSharedPreference.province_id;
      this.form_booking_order_profile.province_name = this.isSharedPreference.province_name;
      this.form_booking_order_profile.city = this.isSharedPreference.city_id;
      this.form_booking_order_profile.city_name = this.isSharedPreference.city_name;
      this.form_booking_order_profile.address = this.isSharedPreference.address;
      this.form_booking_order_profile.token = this.isSharedPreference.token;
    }
    window.scrollTo(0, 0);
  },
  watch: {
    form_booking_order_profile: {
      handler(newValue) {
        if (newValue.province != "") {
          this.getKota({
            province_code: newValue.province,
          });
        }
      },
      deep: true,
    },
    form_booking_order_pick_car: {
      handler(newValue) {
        if (newValue.car_category != "") {
          this.getCarModel({
            category_id: newValue.car_category,
          });
        }
        if (newValue.car_transmission != "" && newValue.car_model) {
        }
        if (newValue.car_model) {
          this.getCarColor({
            car_model_id: newValue.car_model,
          });
        }
        if (newValue.kota_cabang != "") {
          this.getCabang({
            city_code: newValue.kota_cabang,
          });
        }
      },
      deep: true,
    },
    form_booking_order_payment_credit: {
      handler(newValue) {
        if (
          this.form_booking_order_pick_car.car_transmission != "" &&
          this.form_booking_order_pick_car.car_model != "" &&
          this.form_booking_order_pick_car.car_type != "" &&
          newValue.uang_muka != 0 &&
          newValue.tenor != 0
        ) {
          this.getSimulationCar({
            car_model_id: this.form_booking_order_pick_car.car_model,
            car_model_transmission: this.form_booking_order_pick_car
              .car_transmission,
            car_model_type: this.form_booking_order_pick_car.car_type,
            uang_muka: newValue.uang_muka,
            tenor: newValue.tenor,
          });
          this.form_booking_order_payment_credit.angsuran = this.result_credit.angsuran;
        }
      },
      deep: true,
    },
  },
  mounted() {
    $("[background-color]").each(function () {
      $(this).css("background-color", $(this).attr("background-color"));
    });
  },
  computed: {
    ...mapState(["isSave"]),
    ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
    ...mapState(["errors"]),
    ...mapState("regencies", {
      province: (state) => state.province,
      kota: (state) => state.kota,
    }),
    ...mapState("member", {
      tabContent: (state) => state.tabContent,
    }),
    ...mapState("cabang", {
      kota_cabang: (state) => state.kota_cabang,
      cabang: (state) => state.cabang,
    }),
    ...mapState("auth", {
      message_rangka: (state) => state.message_rangka,
    }),
    ...mapState("detail_car", {
      result_credit: (state) => state.result_credit,
      loading_result_credit: (state) => state.loading_result_credit,
    }),
    ...mapState("booking_order", {
      form_booking_order_profile: (state) => state.form_booking_order_profile,
      form_booking_order_pick_car: (state) => state.form_booking_order_pick_car,
      form_booking_order_pick_color: (state) =>
        state.form_booking_order_pick_color,
      form_booking_order_payment_cash: (state) =>
        state.form_booking_order_payment_cash,
      form_booking_order_payment_credit: (state) =>
        state.form_booking_order_payment_credit,
      car_category: (state) => state.car_category,
      car_model: (state) => state.car_model,
      car_type: (state) => state.car_type,
      loading_car_type: (state) => state.loading_car_type,
      car_color: (state) => state.car_color,
      info_selected: (state) => state.info_selected,
      result_promotion_code: (state) => state.result_promotion_code,
      message_promo: (state) => state.message_promo,
      result_promotion_credit: (state) => state.result_promotion_credit,
      message_promo_credit: (state) => state.message_promo_credit,
    }),
  },
  methods: {
    ...mapActions("auth", ["cekNoRangka"]),
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("regencies", ["getProvince", "getKota"]),
    ...mapActions("cabang", ["getKotaCabang", "getCabang"]),
    ...mapActions("detail_car", ["getSimulationCar"]),
    ...mapActions("booking_order", [
      "insertDataBookingOrder",
      "getCarCategory",
      "getCarModel",
      "getCarTypeOrder",
      "getCarColor",
      "getPromotionCode",
      "getPromotionCodeCredit",
      "Clear_State",
    ]),

    pickedColor(img, color) {
      this.info_selected.image_pick_color = img;
      this.info_selected.Pilihan_Warna = color;
    },
    pickCarType(name, price) {
      this.info_selected.car_pick_name = name;
      this.info_selected.Tipe_mobil = name;
      this.info_selected.Harga = price;
      this.form_booking_order_payment_cash.price_otr = price;
    },
    nextStep() {
      if (this.totalsteps <= 5) {
        this.step++;
      }
    },
    prevStep() {
      if (this.step != 1) {
        this.step--;
      }
    },
    cekStatusNorangka() {
      this.isCekRangka = true;
      this.cekNoRangka({
        no_rangka: this.form_booking_order_profile.no_rangka,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          if (this.message_rangka == "valid") {
            this.message_no_rangka.status_valid = true;
            this.message_no_rangka.status_tidak_valid = false;
            this.message_no_rangka.message = "Anda Adalah Member Lama Nasmoco";
            this.showDismissibleAlertRangka = true;
          } else if (this.message_rangka == "tidak valid") {
            this.message_no_rangka.status_valid = false;
            this.message_no_rangka.status_tidak_valid = true;
            this.message_no_rangka.message = "Anda Adalah Member Baru Nasmoco";
            this.showDismissibleAlertRangka = true;
          }
          this.isCekRangka = false;
        })
        .catch((error) => {
          this.isCekRangka = false;
        });
    },
    getYear() {
      var max = new Date().getFullYear();
      var min = 1900;
      for (var i = max; i >= min; i--) {
        this.tahun.push({
          value: i,
        });
      }
      this.tahun.splice(0, 1);
    },
    // is number
    // keypres untuk input float data type
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // regencies
    setValIdProvince(val) {
      this.form_booking_order_profile.province = val.code;
      this.form_booking_order_profile.province_name = val.provinsi;
    },
    setValIdKota(val) {
      this.form_booking_order_profile.city = val.kode;
      this.form_booking_order_profile.city_name = val.nama;
    },
    setValIdkategoriMobil(val) {
      this.form_booking_order_pick_car.car_category = val.category_id;
      this.form_booking_order_pick_car.car_category_name = val.category_name;
      this.info_selected.category_car = val.category_name;
    },
    setValIdCarModel(val) {
      this.form_booking_order_pick_car.car_model = val.car_model_id;
      this.form_booking_order_pick_car.car_model_name = val.car_mode_name;
      this.info_selected.car_model_name = val.car_mode_name;
      this.getCarTypeOrder({
        car_model_id: this.form_booking_order_pick_car.car_model,
        car_transmission: this.form_booking_order_pick_car.car_transmission,
      });
    },
    setValIdTransmisi(val) {
      this.form_booking_order_pick_car.car_transmission = val.value;
      this.form_booking_order_pick_car.car_transmission_name = val.value;
      this.info_selected.Transmisi = val.value;
      this.getCarTypeOrder({
        car_model_id: this.form_booking_order_pick_car.car_model,
        car_transmission: this.form_booking_order_pick_car.car_transmission,
      });
    },
    setValIdKotaCabang(val) {
      this.form_booking_order_pick_car.kota_cabang = val.city_code;
      this.form_booking_order_pick_car.kota_cabang_name = val.city_name;
    },
    setValIdCabang(val) {
      this.form_booking_order_pick_car.cabang_id = val.cabang_id;
      this.form_booking_order_pick_car.cabang_id_name = val.cabang_name;
      this.info_selected.Cabang = val.cabang_name;
    },
    setValIdTenor(val) {
      this.form_booking_order_payment_credit.tenor = val.value;
      if (this.form_booking_order_payment_credit.promotion_code != "") {
        this.cekKodePromoCredit();
      }
    },
    cekKodePromoCash() {
      this.getPromotionCode({
        promo_code: this.form_booking_order_payment_cash.promotion_code,
        car_type: this.form_booking_order_pick_car.car_type,
        payment_type: this.form_booking_order_payment_cash.payment_type,
        down_payment: this.form_booking_order_payment_credit.uang_muka,
        tenor: this.form_booking_order_payment_credit.tenor,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          if (this.message_promo == "Valid") {
            this.message_promo_code.status_valid = true;
            this.message_promo_code.status_tidak_valid = false;
            this.form_booking_order_payment_cash.price_otr = this.result_promotion_code.sub_total;
            this.form_booking_order_payment_cash.price_otr_promo = this.result_promotion_code.total;
            this.message_promo_code.message = "*Berhasil memakai kode promo";
            this.showDismissibleAlertPromo = true;
          }
        })
        .catch((error) => {
          if (this.message_promo == "Invalid") {
            this.message_promo_code.status_valid = false;
            this.message_promo_code.status_tidak_valid = true;
            this.message_promo_code.message = "*Kode Promo tidak Berlaku";
            this.form_booking_order_payment_cash.price_otr_promo = 0;
            this.showDismissibleAlertPromo = true;
          }
        });
    },
    cekKodePromoCredit() {
      this.getPromotionCodeCredit({
        promo_code: this.form_booking_order_payment_cash.promotion_code,
        car_type: this.form_booking_order_pick_car.car_type,
        payment_type: this.form_booking_order_payment_cash.payment_type,
        down_payment: this.form_booking_order_payment_credit.uang_muka,
        tenor: this.form_booking_order_payment_credit.tenor,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          if (this.message_promo_credit == "Valid") {
            this.message_promo_code_credit.status_valid = true;
            this.message_promo_code_credit.status_tidak_valid = false;
            this.form_booking_order_payment_cash.price_otr = this.result_credit.price_otr;
            this.form_booking_order_payment_credit.angsuran = this.result_promotion_credit.angsuran;
            this.form_booking_order_payment_credit.angsuran_promo = this.result_promotion_credit.angsuran_promo;
            this.message_promo_code_credit.message =
              "*Berhasil memakai kode promo";
            this.showDismissibleAlertPromoCredit = true;
          }
        })
        .catch((error) => {
          if (this.message_promo_credit == "Invalid") {
            this.message_promo_code_credit.status_valid = false;
            this.message_promo_code_credit.status_tidak_valid = true;
            this.message_promo_code_credit.message =
              "*Kode Promo tidak Berlaku";
            this.form_booking_order_payment_credit.angsuran_promo = 0;
            this.showDismissibleAlertPromoCredit = true;
          }
        });
    },
    uploadKTP(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      let limit = 1024 * 1024 * 2;
      if (file["size"] > limit) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File terlalu besar pilih yang lain",
        });
        return false;
      }
      reader.onloadend = (file) => {
        this.form_booking_order_payment_credit.ktp = reader.result;
      };
      reader.readAsDataURL(file);
    },
    //insert booking order
    submitOrder() {
      this.$Progress.start();
      this.insertDataBookingOrder({
        name: this.form_booking_order_profile.name,
        email: this.form_booking_order_profile.email,
        phone: this.form_booking_order_profile.phone,
        address: this.form_booking_order_profile.address,
        gender: this.form_booking_order_profile.gender,
        birth: this.form_booking_order_profile.birth,
        no_rangka: this.form_booking_order_profile.no_rangka,
        password: this.form_booking_order_profile.password,
        province: this.form_booking_order_profile.province,
        city: this.form_booking_order_profile.city,
        car_category: this.form_booking_order_pick_car.car_category,
        car_model: this.form_booking_order_pick_car.car_model,
        car_transmission: this.form_booking_order_pick_car.car_transmission,
        car_type: this.form_booking_order_pick_car.car_type,
        cabang_id: this.form_booking_order_pick_car.cabang_id,
        car_color: this.form_booking_order_pick_color.car_color,
        payment_type: this.form_booking_order_payment_cash.payment_type,
        price_otr: this.form_booking_order_payment_cash.price_otr,
        price_otr_promo: this.form_booking_order_payment_cash.price_otr_promo,
        uang_muka: this.form_booking_order_payment_credit.uang_muka,
        tenor: this.form_booking_order_payment_credit.tenor,
        angsuran: this.form_booking_order_payment_credit.angsuran,
        angsuran_promo: this.form_booking_order_payment_credit.angsuran_promo,
        promotion_code: this.form_booking_order_payment_cash.promotion_code,
        ktp: this.form_booking_order_payment_credit.ktp,
        token: this.form_booking_order_profile.token,
        channel: "booking-order",
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Booking Order berhasil ditambahkan",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$router.push({
            name: "Order_info",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          toast.fire({
            icon: "error",
            title: this.errors,
          });
          this.$Progress.fail();
        });
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>