<template>
  <div>
    <section
      class="intro-md"
      v-if="single_page_id.image"
      :data-background="single_page_id.image"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text pt-lg-5">
              <h2 class="section-heading">{{ single_page_id.title }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-md">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 m-auto" v-html="single_page_id.text"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "SinglePage",
  metaInfo() {
    return {
      title: "Info - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {
      single_page_background: "",
      load_background: false,
    };
  },
  mounted() {
    var vm = this;
    // reloading promotion
    this.getSinglePageByID(this.$route.params.profil)
      .then((res) => {
        vm.single_page_background = this.single_page_id.image;
        if (vm.single_page_background != "") {
          vm.load_background = true;
          vm.$nextTick(
            function () {
              vm.DataBackground();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  },
  computed: {
    ...mapState("single_page", {
      single_page_id: (state) => state.single_page_id,
    }),
  },
  methods: {
    ...mapActions("single_page", ["getSinglePageByID"]),

    DataBackground: function () {
      // attr data-background
      $("[data-background]").each(function () {
        $(this).css(
          "background-image",
          "url(" + $(this).attr("data-background") + ")"
        );
      });
    },
  },
  created() {
    this.getSinglePageByID(this.$route.params.profil);
    window.scrollTo(0, 0);
  },
};
</script>