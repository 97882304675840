<template>
  <div id="app">
    <div class="site-wrap" id="main-layout" v-if="$route.meta.mainLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
      <app-footer />
      <app-copyright />
    </div>
    <div class="site-wrap" id="order-layout" v-if="$route.meta.orderLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
    </div>
    <div class="site-wrap" id="member-layout" v-if="$route.meta.memberLayout">
      <app-header />
      <app-sticky />
      <transition name="slide-fade">
        <router-view :key="$route.fullPath"> </router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
      <app-copyright />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Copyright from "@/components/Copyright.vue";
import Sticky from "@/components/Sticky.vue";

export default {
  name: "App",
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-copyright": Copyright,
    "app-sticky": Sticky,
  },
  mounted() {
    this.$Progress.finish();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("path/to/service-worker.js")
        .then((reg) => {
          console.log("Registration succesful, scope: " + reg.scope);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  methods: {
    async accept() {
      this.showUpgradeUI = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      this.$Progress.finish();
    });

    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}

.info-box-cus {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  position: relative;
}
</style>