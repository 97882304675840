import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import After_sale from '../views/After_sale.vue'
import After_sale_detail from '../views/after_sale_detail.vue'
import Promo from '../views/Promo.vue'
import Trade_in from '../views/Trade_in.vue'
import Booking_service from '../views/booking_service.vue'
import Sparepart from '../views/spare_part.vue'
import Compare_car from '../views/compare_car.vue'
import Contact_us from '../views/contact_us.vue'
import Credit_simulation from '../views/credit_simulation.vue'
import Dashboard_member from '../views/dashboard_member.vue'
import Detail_product from '../views/details_product.vue'
import Download_brochure from '../views/download_brochure.vue'
import Find_dealer from '../views/find_dealer.vue'
import Find_dealer_detail from '../views/find_dealer_detail'
import Nasmoco_journey from '../views/nasmoco_journey.vue'
import News_read from '../views/news_read.vue'
import Order_info from '../views/order_info.vue'
import Order_success from '../views/order_success.vue'
import Order from '../views/order.vue'
import Pricelist_page from '../views/pricelist_page.vue'
import Single_page from '../views/single_page.vue'
import Test_drive from '../views/test_drive.vue'
import Nasmoco_Club from '../views/nasmoco_club'
import Nasmoco_Club_Detail from '../views/nasmoco_club_detail'

//detail transaksi
import Detail_booking_order from '../views/detail_booking_order'
import Detail_booking_service from '../views/detail_booking_service'
import Detail_test_drive from '../views/detail_test_drive'
import Detail_trade_in from '../views/detail_trade_in'


//store
import store from '../store/index.js'
Vue.use(VueRouter)

const routes = [
  //home route
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      mainLayout: true
    }
  },
  //end home route

  {
    path: '/after-sales',
    name: 'After-sale',
    component: After_sale,
    meta: {
      mainLayout: true
    },
  },
  {
    path: '/nasmoco-club',
    name: 'nasmoco_club',
    component: Nasmoco_Club,
    meta: {
      mainLayout: true
    },
  },
  {
    path: '/nasmoco-club/:link',
    name: 'nasmoco_club_detail',
    component: Nasmoco_Club_Detail,
    meta: {
      mainLayout: true
    },
  },
  // end after sale route
  //start promo
  {
    path: '/promo',
    name: 'Promo',
    component: Promo,
    meta: {
      mainLayout: true
    }
  },

  //end news & promo
  {
    path: '/trade-in',
    name: 'Trade_in',
    component: Trade_in,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/booking-service',
    name: 'Booking_service',
    component: Booking_service,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/sparepart-accesoris',
    name: 'Sparepart',
    component: Sparepart,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/compare-car',
    name: 'Compare_car',
    component: Compare_car,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/contact-us',
    name: 'Contact_us',
    component: Contact_us,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/credit-simulation',
    name: 'Credit_simulation',
    component: Credit_simulation,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/dashboard-member',
    name: 'Dashboard_member',
    component: Dashboard_member,
    meta: {
      memberLayout: true
    }
  },

  {
    path: '/brochure',
    name: 'Download_brochure',
    component: Download_brochure,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/find-dealer',
    name: 'Find_dealer',
    component: Find_dealer,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/find-dealer/:link',
    name: 'Find_dealer_detail',
    component: Find_dealer_detail,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/nasmoco-journey',
    name: 'Nasmoco_journey',
    component: Nasmoco_journey,
    meta: {
      mainLayout: true
    }
  },

  {
    path: '/order-info',
    name: 'Order_info',
    component: Order_info,
    meta: {
      orderLayout: true
    }
  },
  {
    path: '/order-success',
    name: 'Order_success',
    component: Order_success,
    meta: {
      orderLayout: true
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    meta: {
      orderLayout: true
    }
  },
  {
    path: '/price-list',
    name: 'Pricelist_page',
    component: Pricelist_page,
    meta: {
      mainLayout: true
    }
  },
  // Single Page
  {
    path: '/test-drive',
    name: 'Test_drive',
    component: Test_drive,
    meta: {
      mainLayout: true
    }
  },

  {
    path: '/:link',
    name: 'Detail_car',
    component: Detail_product,
    meta: {
      mainLayout: true
    },
  },
  // after sale route 
  {
    path: '/after-sales/:link',
    name: 'After_sale_detail',
    component: After_sale_detail,
    meta: {
      mainLayout: true
    },
  },
  {
    path: '/info/:profil',
    name: 'Single_page',
    component: Single_page,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/news/:link',
    name: 'News_read',
    component: News_read,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/bookingorder/detail/:id',
    name: 'Detail_booking_order',
    component: Detail_booking_order,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/bookingservice/detail/:id',
    name: 'Detail_booking_service',
    component: Detail_booking_service,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/testdrive/detail/:id',
    name: 'Detail_test_drive',
    component: Detail_test_drive,
    meta: {
      mainLayout: true
    }
  },
  {
    path: '/tradein/detail/:id',
    name: 'Detail_trade_in',
    component: Detail_trade_in,
    meta: {
      mainLayout: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
