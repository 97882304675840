<template>
  <div>
    <section class="intro-md" data-background="assets/img/bg-1.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="block-text">
              <p class="section-subheading">AFTER SALES</p>
              <h2 class="section-heading">Toyota Ada untuk Anda</h2>
              <p class="section-description-sm">
                Nasmoco Group menyediakan berbagai macam layanan terbaik yang
                siap memenuhi kebutuhan mobilitas Anda.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section-sm trusting-sales-section">
      <div class="container">
        <div
          class="card card-style bg-image center"
          data-background="assets/img/cover-1.png"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5">
                <div class="block-text">
                  <h2 class="section-description font-weight-500 text-white">
                    Thousands of Customers Trusting Us Preferred Color Universe!
                  </h2>
                  <p class="section-description-xs text-white">
                    Average star rating based on the listed sources. 02/26/2018
                  </p>
                </div>
              </div>
              <div class="col-lg-7 pl-lg-5">
                <div class="flex-3-column">
                  <div class="flex-col align-self-stretch text-center">
                    <img
                      data-src="assets/img/logo-amazon.png"
                      class="img-fluid lazyload logo"
                    />
                    <p class="section-description-sm text-white mb-1">
                      30.000+ sales
                    </p>
                    <img
                      data-src="assets/img/img-five-stars.png"
                      class="img-fluid lazyload stars"
                    />
                  </div>
                  <div class="flex-col align-self-stretch text-center">
                    <img
                      data-src="assets/img/logo-ebay.png"
                      class="img-fluid lazyload logo"
                    />
                    <p class="section-description-sm text-white mb-1">
                      15.000+ sales
                    </p>
                    <img
                      data-src="assets/img/img-five-stars.png"
                      class="img-fluid lazyload stars"
                    />
                  </div>
                  <div class="flex-col align-self-stretch text-center">
                    <img
                      data-src="assets/img/logo-google-trusted.png"
                      class="img-fluid lazyload logo"
                    />
                    <p class="section-description-sm text-white mb-1">
                      50.000+ sales
                    </p>
                    <img
                      data-src="assets/img/img-five-stars.png"
                      class="img-fluid lazyload stars"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="serve-section bg-white section-md">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="box-section-title text-center">
              <h2 class="section-heading">We Serve <span>You Better</span></h2>
              <p class="section-description-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="flex-2-column">
              <div
                class="flex-col"
                v-for="(row, index) in after_sale.icon"
                :key="index"
              >
                <router-link
                  v-if="row.link == 'booking-service'"
                  :to="{
                    name: 'Booking_service',
                  }"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </router-link>

                <router-link
                  v-if="row.link == 'sparepart-and-accesories'"
                  :to="{
                    name: 'Sparepart',
                  }"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </router-link>

                <router-link
                  v-if="
                    row.link == 'general-repair' || row.link == 'body-and-paint'
                  "
                  :to="{
                    name: 'After_sale_detail',
                    params: { link: row.link },
                  }"
                  class="card card-style trans-ease-in-out"
                >
                  <div class="card-body">
                    <div class="icon trans-ease-in-out">
                      <img
                        :data-src="row.image"
                        alt=""
                        class="img-fluid lazyloaded"
                        :src="row.image"
                      />
                    </div>
                    <h4 class="section-description">{{ row.title }}</h4>
                    <p class="section-description-sm">
                      {{ row.teks }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="near-us section-md bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-left">
            <div
              class="card-video bg-image center d-flex align-items-center justify-content-center"
              data-background="assets/img/bg-3.png"
            ></div>
          </div>
          <div class="col-lg-6">
            <div class="box-section-title">
              <p class="section-subheading-xs mb-2">OUR MISSION</p>
              <h2 class="section-heading">
                <span>Get the best offer</span> with us
              </h2>
              <p class="section-description-sm">
                Dapatkan penawaran spesial dan menarik untuk kendaraan Anda
                hanya di Nasmoco!
              </p>

              <div class="find-call">
                <router-link
                  :to="{ name: 'Find_dealer' }"
                  class="btn btn-padd btn-dark rounded px-4"
                  ><span>FIND DEALER</span>
                  <i class="fa fa-caret-right ml-3"></i
                ></router-link>
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-4"
                  ><i class="fab fa-whatsapp font-weight-400 mr-2"></i
                  >WHATSAPP</a
                >
                <!-- <a href="https://wa.me/628981260060" target="_blank" class="btn btn-padd btn-white text-dark call"><i class="fa fa-phone mr-2"></i> <span>0898 - 1260060</span></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="testimoni bg-white section-md">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto">
            <div class="box-section-title text-center">
              <p class="section-subheading-xs mb-2">TESTIMONI</p>
              <h2 class="section-heading">What they say</h2>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="testimoni-slider-container">
              <div
                class="owl-carousel owl-theme testimoni-slider"
                v-if="loaded_testimoni"
              >
                <div
                  class="owl-item"
                  v-for="(row, index) in owl_testimoni"
                  :key="index"
                >
                  <div class="testimoni-content">
                    <div class="quotation-mark">
                      <img
                        data-src="assets/img/quotation-mark.png"
                        alt=""
                        class="img-fluid lazyload testimoni-img"
                      />
                    </div>
                    <img
                      :data-src="row.image"
                      alt=""
                      class="img-fluid lazyload testimoni-img order-md-last"
                    />
                    <div class="testimoni-text order-md-first">
                      <p class="section-description-sm text-black">
                        {{ row.teks }}
                      </p>
                      <p class="section-description font-weight-bold mb-1">
                        {{ row.name }}
                      </p>
                      <p class="section-description-sm">{{ row.business }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="testimoni-slider-dots"></div>
              <div class="nav-slider testimoni-slider-prev">
                <i class="fas fa-chevron-left ml-auto"></i>
              </div>
              <div class="nav-slider testimoni-slider-next">
                <i class="fas fa-chevron-right ml-auto"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="section-bottom-question">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 block-text section-md">
            <div class="box-section-title">
              <h2 class="section-heading">Have a question?</h2>
              <p class="section-description-sm">
                Apabila Anda memiliki pertanyaan seputar kendaraan Anda,
                silahkan hubungi kami melalui WhatsApp atau kunjungi dealer
                Nasmoco terdekat.
              </p>

              <div class="find-call">
                <router-link
                  :to="{ name: 'Find_dealer' }"
                  class="btn btn-padd btn-dark rounded"
                  ><span>FIND DEALER</span>
                  <i class="fa fa-caret-right ml-3"></i
                ></router-link>
                <a
                  href="https://wa.me/628981260060"
                  target="_blank"
                  class="btn btn-padd btn-whatsapp px-4"
                  ><i class="fab fa-whatsapp font-weight-400 mr-2"></i
                  >WHATSAPP</a
                >
                <!-- <a href="https://wa.me/628981260060" target="_blank" class="btn btn-padd btn-white rounded"><i class="fa fa-phone mr-2"></i> <span>0898 - 1260060</span></a> -->
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 block-thumbnails bg-image center"
            data-background="assets/img/bg-2.png"
          ></div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AfterSale",
  metaInfo() {
    return {
      title: "After Sale - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {
      // testimoni
      loaded_testimoni: false,
      owl_testimoni: [],
    };
  },
  created() {
    this.getAfterSale();
    window.scrollTo(0, 0);
  },
  mounted() {
    var vm = this;
    // attr data-background
    $("[data-background]").each(function () {
      $(this).css(
        "background-image",
        "url(" + $(this).attr("data-background") + ")"
      );
    });

    // reloading promotion
    this.getAfterSaleTestimoni()
      .then((res) => {
        vm.owl_testimoni = this.testimoni;
        if (vm.owl_testimoni.length > 0) {
          vm.loaded_testimoni = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselTestimoniSlider();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  },
  computed: {
    ...mapState("home", {
      after_sale: (state) => state.after_sale,
    }),
    ...mapState("after_sale", {
      testimoni: (state) => state.testimoni,
    }),
  },
  methods: {
    ...mapActions("home", ["getAfterSale"]),
    ...mapActions("after_sale", ["getAfterSaleTestimoni"]),
    findDreamCar() {
      this.$Progress.start();
      this.submitResult()
        .then((res) => {
          console.log(res);
          this.$router.push({ name: "Nasmoco_journey" });
          this.$Progress.finish();
        })
        .catch((err) => {
          this.$Progress.fail();
        });
    },

    // Owl Carousel Section Testimoni
    OwlCarouselTestimoniSlider: function () {
      if ($(".testimoni-slider").length) {
        var testimoniSlider = $(".testimoni-slider");

        testimoniSlider.owlCarousel({
          center: false,
          loop: false,
          autoplay: true,
          speed: 3000,
          smartSpeed: 2000,
          nav: false,
          autoplayHoverPause: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              center: true,
              loop: true,
              items: 1,
              margin: 110,
              stagePadding: 0,
              dots: true,
              dotsContainer: ".testimoni-slider-dots",
            },
            576: {
              center: true,
              loop: true,
              items: 1,
              margin: 110,
              stagePadding: 0,
              dots: true,
              dotsContainer: ".testimoni-slider-dots",
            },
            767: {
              center: true,
              loop: true,
              items: 1,
              margin: 110,
              stagePadding: 0,
              dots: true,
              dotsContainer: ".testimoni-slider-dots",
            },
            992: {
              items: 1,
              margin: 140,
              stagePadding: 0,
              dots: false,
            },
            1200: {
              items: 1,
              margin: 140,
              stagePadding: 0,
              dots: false,
            },
          },
        });

        if ($(".testimoni-slider-prev").length) {
          var prev = $(".testimoni-slider-prev");
          prev.on("click", function () {
            testimoniSlider.trigger("prev.owl.carousel");
          });
        }

        if ($(".testimoni-slider-next").length) {
          var next = $(".testimoni-slider-next");
          next.on("click", function () {
            testimoniSlider.trigger("next.owl.carousel");
          });
        }
      }
    },
    // End Owl Carousel Section
  },
};
</script>