import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //data dealer
    data_dealer: [],
    // form find dealer
    form_find_dealer: {
        kota_cabang: "",
        cabang_name: "",
    },
    loadMaps: "",
    // detail dealer 
    loading_slider: false,
    slider_by_id: [],
    loading_icon: false,
    icon_by_id: [],
    loading_maps: false,
    maps_by_id: "",
    loading_contact: false,
    contact_by_id: [],
    loading_nea: false,
    nea_by_id: [],
    loading_promo: false,
    promo_by_id: []
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.data_dealer = [],
            state.form_find_dealer = {
                kota_cabang: "",
                cabang_name: "",
            },
            state.loadMaps = "",
            // detail dealer 
            state.loading_slider = false,
            state.slider_by_id = [],
            state.loading_icon = false,
            state.icon_by_id = [],
            state.loading_maps = false,
            state.maps_by_id = "",
            state.loading_contact = false,
            state.contact_by_id = [],
            state.loading_nea = false,
            state.nea_by_id = [],
            state.loading_promo = false,
            state.promo_by_id = []
    },
    ASSIGN_DATA_DEALER(state, payload) {
        state.data_dealer = payload
    },
    SET_LOAD_MAPS(state, payload) {
        state.loadMaps = payload;
    },
    ASSIGN_DATA_SLIDER(state, payload) {
        state.slider_by_id = payload
    },
    ASSIGN_DATA_ICON(state, payload) {
        state.icon_by_id = payload
    },
    ASSIGN_DATA_MAPS(state, payload) {
        state.maps_by_id = payload
    },
    ASSIGN_DATA_CONTACT(state, payload) {
        state.contact_by_id = payload
    },
    ASSIGN_DATA_NEA(state, payload) {
        state.nea_by_id = payload
    },
    ASSIGN_DATA_PROMO(state, payload) {
        state.promo_by_id = payload
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    getDealer({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true
            $axios.post(`cabang`, {
                city_code: payload.city_code
            })
                .then((response) => {
                    commit('ASSIGN_DATA_DEALER', response.data.data)
                    state.loadMaps = response.data.data[0].maps
                    state.loading = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getSliderByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_slider = true
            $axios.post(`dealer/slider`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_SLIDER', response.data.data)
                    state.loading_slider = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getIconByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_icon = true
            $axios.post(`dealer/icon`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_ICON', response.data.data)
                    state.loading_icon = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getMapsByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_maps = true
            $axios.post(`dealer/maps`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_MAPS', response.data.data)
                    state.loading_maps = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getContactByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_contact = true
            $axios.post(`dealer/contact`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CONTACT', response.data.data)
                    state.loading_contact = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getNeaByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_nea = true
            $axios.post(`dealer/nea`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_NEA', response.data.data)
                    state.loading_nea = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getPromoByID({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading_promo = true
            $axios.post(`dealer/promo`, {
                seo: payload
            })
                .then((response) => {
                    commit('ASSIGN_DATA_PROMO', response.data.data)
                    state.loading_promo = false
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
