import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get brochure
    brochure: [],
    message: "",
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.brochure = [],
            state.message = ""
    },
    ASSIGN_DATA_BROCHURE(state, payload) {
        state.brochure = payload;
    },
    ASSIGN_DATA_MESSAGE(state, payload) {
        state.message = payload;
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    // get Item Slider
    getBrochure({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.get(`brochure`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_BROCHURE', response.data.data.category)
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },
    getDownloadBrochure({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.post(`brochure/download`, {
                brochure_id: payload.brochure_id,
                downloaded: payload.downloaded
            })
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_MESSAGE', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
