<template>
  <div>
    <section class="section-lg dashboard-member">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="dash-sidebar">
              <ul class="dash-menu nav nav-tabs" id="nav-tab" role="tablist">
                <li class="dash-menu-heading">
                  <h6 class="section-description-sm">MENU</h6>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toBeranda()"
                    class="dash-menu-link nav-link active"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-1"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.49951 10.8329H3.33284V12.4996V16.6662C3.33284 17.5854 4.08034 18.3329 4.99951 18.3329H14.9995C15.9187 18.3329 16.6662 17.5854 16.6662 16.6662V12.4996V10.8329H17.4995C17.8362 10.8329 18.1412 10.6296 18.2695 10.3187C18.3987 10.0071 18.327 9.64872 18.0887 9.41039L10.5887 1.91039C10.2628 1.58456 9.73617 1.58456 9.41034 1.91039L1.91034 9.41039C1.67201 9.64872 1.60034 10.0071 1.72951 10.3187C1.85867 10.6296 2.16284 10.8329 2.49951 10.8329V10.8329ZM9.99951 3.67789L14.9995 8.67789V12.4996V12.4996L15.0003 16.6662H4.99951V12.4996V9.99955V8.67872L9.99951 3.67789V3.67789Z"
                        fill="#9B9B9B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.99935 14.1667C11.8377 14.1667 13.3327 12.6717 13.3327 10.8333C13.3327 8.995 11.8377 7.5 9.99935 7.5C8.16102 7.5 6.66602 8.995 6.66602 10.8333C6.66602 12.6717 8.16102 14.1667 9.99935 14.1667V14.1667ZM9.99935 9.16667C10.9185 9.16667 11.666 9.91417 11.666 10.8333C11.666 11.7525 10.9185 12.5 9.99935 12.5C9.08018 12.5 8.33268 11.7525 8.33268 10.8333C8.33268 9.91417 9.08018 9.16667 9.99935 9.16667V9.16667Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Beranda
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toProfile()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-2"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.3333 2C5.81583 2 2 5.81583 2 10.3333C2 12.9908 3.32667 15.3983 5.33333 16.9367V17H5.41833C6.80833 18.0375 8.51083 18.6667 10.3333 18.6667C12.1558 18.6667 13.8583 18.0375 15.2483 17H15.3333V16.9367C17.34 15.3983 18.6667 12.9917 18.6667 10.3333C18.6667 5.81583 14.8508 2 10.3333 2V2ZM7.06167 16.1058C7.30917 14.985 8.30667 14.1442 9.5 14.1442H11.1667C12.36 14.1442 13.3575 14.9858 13.605 16.1058C12.6317 16.67 11.5133 17 10.3333 17C9.15333 17 8.035 16.67 7.06167 16.1058V16.1058ZM15.0083 15.0342C14.3775 13.5342 12.8933 12.4775 11.1667 12.4775H9.5C7.77333 12.4775 6.28833 13.535 5.65833 15.0342C4.43583 13.8183 3.66667 12.1467 3.66667 10.3333C3.66667 6.71917 6.71917 3.66667 10.3333 3.66667C13.9475 3.66667 17 6.71917 17 10.3333C17 12.1467 16.2308 13.8183 15.0083 15.0342V15.0342Z"
                        fill="#9B9B9B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.3333 7C8.43333 7 7 8.43333 7 10.3333C7 12.2333 8.43333 13.6667 10.3333 13.6667C12.2333 13.6667 13.6667 12.2333 13.6667 10.3333C13.6667 8.43333 12.2333 7 10.3333 7V7ZM10.3333 12C9.35167 12 8.66667 11.315 8.66667 10.3333C8.66667 9.35167 9.35167 8.66667 10.3333 8.66667C11.315 8.66667 12 9.35167 12 10.3333C12 11.315 11.315 12 10.3333 12V12Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Profile
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toBookingOrder()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-3"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.4993 9.16667H14.9993V3.33333C14.9993 2.8725 14.6268 2.5 14.166 2.5H2.49935C2.03852 2.5 1.66602 2.8725 1.66602 3.33333V15C1.66602 16.3783 2.78768 17.5 4.16602 17.5H15.8327C17.211 17.5 18.3327 16.3783 18.3327 15V10C18.3327 9.53917 17.9602 9.16667 17.4993 9.16667ZM4.16602 15.8333C3.70602 15.8333 3.33268 15.46 3.33268 15V4.16667H13.3327V15C13.3327 15.2925 13.3835 15.5733 13.4752 15.8333H4.16602ZM16.666 15C16.666 15.46 16.2927 15.8333 15.8327 15.8333C15.3727 15.8333 14.9993 15.46 14.9993 15V10.8333H16.666V15Z"
                        fill="#9B9B9B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 5.83301H11.6667V7.49967H5V5.83301ZM5 9.16634H11.6667V10.833H5V9.16634ZM9.16667 12.4997H11.6667V14.1663H9.16667V12.4997Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Buy a Car
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toBookingService()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-4"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.16667 18.3337H15.8333C16.7525 18.3337 17.5 17.5862 17.5 16.667V4.16699C17.5 3.24783 16.7525 2.50033 15.8333 2.50033H14.1667C14.1667 2.03949 13.7942 1.66699 13.3333 1.66699H6.66667C6.20583 1.66699 5.83333 2.03949 5.83333 2.50033H4.16667C3.2475 2.50033 2.5 3.24783 2.5 4.16699V16.667C2.5 17.5862 3.2475 18.3337 4.16667 18.3337ZM4.16667 4.16699H5.83333V5.83366H14.1667V4.16699H15.8333V16.667H4.16667V4.16699Z"
                        fill="#9B9B9B"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.16664 11.322L7.67247 9.8278L6.49414 11.0061L9.16664 13.6786L13.5058 9.33947L12.3275 8.16113L9.16664 11.322Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Booking Service
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toTestDrive()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-5"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0004 11.7772C10.9823 11.7772 11.7782 10.9813 11.7782 9.99946C11.7782 9.01762 10.9823 8.22168 10.0004 8.22168C9.01859 8.22168 8.22266 9.01762 8.22266 9.99946C8.22266 10.9813 9.01859 11.7772 10.0004 11.7772Z"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 11.7783V18.0005"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.22266 9.99946L2.22266 8.22168"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.7773 9.99946L17.7773 8.22168"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Test Drive
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toTradeIn()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-6"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0004 11.7772C10.9823 11.7772 11.7782 10.9813 11.7782 9.99946C11.7782 9.01762 10.9823 8.22168 10.0004 8.22168C9.01859 8.22168 8.22266 9.01762 8.22266 9.99946C8.22266 10.9813 9.01859 11.7772 10.0004 11.7772Z"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 11.7783V18.0005"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.22266 9.99946L2.22266 8.22168"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.7773 9.99946L17.7773 8.22168"
                        stroke="#9B9B9B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Trade In
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toKonfirmasi()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-7"
                    aria-selected="true"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM17 14H3V6H17V14ZM10 7C8.34 7 7 8.34 7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7ZM23 7V18C23 19.1 22.1 20 21 20H4V18H21V7H23Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Konfirmasi Pembayaran
                  </a>
                </li>
                <li class="dash-menu-item nav-item">
                  <a
                    @click="toInbox()"
                    class="dash-menu-link nav-link"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="menu-8"
                    aria-selected="true"
                  >
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.0667 11.6308H13.5333C14.3052 11.6308 14.9333 10.9785 14.9333 10.1769V1.45385C14.9333 0.652292 14.3052 0 13.5333 0H1.4C0.628133 0 0 0.652292 0 1.45385V15.5077L4.97747 11.6308H13.0667ZM4.35587 9.69231L1.86667 11.6308V1.93846H13.0667V9.69231H4.35587Z"
                        fill="#9B9B9B"
                      />
                      <path
                        d="M17.6003 5.81543H17.1336V11.6318C17.1336 12.6979 16.3001 13.5635 15.2763 13.5693H5.93359V14.0539C5.93359 14.8554 6.56173 15.5077 7.33359 15.5077H14.0228L19.0003 19.3847V7.26928C19.0003 6.46772 18.3721 5.81543 17.6003 5.81543Z"
                        fill="#9B9B9B"
                      />
                    </svg>
                    Inbox
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-9">
            <div class="tab-content" id="nav-tabContent">
              <div class="row" v-if="tabContent == 1">
                <div class="col-lg-12">
                  <div class="wrap-content">
                    <h3 class="section-heading-xs">Account:</h3>

                    <div class="block-user">
                      <img
                        :src="profile.image"
                        class="img-fluid lazyload img-user"
                      />
                      <ul class="list-info flex">
                        <li>
                          <label>Name:</label><br />
                          <span>{{ isSharedPreference.full_name }}</span>
                        </li>
                        <li>
                          <label>E-mail:</label><br />
                          <span>{{ isSharedPreference.member_email }}</span>
                        </li>
                        <li>
                          <label>Phone:</label><br />
                          <span>{{ isSharedPreference.phone }}</span>
                        </li>
                        <li>
                          <label>Provinsi:</label><br />
                          <span>{{ isSharedPreference.province_name }}</span>
                        </li>
                        <li>
                          <label>Address:</label><br />
                          <span>{{ isSharedPreference.address }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="wrap-content">
                        <h3 class="section-heading-xs">Booking Service:</h3>

                        <ul class="list-info">
                          <li>
                            <label class="mb-1">Upcoming Reservations:</label
                            ><br />
                            <span>{{ profile.past_bookingservice }}</span>
                          </li>
                          <li>
                            <label class="mb-1">Past Booking Service:</label
                            ><br />
                            <span>{{
                              profile.next_bookingservice | myDate
                            }}</span>
                          </li>
                        </ul>

                        <router-link
                          :to="{ name: 'Booking_service' }"
                          class="btn btn-padd btn-accent"
                          >Booking Service <i class="fa fa-arrow-right"></i
                        ></router-link>
                      </div>
                    </div>

                    <div class="col-lg-6 border-left">
                      <div class="wrap-content">
                        <h3 class="section-heading-xs">Test Drive</h3>

                        <ul class="list-info">
                          <li>
                            <label class="mb-1">Upcoming Reservations:</label
                            ><br />
                            <span>{{ profile.past_testdrive }}</span>
                          </li>
                          <li>
                            <label class="mb-1">Past Booking Service:</label
                            ><br />
                            <span>{{ profile.next_testdrive | myDate }}</span>
                          </li>
                        </ul>

                        <router-link
                          :to="{ name: 'Test_drive' }"
                          class="btn btn-padd btn-accent"
                          >Test Drive <i class="fa fa-arrow-right"></i
                        ></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="tabContent == 2">
                <div class="col-lg-12">
                  <div class="wrap-content">
                    <h3 class="section-heading-xs">Profile:</h3>

                    <div class="row">
                      <div class="col-lg-6">
                        <img
                          :src="profile.image"
                          class="img-fluid lazyload img-user mb-3"
                        />
                      </div>
                      <div class="col-lg-6 text-center text-lg-right">
                        <a
                          @click.prevent="ModalEditProfile()"
                          data-dismiss="modal"
                          data-toggle="modal"
                          class="btn btn-sm btn-yellow font-weight-600"
                          >Edit Profile</a
                        >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <ul class="list-info flex">
                          <li>
                            <label>Name:</label>
                            <span>{{ profile.full_name }}</span>
                          </li>
                          <li>
                            <label>E-mail:</label>
                            <span>{{ profile.member_email }}</span>
                          </li>
                          <li>
                            <label>Phone Number:</label>
                            <span>{{ profile.phone }}</span>
                          </li>
                          <li>
                            <label>Tanggal Lahir:</label>
                            <span>{{ profile.birth | myDate }}</span>
                          </li>
                          <li>
                            <label>Jenis Kelamin:</label>
                            <span>{{ profile.gender }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-6">
                        <ul class="list-info flex">
                          <li>
                            <label>Provinsi:</label>
                            <span>{{ profile.province_name }}</span>
                          </li>
                          <li>
                            <label>Kabupaten/Kota:</label>
                            <span>{{ profile.city_name }}</span>
                          </li>
                          <li>
                            <label>Address:</label>
                            <span>{{ profile.address }}</span>
                          </li>
                          <li>
                            <label>No. Rangka:</label>
                            <span>{{ profile.no_rangka }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="tabContent == 3">
                <div class="col-lg-12">
                  <div class="card card-style">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="section-description-md font-weight-bold">
                          Order History
                        </p>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-style">
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </th>
                              <th scope="col">Order ID</th>
                              <th scope="col">Car Model</th>
                              <th scope="col">Car Category</th>
                              <th scope="col">Date</th>
                              <th scope="col">Total</th>
                              <th scope="col">Payment Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-if="history_booking_order == 'no data recorded'"
                              class="no-data"
                            >
                              <td colspan="3">No data</td>
                            </tr>
                            <tr
                              v-else
                              v-for="(row, index) in history_booking_order"
                              :key="index"
                            >
                              <td>
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </td>
                              <th>{{ row.booking_id }}</th>
                              <td>{{ row.car_model }}</td>
                              <td>{{ row.car_category }}</td>
                              <td>{{ row.booking_date | myDate }}</td>
                              <td>{{ row.booking_fee | currency }}</td>
                              <td class="text-center">
                                <span
                                  class="badge badge-dot badge-success"
                                  v-if="row.status == 'Paid'"
                                  >Paid</span
                                >
                                <span
                                  class="badge badge-dot badge-danger"
                                  v-else
                                  >Unpaid</span
                                >
                              </td>
                              <td class="text-center">
                                <b-dropdown variant="warning" no-caret>
                                  <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                  </template>
                                  <b-dropdown-item>
                                    <router-link
                                      :to="{
                                        name: 'Detail_booking_order',
                                        params: {
                                          id: row.id,
                                        },
                                      }"
                                    >
                                      <i class="fas fa-cash-register red"></i>
                                      Informasi
                                    </router-link></b-dropdown-item
                                  >
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="tabContent == 4">
                <div class="col-lg-12">
                  <div class="card card-style">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="section-description-md font-weight-bold">
                          Booking Service History
                        </p>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-style">
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </th>
                              <th scope="col">Booking ID</th>
                              <th scope="col">Date</th>
                              <th scope="col">Cabang</th>
                              <th scope="col">Tipe Mobil</th>
                              <th scope="col">Service</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-if="
                                history_booking_service == 'no data recorded'
                              "
                              class="no-data"
                            >
                              <td colspan="3">No data</td>
                            </tr>
                            <tr
                              v-else
                              v-for="(row, index) in history_booking_service"
                              :key="index"
                            >
                              <td>
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </td>
                              <th>{{ row.booking_id }}</th>
                              <td>{{ row.booking_date | myDate }}</td>
                              <td>{{ row.branch_name }}</td>
                              <td>{{ row.car_model_type }}</td>
                              <td>{{ row.jenis_service }}</td>
                              <td class="text-center">
                                <b-dropdown variant="warning" no-caret>
                                  <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                  </template>
                                  <b-dropdown-item>
                                    <router-link
                                      :to="{
                                        name: 'Detail_booking_service',
                                        params: {
                                          id: row.id,
                                        },
                                      }"
                                    >
                                      <i class="fas fa-cash-register red"></i>
                                      Informasi
                                    </router-link></b-dropdown-item
                                  >
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="tabContent == 5">
                <div class="col-lg-12">
                  <div class="card card-style">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="section-description-md font-weight-bold">
                          Test Drive History
                        </p>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-style">
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </th>
                              <th scope="col">Booking ID</th>
                              <th scope="col">Date</th>
                              <th scope="col">Cabang</th>
                              <th scope="col">Tipe Mobil</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-if="history_test_drive == 'no data recorded'"
                              class="no-data"
                            >
                              <td colspan="3">No data</td>
                            </tr>
                            <tr
                              v-else
                              v-for="(row, index) in history_test_drive"
                              :key="index"
                            >
                              <td>
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </td>
                              <th>{{ row.booking_id }}</th>
                              <td>{{ row.booking_date | myDate }}</td>
                              <td>{{ row.branch_name }}</td>
                              <td>{{ row.car_type }}</td>
                              <td class="text-center">
                                <b-dropdown variant="warning" no-caret>
                                  <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                  </template>
                                  <b-dropdown-item>
                                    <router-link
                                      :to="{
                                        name: 'Detail_test_drive',
                                        params: {
                                          id: row.id,
                                        },
                                      }"
                                    >
                                      <i class="fas fa-cash-register red"></i>
                                      Informasi
                                    </router-link></b-dropdown-item
                                  >
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="tabContent == 6">
                <div class="col-lg-12">
                  <div class="card card-style">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="section-description-md font-weight-bold">
                          Trade In History
                        </p>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-style">
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </th>
                              <th scope="col">Trade ID</th>
                              <th scope="col">Date</th>
                              <th scope="col">Cabang</th>
                              <th scope="col">Mobil Anda</th>
                              <th scope="col">Mobil Impian</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-if="history_trade_in == 'no data recorded'"
                              class="no-data"
                            >
                              <td colspan="4">No data</td>
                            </tr>
                            <tr
                              v-else
                              v-for="(row, index) in history_trade_in"
                              :key="index"
                            >
                              <td>
                                <label class="custom-checkbox"
                                  ><input type="checkbox"
                                /></label>
                              </td>
                              <th>{{ row.booking_id }}</th>
                              <td>{{ row.booking_date | myDate }}</td>
                              <td>{{ row.branch_name }}</td>
                              <td>{{ row.mobil_anda }}</td>
                              <td>{{ row.mobil_impian }}</td>
                              <td class="text-center">
                                <b-dropdown variant="warning" no-caret>
                                  <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                  </template>
                                  <b-dropdown-item>
                                    <router-link
                                      :to="{
                                        name: 'Detail_trade_in',
                                        params: {
                                          id: row.id,
                                        },
                                      }"
                                    >
                                      <i class="fas fa-cash-register red"></i>
                                      Informasi
                                    </router-link></b-dropdown-item
                                  >
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="wrap-content" v-if="tabContent == 7">
                <h3 class="section-heading-xs">Konfirmasi Pembayaran</h3>

                <form
                  method="post"
                  action="javascript:coid(0)"
                  class="form-style"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">No Order</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="order_id"
                          label="order_id"
                          placeholder="Pilih Order Id"
                          @input="setValOrderId"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Order Id tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.order_id
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Bank Tujuan</label>
                        <input
                          disabled
                          v-model="form_confirm_payment.bank_tujuan"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Input nomor rekening Anda"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Bank Anda</label>
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="bank"
                          label="name"
                          placeholder="Pilih Bank Anda"
                          @input="setValBankAnda"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Bank tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.name
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Nomor Rekening</label>
                        <input
                          @keypress="isNumber($event)"
                          v-model="form_confirm_payment.nomor_rekening_anda"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Input nomor rekening Anda"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Atas Nama Rekening</label>
                        <input
                          v-model="form_confirm_payment.nama_rekening_anda"
                          type="text"
                          name=""
                          class="form-control"
                          placeholder="Input nama pemilik rekening"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Metode Transfer</label>
                        <v-select
                          class="mb-1"
                          label="label"
                          placeholder="Pilih Metode Transfer"
                          :options="TipePayment"
                          :clearable="false"
                          @input="SetValMetodeTransfer"
                        >
                          <template slot="no-options"
                            >Metode Tidak Ditemukan</template
                          >
                        </v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Nominal</label>
                        <money
                          class="form-control text-right"
                          v-model="form_confirm_payment.nominal"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label">Tanggal Transfer</label>
                        <date-picker
                          :class="{
                            'form-control': 'form-control',
                          }"
                          v-model="form_confirm_payment.tanggal"
                          :config="options"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="control-label"
                          >Upload Bukti Transfer</label
                        >
                        <div class="custome-input-file">
                          <div class="wrap-input">
                            <input
                              accept="image/jpg, image/jpeg, image/png"
                              type="file"
                              name=""
                              class="btn"
                              @change="uploadBuktiTransfer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="text-right">
                    <button
                      :disabled="$v.form_confirm_payment.$invalid"
                      v-if="!isSave"
                      type="submit"
                      id="submit"
                      class="btn btn-padd btn-width text-center btn-yellow mt-4"
                      @click.prevent="submitKonfirmasiPembayaran"
                    >
                      <span>SUBMIT</span>
                    </button>
                    <button
                      v-if="isSave"
                      :disabled="isSave"
                      type="submit"
                      id="submit"
                      class="btn btn-padd btn-width text-center btn-yellow mt-4"
                    >
                      <span class="spinner-border spinner-border-sm"></span>
                    </button>
                  </div>
                </form>
              </div>

              <div class="row" v-if="tabContent == 8">
                <div class="col-lg-12">
                  <div class="wrap-content">
                    <h3 class="section-heading-xs mb-3">Inbox:</h3>

                    <div class="card card-style shadow-sm mb-2">
                      <div
                        class="card-body"
                        v-for="(row_inbox, index_inbox) in inbox"
                        :key="index_inbox"
                      >
                        <p class="section-description-xs mb-2">
                          {{ row_inbox.tanggal | myDate }}
                        </p>
                        <h4
                          class="section-description-md font-weight-bold mb-1"
                        >
                          {{ row_inbox.judul }}
                        </h4>
                        <p
                          class="section-description-sm mb-0"
                          v-html="row_inbox.text"
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div
      class="modal fade modal-style"
      id="edit-profile-modal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
        style="max-width: 850px"
      >
        <div class="modal-content bg-none">
          <div class="modal-body body-modal-style">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>

            <div class="d-md-flex justify-content-between">
              <h3 class="section-heading-xs">Edit Profile</h3>
            </div>

            <form class="form-style mb-4">
              <div class="row">
                <div class="col-lg-6">
                  <img
                    v-if="profile.image == ''"
                    src="assets/img/img-user.png"
                    class="img-fluid lazyload img-user mb-3"
                  />
                  <img
                    v-else
                    :src="getPhotoProfile()"
                    class="img-fluid lazyload img-user mb-3"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-2">
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      placeholder="Your name *"
                      v-model.trim="$v.form_edit_profile.name.$model"
                      :class="{
                        'is-invalid': $v.form_edit_profile.name.$error,
                        'is-valid':
                          !$v.form_edit_profile.name.$error &&
                          form_edit_profile.name != '',
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.name.required"
                    >
                      *Masukkan Nama Lengkap!.
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <input
                      disabled
                      type="text"
                      name=""
                      class="form-control"
                      placeholder="E-mail *"
                      v-model.trim="$v.form_edit_profile.email.$model"
                      :class="{
                        'is-invalid': $v.form_edit_profile.email.$error,
                        'is-valid':
                          !$v.form_edit_profile.email.$error &&
                          form_edit_profile.email != '',
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.email.email"
                    >
                      *Email tidak valid!.
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.email.required"
                    >
                      *Masukkan Email!.
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <input
                      type="text"
                      name=""
                      class="form-control"
                      placeholder="Phone Number"
                      v-model.trim="$v.form_edit_profile.phone.$model"
                      :class="{
                        'is-invalid': $v.form_edit_profile.phone.$error,
                        'is-valid':
                          !$v.form_edit_profile.phone.$error &&
                          form_edit_profile.phone != '',
                      }"
                      @keypress="isNumber($event)"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.phone.required"
                    >
                      *Masukkan Nomor Telepon!.
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.phone.minLength"
                    >
                      *No. Telp minimal 10 Digit!.
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <date-picker
                      :class="{
                        'form-control': 'form-control',
                      }"
                      v-model="form_edit_profile.birth"
                      :config="options"
                    ></date-picker>
                  </div>

                  <div class="form-group">
                    <label class="control-label text-2">Jenis Kelamin</label>
                    <div class="d-flex">
                      <b-form-radio-group
                        button-variant="danger"
                        v-model.trim="$v.form_edit_profile.gender.$model"
                        :options="gender_option"
                        name="radio-inline"
                      ></b-form-radio-group>
                      <div
                        class="invalid-feedback"
                        v-if="!$v.form_edit_profile.gender.required"
                      >
                        *Pilih Gender!.
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <input
                      v-model.trim="$v.form_edit_profile.password.$model"
                      :class="{
                        'is-invalid': $v.form_edit_profile.password.$error,
                        'is-valid':
                          !$v.form_edit_profile.password.$error &&
                          form_edit_profile.password != '',
                      }"
                      type="password"
                      name=""
                      class="form-control"
                      placeholder="Password *"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.password.required"
                    >
                      *Masukkan Password!.
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.password.minLength"
                    >
                      *Password Minimal 8 karakter!.
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <input
                      v-model.trim="
                        $v.form_edit_profile.confirm_password.$model
                      "
                      :class="{
                        'is-invalid':
                          $v.form_edit_profile.confirm_password.$error,
                        'is-valid':
                          !$v.form_edit_profile.confirm_password.$error &&
                          form_edit_profile.confirm_password != '',
                      }"
                      type="password"
                      name=""
                      class="form-control"
                      placeholder="Confirm Password *"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="
                        !$v.form_edit_profile.confirm_password.sameAsPassword
                      "
                    >
                      *Password Belum sama!.
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="province"
                          label="provinsi"
                          placeholder="Pilih Provinsi"
                          @input="setValIdProvince"
                          :value="form_edit_profile.province_name"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Provinsi tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.provinsi
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <v-select
                          class="vselect-custome"
                          style="width: 100%"
                          :options="kota"
                          label="nama"
                          placeholder="Pilih Kota"
                          @input="setValIdKota"
                          :value="form_edit_profile.city_name"
                          :clearable="false"
                        >
                          <template slot="no-options"
                            >Kota tidak ditemukan</template
                          >
                          <template slot="option" slot-scope="option">{{
                            option.nama
                          }}</template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <textarea
                      name=""
                      rows="4"
                      class="form-control"
                      placeholder="Address"
                      v-model.trim="$v.form_edit_profile.address.$model"
                      :class="{
                        'is-invalid': $v.form_edit_profile.address.$error,
                        'is-valid':
                          !$v.form_edit_profile.address.$error &&
                          form_edit_profile.address != '',
                      }"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.form_edit_profile.address.required"
                    >
                      *Masukkan Alamat!.
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="form_edit_profile.no_rangka"
                        type="text"
                        name=""
                        class="form-control"
                        placeholder="Input Nomor Rangka"
                      />
                      <div class="input-group-append">
                        <button
                          :disabled="form_edit_profile.no_rangka == ''"
                          v-if="!isCekRangka"
                          type="submit"
                          class="btn btn-sm btn-outline-dark"
                          @click.prevent="cekStatusNorangka"
                        >
                          <i class="fa fa-search"></i> CEK NO. RANGKA
                        </button>
                        <button
                          v-if="isCekRangka"
                          type="submit"
                          class="btn btn-sm btn-outline-dark"
                        >
                          <span class="spinner-border spinner-border-sm"></span>
                        </button>
                      </div>
                    </div>
                    <b-alert
                      class="mt-2"
                      v-if="message_no_rangka.status_tidak_valid == true"
                      v-model="showDismissibleAlertRangka"
                      variant="danger"
                      >{{ message_no_rangka.message }}</b-alert
                    >
                    <b-alert
                      class="mt-2"
                      v-if="message_no_rangka.status_valid == true"
                      v-model="showDismissibleAlertRangka"
                      variant="success"
                      >{{ message_no_rangka.message }}</b-alert
                    >
                  </div>

                  <div class="form-group">
                    <label class="control-label">Upload Image Profile</label>
                    <div class="custome-input-file">
                      <div class="wrap-input">
                        <input
                          accept="image/jpg, image/jpeg, image/png"
                          type="file"
                          name=""
                          class="btn"
                          @change="updatePhotoProfile"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        v-if="!isSave"
                        @click.prevent="submitUpdateProfile"
                        type="submit"
                        class="btn btn-accent btn-block font-weight-600 py-2 mt-2"
                      >
                        Simpan Perubahan <i class="fa fa-save ml-2"></i>
                      </button>
                      <button
                        v-if="isSave"
                        :disabled="isSave"
                        type="submit"
                        class="btn btn-accent btn-block font-weight-600 py-2 mt-2"
                      >
                        <span class="spinner-border spinner-border-sm"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
export default {
  name: "Dashboard_member",
  metaInfo() {
    return {
      title: "Member - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: true,
      },
      TipePayment: [
        {
          label: "Atm Transfer",
          value: "Atm Transfer",
        },
        {
          label: "Internet Banking",
          value: "Internet Banking",
        },
        {
          label: "Mobile Banking",
          value: "Mobile Banking",
        },
      ],
      gender_option: [
        { text: "Laki-laki", value: "Laki-laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      isCekRangka: false,
      showDismissibleAlertRangka: false,
      message_no_rangka: {
        status_valid: false,
        status_tidak_valid: false,
        message: "",
      },
    };
  },
  validations: {
    form_confirm_payment: {
      token: { required },
      email: { required },
      no_order: { required },
      bank_anda: { required },
      bank_tujuan: { required },
      nama_rekening_anda: { required },
      nomor_rekening_anda: { required },
      methode_transfer: { required },
      nominal: { required },
      tanggal: { required },
      bukti_transfer: { required },
    },
    form_edit_profile: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        sameAsPassword: sameAs("password"),
      },
      birth: {
        required,
      },
      phone: {
        required,
        minLength: minLength(10),
      },
      address: {
        required,
      },
      gender: {
        required,
      },
      province: {
        required,
      },
      city: {
        required,
      },
    },
  },
  created() {
    this.getProvince();
    this.form_confirm_payment.token = this.isSharedPreference.token;
    this.form_confirm_payment.email = this.isSharedPreference.member_email;

    if (this.isAuth) {
      this.form_confirm_payment.token = this.isSharedPreference.token;
      this.form_confirm_payment.email = this.isSharedPreference.member_email;
    }
    this.getProfile({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
      unique_id: this.isSharedPreference.unique_id,
    });
    this.getHistoryBookingService({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
      unique_id: this.isSharedPreference.unique_id,
    });
    this.getHistoryTradeIn({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
      unique_id: this.isSharedPreference.unique_id,
    });
    this.getHistoryTestDrive({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
      unique_id: this.isSharedPreference.unique_id,
    });
    this.getHistoryBookingOrder({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
      unique_id: this.isSharedPreference.unique_id,
    });
    this.getInbox({
      token: this.isSharedPreference.token,
      member_email: this.isSharedPreference.member_email,
    });
    this.getOrderId(this.isSharedPreference.unique_id);
    this.getBank();
    Fire.$on("AfterCreated", () => {
      this.getProfile({
        token: this.isSharedPreference.token,
        member_email: this.isSharedPreference.member_email,
        unique_id: this.isSharedPreference.unique_id,
      });
    });
    window.scrollTo(0, 0);
  },
  watch: {
    form_edit_profile: {
      handler(newValue) {
        if (newValue.province != "") {
          this.getKota({
            province_code: newValue.province,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    $(".toggle-password").on("click", function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      let input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  },
  methods: {
    ...mapMutations(["CLEAR_ERRORS"]),
    ...mapActions("regencies", ["getProvince", "getKota"]),
    ...mapActions("member", [
      "getHistoryBookingService",
      "getHistoryTradeIn",
      "getHistoryTestDrive",
      "getHistoryBookingOrder",
      "SendConfirmPayment",
      "getOrderId",
      "getBank",
      "getInbox",
      "getProfile",
      "UpdateProfile",
      "Clear_State",
    ]),
    ...mapActions("auth", ["cekNoRangka"]),
    cekStatusNorangka() {
      this.isCekRangka = true;
      this.cekNoRangka({
        no_rangka: this.form_edit_profile.no_rangka,
      })
        .then((res) => {
          this.CLEAR_ERRORS();
          if (this.message_rangka == "valid") {
            this.form_edit_profile.no_rangka_status = "valid";
            this.message_no_rangka.status_valid = true;
            this.message_no_rangka.status_tidak_valid = false;
            this.message_no_rangka.message = "Anda Adalah Member Lama Nasmoco";
            this.showDismissibleAlertRangka = true;
          } else if (this.message_rangka == "tidak valid") {
            this.form_edit_profile.no_rangka_status = "not valid";
            this.message_no_rangka.status_valid = false;
            this.message_no_rangka.status_tidak_valid = true;
            this.message_no_rangka.message = "Anda Adalah Member Baru Nasmoco";
            this.showDismissibleAlertRangka = true;
          }
          this.isCekRangka = false;
        })
        .catch((error) => {
          this.isCekRangka = false;
        });
    },

    ModalEditProfile() {
      this.form_edit_profile.name = this.profile.full_name;
      this.form_edit_profile.email = this.profile.member_email;
      this.form_edit_profile.phone = this.profile.phone;
      this.form_edit_profile.birth = this.profile.birth;
      this.form_edit_profile.gender = this.profile.gender;
      this.form_edit_profile.province = this.profile.province_id;
      this.form_edit_profile.province_name = this.profile.province_name;
      this.form_edit_profile.city = this.profile.city_id;
      this.form_edit_profile.city_name = this.profile.city_name;
      this.form_edit_profile.address = this.profile.address;
      this.form_edit_profile.token = this.isSharedPreference.token;
      this.form_edit_profile.no_rangka = this.profile.no_rangka;
      $("#edit-profile-modal").modal("show");
    },
    toBeranda() {
      this.tabContent = 1;
    },
    toProfile() {
      this.tabContent = 2;
    },
    toBookingOrder() {
      this.tabContent = 3;
    },
    toBookingService() {
      this.tabContent = 4;
    },
    toTestDrive() {
      this.tabContent = 5;
    },
    toTradeIn() {
      this.tabContent = 6;
    },
    toKonfirmasi() {
      this.tabContent = 7;
    },
    toInbox() {
      this.tabContent = 8;
    },
    setValOrderId(val) {
      this.form_confirm_payment.no_order = val.order_id;
    },
    setValBankAnda(val) {
      this.form_confirm_payment.bank_anda = val.name;
    },
    SetValMetodeTransfer(val) {
      this.form_confirm_payment.methode_transfer = val.value;
    },
    setValIdProvince(val) {
      this.form_edit_profile.province = val.code;
      this.form_edit_profile.province_name = val.provinsi;
    },
    setValIdKota(val) {
      this.form_edit_profile.city = val.kode;
      this.form_edit_profile.city_name = val.nama;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    uploadBuktiTransfer(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      let limit = 1024 * 1024 * 2;
      if (file["size"] > limit) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File terlalu besar pilih yang lain",
        });
        return false;
      }
      reader.onloadend = (file) => {
        this.form_confirm_payment.bukti_transfer = reader.result;
      };
      reader.readAsDataURL(file);
    },
    getPhotoProfile() {
      if (this.form_edit_profile.image != "") {
        let photo = this.form_edit_profile.image;
        return photo;
      } else {
        let photo = this.profile.image;
        return photo;
      }
    },
    updatePhotoProfile(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      let limit = 1024 * 1024 * 2;
      if (file["size"] > limit) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File terlalu besar pilih yang lain",
        });
        return false;
      }
      reader.onloadend = (file) => {
        this.form_edit_profile.image = reader.result;
      };
      reader.readAsDataURL(file);
    },
    submitKonfirmasiPembayaran() {
      this.$Progress.start();
      this.SendConfirmPayment()
        .then((res) => {
          this.CLEAR_ERRORS();
          swal.fire({
            title: "Konfirmasi Pembayaran berhasil dikirim",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.tabContent = 3;
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },
    submitUpdateProfile() {
      this.$Progress.start();
      this.UpdateProfile()
        .then((res) => {
          this.CLEAR_ERRORS();
          Fire.$emit("AfterCreated");
          $("#edit-profile-modal").modal("hide");
          swal.fire({
            title: "Profile berhasil dirubah",
            icon: "success",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          swal.fire({
            text: this.errors,
            icon: "error",
            confirmButtonColor: "#27ae60",
            confirmButtonText: "OK!",
          });
          this.$Progress.fail();
        });
    },
  },
  computed: {
    ...mapGetters(["isAuth", "isToken", "isSharedPreference"]),
    ...mapState(["isSave"]),
    ...mapState(["errors"]),
    ...mapState("regencies", {
      province: (state) => state.province,
      kota: (state) => state.kota,
    }),
    ...mapState("member", {
      loading: (state) => state.loading,
      tabContent: (state) => state.tabContent,
      history_booking_service: (state) => state.history_booking_service,
      history_trade_in: (state) => state.history_trade_in,
      history_test_drive: (state) => state.history_test_drive,
      history_booking_order: (state) => state.history_booking_order,
      order_id: (state) => state.order_id,
      bank: (state) => state.bank,
      form_confirm_payment: (state) => state.form_confirm_payment,
      form_edit_profile: (state) => state.form_edit_profile,
      profile: (state) => state.profile,
      inbox: (state) => state.inbox,
    }),
    ...mapState("auth", {
      message_rangka: (state) => state.message_rangka,
    }),

    tabContent: {
      get() {
        return this.$store.state.member.tabContent;
      },
      set(val) {
        this.$store.commit("member/SET_TAB_CONTENT", val);
      },
    },
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>