import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get car by seo
    tabContent: 1,
    history_booking_service: [],
    detail_booking_service_by_id: [],

    history_trade_in: [],
    detail_trade_in_by_id: [],

    history_test_drive: [],
    detail_test_drive_by_id: [],

    history_booking_order: [],
    detail_booking_by_id: [],
    inbox: [],

    profile: [],

    order_id: [],
    bank: [],
    form_confirm_payment: {
        token: "",
        email: "",
        no_order: "",
        bank_anda: "",
        nama_rekening_anda: "",
        nomor_rekening_anda: "",
        bank_tujuan: "Bank Mandiri - 135.000.8077.990 a/n PT. New Ratna Motor",
        methode_transfer: "",
        nominal: 0,
        tanggal: new Date(),
        bukti_transfer: ""
    },
    form_edit_profile: {
        token: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        gender: "",
        birth: new Date(),
        province: "",
        province_name: "",
        city: "",
        city_name: "",
        password: "",
        confirm_password: "",
        no_rangka: "",
        no_rangka_status: "",
        image: ""
    }
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.tabContent = 1,
            state.history_booking_service = [],
            state.detail_booking_service_by_id = [],
            state.history_trade_in = [],
            state.detail_trade_in_by_id = [],
            state.history_test_drive = [],
            state.detail_test_drive_by_id = [],
            state.history_booking_order = [],
            state.detail_booking_by_id = [],
            state.inbox = [],
            state.profile = [],
            state.order_id = [],
            state.bank = [],
            state.form_confirm_payment = {
                token: "",
                email: "",
                no_order: "",
                bank_anda: "",
                nama_rekening_anda: "",
                nomor_rekening_anda: "",
                bank_tujuan: "Bank Mandiri - 135.000.8077.990 a/n PT. New Ratna Motor",
                methode_transfer: "",
                nominal: 0,
                tanggal: new Date(),
                bukti_transfer: ""
            },
            state.form_edit_profile = {
                token: "",
                name: "",
                email: "",
                phone: "",
                address: "",
                gender: "",
                birth: new Date(),
                province: "",
                province_name: "",
                city: "",
                city_name: "",
                password: "",
                confirm_password: "",
                no_rangka: "",
                no_rangka_status: "",
                image: ""
            }
    },
    CLEAR_FORM(state) {
        state.form_confirm_payment = {
            token: "",
            email: "",
            no_order: "",
            bank_anda: "",
            nama_rekening_anda: "",
            nomor_rekening_anda: "",
            bank_tujuan: "Bank Mandiri - 135.000.8077.990 a/n PT. New Ratna Motor",
            methode_transfer: "",
            nominal: 0,
            tanggal: new Date(),
            bukti_transfer: ""
        }
    },
    CLEAR_FORM_UPDATE_PROFILE(state) {
        state.form_edit_profile = {
            token: "",
            name: "",
            email: "",
            phone: "",
            address: "",
            gender: "",
            birth: new Date(),
            province: "",
            province_name: "",
            city: "",
            city_name: "",
            password: "",
            confirm_password: "",
            no_rangka: "",
            no_rangka_status: "",
            image: ""
        }
    },

    ASSIGN_DATA_PROFILE(state, payload) {
        state.profile = payload
    },
    ASSIGN_DATA_BOOKING_SERVICE(state, payload) {
        state.history_booking_service = payload
    },
    ASSIGN_DATA_TRADE_IN(state, payload) {
        state.history_trade_in = payload
    },
    ASSIGN_DATA_TEST_DRIVE(state, payload) {
        state.history_test_drive = payload
    },
    ASSIGN_DATA_BOOKING_ORDER(state, payload) {
        state.history_booking_order = payload
    },
    ASSIGN_DATA_BOOKING_ORDER_BY_ID(state, payload) {
        state.detail_booking_by_id = payload
    },
    ASSIGN_DATA_BOOKING_SERVICE_BY_ID(state, payload) {
        state.detail_booking_service_by_id = payload
    },
    ASSIGN_DATA_TRADE_IN_BY_ID(state, payload) {
        state.detail_trade_in_by_id = payload
    },
    ASSIGN_DATA_TEST_DRIVE_BY_ID(state, payload) {
        state.detail_test_drive_by_id = payload
    },
    ASSIGN_DATA_ORDER_BY_ID(state, payload) {
        state.order_id = payload
    },
    ASSIGN_DATA_BANK(state, payload) {
        state.bank = payload
    },
    ASSIGN_DATA_INBOX(state, payload) {
        state.inbox = payload
    },
    SET_TAB_CONTENT(state, payload) {
        state.tabContent = payload;
    },
};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },

    getProfile({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`memberprofile`, {
                token: payload.token,
                member_email: payload.member_email,
                unique_id: payload.unique_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_PROFILE', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    UpdateProfile({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`updateprofile`, {
                token: state.form_edit_profile.token,
                name: state.form_edit_profile.name,
                email: state.form_edit_profile.email,
                phone: state.form_edit_profile.phone,
                address: state.form_edit_profile.address,
                gender: state.form_edit_profile.gender,
                birth: state.form_edit_profile.birth,
                province: state.form_edit_profile.province,
                city: state.form_edit_profile.city,
                password: state.form_edit_profile.password,
                no_rangka: state.form_edit_profile.no_rangka,
                image: state.form_edit_profile.image
            })
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })

                    commit('CLEAR_FORM_UPDATE_PROFILE')
                    localStorage.removeItem('token');
                    localStorage.removeItem('sharedPreference');
                    //set localstorage
                    localStorage.setItem('token', response.data.data.data.token)
                    localStorage.setItem('sharedPreference', JSON.stringify(response.data.data.data));
                    commit('SET_TOKEN', response.data.data.data.token, {
                        root: true
                    })
                    commit('SET_SHARED_PREFERENCE', response.data.data.data, {
                        root: true
                    })

                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getHistoryBookingService({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`history/bookingservice`, {
                token: payload.token,
                member_email: payload.member_email,
                unique_id: payload.unique_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_BOOKING_SERVICE', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getHistoryTradeIn({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`history/tradein`, {
                token: payload.token,
                member_email: payload.member_email,
                unique_id: payload.unique_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_TRADE_IN', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getHistoryTestDrive({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`history/testdrive`, {
                token: payload.token,
                member_email: payload.member_email,
                unique_id: payload.unique_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_TEST_DRIVE', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getHistoryBookingOrder({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`history/bookingorder`, {
                token: payload.token,
                member_email: payload.member_email,
                unique_id: payload.unique_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_BOOKING_ORDER', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getDetailBookingOrderById({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`detail/bookingorder?id=${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_BOOKING_ORDER_BY_ID', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getDetailBookingServiceById({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`detail/bookingservice?id=${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_BOOKING_SERVICE_BY_ID', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getDetailTradeById({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`detail/tradein?id=${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_TRADE_IN_BY_ID', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getDetailTestById({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`detail/testdrive?id=${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_TEST_DRIVE_BY_ID', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getOrderId({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`member/orderid?unique_id=${payload}`)
                .then((response) => {
                    commit('ASSIGN_DATA_ORDER_BY_ID', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getBank({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.get(`member/bank`)
                .then((response) => {
                    commit('ASSIGN_DATA_BANK', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    SendConfirmPayment({
        commit,
        state
    }, payload) {
        commit('SET_SAVE', true, {
            root: true
        })
        return new Promise((resolve, reject) => {
            $axios.post(`member/payment`, state.form_confirm_payment)
                .then((response) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    commit('CLEAR_FORM')
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_SAVE', false, {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    getInbox({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            state.loading = true;
            $axios.post(`member/inbox`, {
                token: payload.token,
                email: payload.member_email,

            })
                .then((response) => {
                    commit('ASSIGN_DATA_INBOX', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
