import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    // form compare car 
    compare_car: {
        car_category_1: "",
        car_model_1: "",
        car_transmission_1: "",
        car_type_1: "",
        car_category_2: "",
        car_model_2: "",
        car_transmission_2: "",
        car_type_2: ""
    },
    result_car_1: {
        image: "",
        price: ""
    },
    result_car_2: {
        image: "",
        price: ""
    },

    result_dimensi: [],
    result_chasis: [],
    result_mesin: [],

    // car detail 1
    car_category_1: [],
    car_model_1: [],
    car_type_1: [],
    // car detai2
    car_category_2: [],
    car_model_2: [],
    car_type_2: [],
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.compare_car = {
                car_category_1: "",
                car_model_1: "",
                car_transmission_1: "",
                car_type_1: "",
                car_category_2: "",
                car_model_2: "",
                car_transmission_2: "",
                car_type_2: ""
            },
            state.result_car_1 = {
                image: "",
                price: ""
            },
            state.result_car_2 = {
                image: "",
                price: ""
            },

            state.result_dimensi = [],
            state.result_chasis = [],
            state.result_mesin = [],

            // car detail 1
            state.car_category_1 = [],
            state.car_model_1 = [],
            state.car_type_1 = [],
            // car detai2
            state.car_category_2 = [],
            state.car_model_2 = [],
            state.car_type_2 = []
    },
    CLEAR_STATE_RESULT_1(state) {
        state.result_car_1 = {
            image: "",
            price: ""
        }
    },
    CLEAR_STATE_RESULT_2(state) {
        state.result_car_2 = {
            image: "",
            price: ""
        }
    },
    // car detail 1 
    ASSIGN_DATA_CAR_CATEGORY_1(state, payload) {
        state.car_category_1 = payload
    },
    ASSIGN_DATA_CAR_MODEL_1(state, payload) {
        state.car_model_1 = payload
    },
    ASSIGN_DATA_CAR_TYPE_1(state, payload) {
        state.car_type_1 = payload
    },

    // car detail 2
    ASSIGN_DATA_CAR_CATEGORY_2(state, payload) {
        state.car_category_2 = payload
    },
    ASSIGN_DATA_CAR_MODEL_2(state, payload) {
        state.car_model_2 = payload
    },
    ASSIGN_DATA_CAR_TYPE_2(state, payload) {
        state.car_type_2 = payload
    },

    ASSIGN_RESULT_CAR(state, payload) {
        state.result_car = payload;
    },
    ASSIGN_RESULT_DIMENSI(state, payload) {
        state.result_dimensi = payload;
    },
    ASSIGN_RESULT_CHASIS(state, payload) {
        state.result_chasis = payload;
    },
    ASSIGN_RESULT_MESIN(state, payload) {
        state.result_mesin = payload;
    },

};
const actions = {
    // Destroy State
    Clear_State({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    Clear_State_Result_1({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE_RESULT_1");
        });
    },
    Clear_State_Result_2({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE_RESULT_2");
        });
    },
    //insert Compare Car
    submitResult({ commit, state }) {
        commit("SET_SAVE", true, {
            root: true,
        });
        return new Promise((resolve, reject) => {
            $axios
                .post(`comparison`, state.compare_car)
                .then((response) => {
                    commit("SET_SAVE", false, {
                        root: true,
                    });
                    commit('ASSIGN_RESULT_DIMENSI', response.data.data.dimensi)
                    commit('ASSIGN_RESULT_CHASIS', response.data.data.chassis)
                    commit('ASSIGN_RESULT_MESIN', response.data.data.mesin)
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("SET_SAVE", false, {
                        root: true,
                    });
                    if (error.response.status == 401) {
                        commit("SET_ERRORS", error.response.data.message, {
                            root: true,
                        });
                    } else if (error.response.status == 422) {
                        commit("SET_ERRORS", "Periksa kembali semua form", {
                            root: true,
                        });
                    } else if (error.response.status == 400) {
                        commit("SET_ERRORS", "Server Bermasalah", {
                            root: true,
                        });
                    }
                    reject(error);
                });
        });
    },
    //car detail
    getCarCategory1({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`carcategory`)
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_CATEGORY_1', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarModel1({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`carmodel`, {
                category_id: payload.category_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_MODEL_1', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarType1({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`comparison/type`, {
                car_model_id: payload.car_model_id,
                car_transmission: payload.car_transmission
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_TYPE_1', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },

    // car detail 2 
    getCarCategory2({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`carcategory`)
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_CATEGORY_2', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarModel2({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`carmodel`, {
                category_id: payload.category_id
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_MODEL_2', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
    getCarType2({
        commit,
        state
    }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`comparison/type`, {
                car_model_id: payload.car_model_id,
                car_transmission: payload.car_transmission
            })
                .then((response) => {
                    commit('ASSIGN_DATA_CAR_TYPE_2', response.data.data)
                    resolve(response.data)
                }).catch((error) => {
                    commit('SET_ERRORS', "Server Bermasalah", {
                        root: true
                    })
                    reject(error)
                })
        })
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
