<template>
  <div>
    <section class="section-lg dashboard-member dashboard-step bg-white mh-93">
      <div class="container">
        <div class="wrap-content pb-2">
          <h3 class="section-heading-xs">Detail Order:</h3>
        </div>

        <div class="wrap-content pb-2">
          <div class="row">
            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li>
                  <label class="w-100 d-block mb-0 font-weight-bold">{{
                    isSharedPreference.full_name
                  }}</label>
                </li>
                <li>
                  <label class="w-100 d-block mb-0">{{
                    isSharedPreference.member_email
                  }}</label>
                </li>
                <li>
                  <label class="w-100 d-block mb-0">{{
                    isSharedPreference.phone
                  }}</label>
                </li>
                <li>
                  <label class="w-100 d-block mb-0">{{
                    isSharedPreference.province_name
                  }}</label>
                </li>
                <li>
                  <label class="w-100 d-block mb-0">{{
                    isSharedPreference.city_name
                  }}</label>
                </li>
                <li>
                  <label class="w-100 d-block mb-0">{{
                    isSharedPreference.address
                  }}</label>
                </li>
              </ul>
            </div>

            <div class="col-lg-6">
              <ul class="list-info-style flex">
                <li class="text-lg-right">
                  <label class="w-100 d-block font-weight-bold">{{
                    detail_booking_by_id.booking_id
                  }}</label>
                </li>
                <li class="text-lg-right">
                  <label class="w-100 d-block">{{
                    detail_booking_by_id.booking_date | myDate
                  }}</label>
                </li>
                <li class="text-lg-right">
                  <label class="w-100 d-block">
                    <span
                      class="badge badge-success px-3 w-auto"
                      v-if="detail_booking_by_id.status == 'Paid'"
                    >
                      {{ detail_booking_by_id.status }}
                    </span>
                    <span
                      class="badge badge-warning px-3 w-auto"
                      v-if="detail_booking_by_id.status == 'Unpaid'"
                    >
                      {{ detail_booking_by_id.status }}
                    </span>
                  </label>
                </li>
              </ul>
            </div>

            <div class="col-md-12 mt-3">
              <div class="table-responsive">
                <table class="table table-style">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Car Model</th>
                      <th scope="col">Car Color</th>
                      <th scope="col">Car Type</th>
                      <th scope="col">Car Price</th>
                      <th scope="col">Branch</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ detail_booking_by_id.car_model }}</td>
                      <td>{{ detail_booking_by_id.car_color }}</td>
                      <td>{{ detail_booking_by_id.car_type }}</td>
                      <td>{{ detail_booking_by_id.price_otr | currency }}</td>
                      <td>{{ detail_booking_by_id.branch_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-5 offset-lg-7">
              <ul class="list-info-style flex border-top">
                <li class="border-bottom pt-2 pb-1 mb-0">
                  <label>Payment Type</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.payment_type
                  }}</span>
                </li>
                <li class="border-bottom pt-2 pb-1 mb-0">
                  <label>Sub Total</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.price_otr | currency
                  }}</span>
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="detail_booking_by_id.payment_type == 'kredit'"
                >
                  <label>Down Payment</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.uang_muka | currency
                  }}</span>
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="detail_booking_by_id.payment_type == 'kredit'"
                >
                  <label>Tenor</label>
                  <span class="font-weight-500 text-right"
                    >{{ detail_booking_by_id.tenor }}x</span
                  >
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="detail_booking_by_id.payment_type == 'kredit'"
                >
                  <label>Angsuran</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.angsuran | currency
                  }}</span>
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="
                    detail_booking_by_id.kode_voucher != '' &&
                    detail_booking_by_id.payment_type == 'kredit'
                  "
                >
                  <label
                    >Potongan Angsuran Voucher
                    <strong>
                      {{ detail_booking_by_id.kode_voucher }}
                    </strong>
                  </label>
                  <span class="font-weight-500 text-right text-accent">
                    - {{ detail_booking_by_id.discount | currency }}</span
                  >
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="
                    detail_booking_by_id.kode_voucher != '' &&
                    detail_booking_by_id.payment_type == 'cash'
                  "
                >
                  <label
                    >Potongan Voucher
                    <strong>
                      {{ detail_booking_by_id.kode_voucher }}
                    </strong>
                  </label>
                  <span class="font-weight-500 text-right text-accent">
                    - {{ detail_booking_by_id.discount | currency }}</span
                  >
                </li>

                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="
                    detail_booking_by_id.payment_type == 'kredit' &&
                    detail_booking_by_id.angsuran_promo != 0
                  "
                >
                  <label>Total Angsuran</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.angsuran_promo | currency
                  }}</span>
                </li>
                <li
                  class="border-bottom pt-2 pb-1 mb-0"
                  v-if="detail_booking_by_id.payment_type == 'cash'"
                >
                  <label>Subtotal </label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.subtotal | currency
                  }}</span>
                </li>
                <li class="border-bottom pt-2 pb-1 mb-0 bg-light">
                  <label>Booking Fee</label>
                  <span class="font-weight-500 text-right">{{
                    detail_booking_by_id.booking_fee | currency
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "DetailBookingOrder",
  metaInfo() {
    return {
      title:
        "Detail Booking Order - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title:
        "Detail Booking Order - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState("member", {
      detail_booking_by_id: (state) => state.detail_booking_by_id,
      loading: (state) => state.loading,
    }),
    ...mapGetters(["isSharedPreference"]),
  },
  methods: {
    ...mapActions("member", ["getDetailBookingOrderById"]),
  },
  created() {
    this.getDetailBookingOrderById(this.$route.params.id);
    window.scrollTo(0, 0);
  },
};
</script>