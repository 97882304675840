<template>
  <div>
    <!-- slider  -->
    <section class="intro-profile-dealer">
      <div class="slider-container profile-dealer-slider-container">
        <div
          class="owl-carousel owl-theme profile-dealer-slider"
          v-if="load_slider"
        >
          <div
            class="owl-item"
            v-for="(row_slider, index_slider) in owl_slider"
            :key="'sl' + index_slider"
          >
            <img
              :data-src="row_slider.image"
              class="img-fluid lazyload img-slider"
            />
          </div>
        </div>

        <div class="nav-slider trans-ease-in-out profile-dealer-slider-prev">
          <span class="ion-chevron-left"></span>
        </div>
        <div class="nav-slider trans-ease-in-out profile-dealer-slider-next">
          <span class="ion-chevron-right"></span>
        </div>
        <div class="slider-dots-style profile-dealer-slider-dots">
          <div class="owl-dot"><span></span></div>
          <div class="owl-dot"><span></span></div>
          <div class="owl-dot active"><span></span></div>
        </div>
      </div>
    </section>
    <!-- service  -->
    <section class="serve-section bg-white section-md pb-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 m-auto mb-4">
            <div class="box-section-title text-center">
              <h2 class="section-heading">Our <span>Service</span></h2>
            </div>
          </div>
        </div>

        <div class="flex-4-column justify-content-start">
          <div
            class="flex-col"
            v-for="(row_icon, index_icon) in icon_by_id"
            :key="'ic' + index_icon"
          >
            <div class="card card-style rounded trans-ease-in-out">
              <div class="card-body text-center">
                <div
                  class="icon icon-lg yellow rounded m-auto trans-ease-in-out"
                >
                  <img
                    :src="row_icon.image"
                    alt=""
                    class="img-fluid lazyloaded"
                  />
                </div>
                <h4 class="section-description font-weight-bold mb-0 mt-2">
                  {{ row_icon.title }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Nea  -->
    <section class="section-md profile-dealer">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="box-section-title text-lg-left text-center">
              <h2 class="section-heading-xs">Locations</h2>
            </div>
            <div
              v-for="(row_maps, index_maps) in maps_by_id"
              :key="'maps' + index_maps"
            >
              <iframe
                :src="row_maps.maps"
                allowfullscreen=""
                loading="lazy"
                class="maps-iframe-style"
              ></iframe>
            </div>
          </div>

          <div class="col-md-5 mt-md-0 mt-5">
            <div class="box-section-title text-lg-left text-center">
              <h2 class="section-heading-xs">
                Contact <span>Information</span>
              </h2>
            </div>

            <ul
              class="list-unstyled list-info"
              v-for="(row_contact, index_contact) in contact_by_id"
              :key="'ct' + index_contact"
            >
              <li>
                <i class="fa fa-phone"></i><span> {{ row_contact.phone }}</span>
              </li>
              <li>
                <i class="fab fa-whatsapp"></i
                ><span> {{ row_contact.whatsapp }}</span>
              </li>
              <li>
                <i class="fa fa-envelope"></i
                ><span> {{ row_contact.email }}</span>
              </li>
              <li>
                <i class="fa fa-map-marker-alt"></i
                ><span> {{ row_contact.alamat }}</span>
              </li>
            </ul>

            <div class="box-section-title text-lg-left text-center">
              <h2 class="section-heading-xs">
                Nasmoco <span>Emergency Assistance</span>
              </h2>
            </div>

            <div
              v-for="(row_nea, index_nea) in nea_by_id"
              :key="'nea' + index_nea"
            >
              <img class="img-fluid lazyloaded" :src="row_nea.image" />
            </div>
          </div>

          <div class="col-md-12">
            <div class="spesial-offer section-sm pb-lg-3">
              <div class="box-section-title text-center mb-0">
                <h2 class="section-heading-sm mb-0">Event & Promo</h2>
              </div>

              <div class="spesial-offer-slider-container">
                <div
                  class="owl-carousel owl-theme spesial-offer-slider"
                  v-if="loaded_special_offers"
                >
                  <div
                    class="items"
                    v-for="(row_promo, index_promo) in owl_special_offer"
                    :key="'pr' + index_promo"
                  >
                    <div class="overlay">
                      <img
                        :src="row_promo.image"
                        alt=""
                        class="img-fluid lazyload"
                      />
                    </div>
                  </div>
                </div>

                <div class="nav-dots">
                  <div class="spesial-offer-slider-dots"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- CTA  -->
    <section class="compare-car light">
      <div class="head-compare-car">
        <div class="container">
          <div
            class="wrap-head-compare d-md-flex align-items-center justify-content-between"
          >
            <div
              class="box-section-title mb-lg-0 mb-3 text-lg-left text-center"
            >
              <h2 class="section-heading-sm text-white mb-0">
                Do you have some questions about our service?
              </h2>
            </div>
            <div
              class="text-lg-right text-center"
              v-for="(row_cta, index_cta) in contact_by_id"
              :key="'cta' + index_cta"
            >
              <a
                :href="'https://wa.me/' + row_cta.whatsapp"
                target="_blank"
                class="btn btn-padd btn-whatsapp py-3 px-4"
                ><i class="fab fa-whatsapp font-weight-200 mr-2"></i>WHATSAPP
                MARKETING</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Profile_Dealer",
  metaInfo() {
    return {
      title:
        "Profile Dealer - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {
      // slider
      load_slider: false,
      owl_slider: [],

      // special Offers
      loaded_special_offers: false,
      owl_special_offer: [],
    };
  },
  computed: {
    ...mapState("find_dealer", {
      slider_by_id: (state) => state.slider_by_id,
      loading_slider: (state) => state.loading_slider,
      icon_by_id: (state) => state.icon_by_id,
      loading_icon: (state) => state.loading_icon,
      nea_by_id: (state) => state.nea_by_id,
      loading_nea: (state) => state.loading_nea,
      maps_by_id: (state) => state.maps_by_id,
      loading_maps: (state) => state.loading_maps,
      contact_by_id: (state) => state.contact_by_id,
      loading_contact: (state) => state.loading_contact,
    }),
  },
  mounted() {
    var vm = this;
    // reloading slider
    this.getSliderByID(this.$route.params.link)
      .then((res) => {
        vm.owl_slider = res.data;
        if (vm.owl_slider.length > 0) {
          vm.load_slider = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselProfileSlider();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });

    // reloading Special Offers
    this.getPromoByID(this.$route.params.link)
      .then((res) => {
        vm.owl_special_offer = res.data;
        if (vm.owl_special_offer.length > 0) {
          vm.loaded_special_offers = true;
          vm.$nextTick(
            function () {
              vm.OwlCarouselSpecialOffers();
            }.bind(vm)
          );
        }
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  },

  methods: {
    ...mapActions("find_dealer", [
      "getSliderByID",
      "getIconByID",
      "getNeaByID",
      "getMapsByID",
      "getContactByID",
      "getPromoByID",
      "Clear_State",
    ]),
    // Owl Carousel Section
    OwlCarouselProfileSlider: function () {
      if ($(".profile-dealer-slider").length) {
        var profileDealerSlider = $(".profile-dealer-slider");
        profileDealerSlider.owlCarousel({
          loop: true,
          autoplay: true,
          speed: 3000,
          smartSpeed: 2000,
          items: 1,
          nav: false,
          dots: true,
          dotsContainer: ".profile-dealer-slider-dots",
          margin: 0,
          autoplayHoverPause: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
        });
        if ($(".profile-dealer-slider-prev").length) {
          var prev = $(".profile-dealer-slider-prev");
          prev.on("click", function () {
            profileDealerSlider.trigger("prev.owl.carousel");
          });
        }
        if ($(".profile-dealer-slider-next").length) {
          var next = $(".profile-dealer-slider-next");
          next.on("click", function () {
            profileDealerSlider.trigger("next.owl.carousel");
          });
        }
      }
    },

    // Special Offer
    OwlCarouselSpecialOffers: function () {
      if ($(".spesial-offer-slider").length) {
        var spesialofferSlider = $(".spesial-offer-slider");

        spesialofferSlider.owlCarousel({
          loop: true,
          center: true,
          autoplay: true,
          speed: 3800,
          autoplayTimeout: 8500,
          smartSpeed: 900,
          nav: false,
          autoWidth: false,
          dots: true,
          dotsContainer: ".spesial-offer-slider-dots",
          slidesToShow: 3,
          slidesToScroll: 1,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              margin: 20,
            },
            576: {
              items: 1,
              margin: 20,
            },
            768: {
              items: 3,
              margin: 10,
            },
            992: {
              items: 3,
              margin: 10,
            },
            1200: {
              items: 3,
              margin: 10,
            },
          },
        });
      }
    },
  },

  created() {
    this.getIconByID(this.$route.params.link);
    this.getNeaByID(this.$route.params.link);
    this.getMapsByID(this.$route.params.link);
    this.getContactByID(this.$route.params.link);
    window.scrollTo(0, 0);
  },
  destroyed() {
    this.Clear_State();
  },
};
</script>