<template>
  <div>
    <section class="intro-nasmoco-club">
      <img :src="nasmoco_club_id.banner" class="img-fluid img-cover" />
    </section>
    <section class="pricelist-section section-md pt-lg-0 section-lg-top--5">
      <div class="container">
        <div class="card card-style rounded-15">
          <div class="card-body px-4 py-3">
            <div class="near-us section-sm">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 text-center text-lg-left">
                    <img :src="nasmoco_club_id.logo" class="cover-video" />
                  </div>
                  <div class="col-lg-6">
                    <div class="box-section-title">
                      <h2 class="section-heading">Profile Club</h2>
                      <h5>
                        {{ nasmoco_club_id.club_name }}
                      </h5>
                      <p class="section-description-sm">
                        {{ nasmoco_club_id.club_profile }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NasmocoClubDetail",
  metaInfo() {
    return {
      title:
        "Nasmoco Club Detail - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("nasmoco_club", {
      nasmoco_club_id: (state) => state.nasmoco_club_id,
    }),
  },
  methods: {
    ...mapActions("nasmoco_club", ["getNasmocoClubByID"]),
  },
  created() {
    this.getNasmocoClubByID(this.$route.params.link);
    window.scrollTo(0, 0);
  },
  mounted() {},
};
</script>