<template>
  <section class="section-lg news" v-if="loading_car_id">
    <div class="container">
      <div class="row stickyfill-content">
        <div class="col-lg-8">
          <div class="content">
            <div class="breadcrumb-style">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
            </div>

            <h2 class="section-heading">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
            </h2>

            <div class="wrap-info-news d-flex justify-content-between">
              <p><b-skeleton animation="wave" width="85%"></b-skeleton></p>
              <p>
                <span class="ion ion-eye"></span>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
              </p>
            </div>

            <b-skeleton-img no-aspect height="200px"></b-skeleton-img>

            <div>
              <b-skeleton animation="wave" width="100%"></b-skeleton>
            </div>
            <!-- <ul class="share-sosial-media list-unstyled">
                <li>
                  <a
                    href="https://www.facebook.com/NasmocoWorld"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/NASMOCOworld"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nasmoco_world/"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nasmoco_world/"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-whatsapp"></i
                  ></a>
                </li>
              </ul> -->
          </div>
        </div>

        <div class="col-lg-4 pl-lg-4 mt-md-0 mt-5">
          <div class="news-sidebar" id="sticky">
            <h4 class="section-description font-weight-800">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
            </h4>

            <div class="card">
              <div class="card-body">
                <div
                  class="media"
                  v-for="(row, index) in news_read_populer"
                  :key="index"
                >
                  <p class="text-3">
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                  </p>

                  <div class="wrap-info-news d-flex justify-content-between">
                    <p class="section-description-xs mb-0">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </p>
                    <p>
                      <span class="ion ion-eye"></span>
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-lg news" v-else>
    <div class="container">
      <div class="row stickyfill-content">
        <div class="col-lg-8">
          <div class="content">
            <div class="breadcrumb-style">
              <ul>
                <li>Home</li>
                <li>News</li>
                <li class="active">{{ news_read_by_id.title }}</li>
              </ul>
            </div>

            <h2 class="section-heading">{{ news_read_by_id.title }}</h2>

            <div class="wrap-info-news d-flex justify-content-between">
              <p>{{ news_read_by_id.postdate | myDate }}</p>
              <p>
                <span class="ion ion-eye"></span>
                {{ news_read_by_id.view }} Views
              </p>
            </div>

            <img
              :src="news_read_by_id.image"
              class="img-fluid lazyload mt-2 mb-4"
            />

            <div v-html="news_read_by_id.text"></div>
            <!-- <ul class="share-sosial-media list-unstyled">
                <li>
                  <a
                    href="https://www.facebook.com/NasmocoWorld"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/NASMOCOworld"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nasmoco_world/"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nasmoco_world/"
                    target="_blank"
                    class="icon"
                    ><i class="fab fa-whatsapp"></i
                  ></a>
                </li>
              </ul> -->
          </div>
        </div>

        <div class="col-lg-4 pl-lg-4 mt-md-0 mt-5">
          <div class="news-sidebar" id="sticky">
            <h4 class="section-description font-weight-800">TRENDING</h4>

            <div class="card">
              <div class="card-body">
                <div
                  class="media"
                  v-for="(row, index) in news_read_populer"
                  :key="index"
                >
                  <router-link
                    :to="{
                      name: 'News_read',
                      params: { link: row.seo },
                    }"
                  >
                    <p class="text-3">
                      {{ row.text_preview }}
                    </p>
                  </router-link>
                  <div class="wrap-info-news d-flex justify-content-between">
                    <p class="section-description-xs mb-0">
                      {{ row.postdate | myDate }}
                    </p>
                    <p>
                      <span class="ion ion-eye"></span>
                      {{ row.view }} Views
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NewsRead",
  metaInfo() {
    return {
      title:
        "Detail News - Dealer Resmi Toyota Area Jawa Tengah dan Yogyakarta",
      meta: [
        {
          name: "description",
          content:
            "Dealer Resmi Toyota Semarang Yogyakarta -  Harga Mobil Toyota",
        },
        {
          name: "keywords",
          content:
            "Avanza, Innova, Agya, FT86, Rush, Fortuner, Calya, Toyota, Nasmoco, Mobil, booking",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("promo", {
      loading_news_id: (state) => state.loading_news_id,
      news_read_by_id: (state) => state.news_read_by_id,
      news_read_populer: (state) => state.news_read_populer,
    }),
  },
  methods: {
    ...mapActions("promo", ["getNewsByID"]),
  },
  created() {
    this.getNewsByID(this.$route.params.link);
    window.scrollTo(0, 0);
  },
};
</script>