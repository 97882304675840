import $axios from "../../services/api.js";
const state = () => ({
    loading: false,
    //get slider
    question: [],
    find_car: {
        question_1: "",
        question_1_title: "",
        question_2: "",
        question_2_title: "",
        question_3: "",
        question_3_title: "",
    },
    result_dream_car: [],
    result_total_dream_car: 0
});
const mutations = {
    CLEAR_STATE(state) {
        state.loading = false,
            state.result_dream_car = [],
            state.result_total_dream_car = 0
    },
    ASSIGN_DATA_QUESTION(state, payload) {
        state.question = payload;
    },
    ASSIGN_DREAM_CAR(state, payload) {
        state.result_dream_car = payload;
    },
    ASSIGN_DREAM_CAR_TOTAL(state, payload) {
        state.result_total_dream_car = payload;
    },
    CLEAR_CAR_DATA(state) {
        state.result_dream_car = []
    }
};
const actions = {
    // Destroy State
    Clear_State_Nasmoco_Journey({ commit }) {
        return new Promise(() => {
            commit("CLEAR_STATE");
        });
    },
    // get Question
    getQuestion({
        commit,
        state
    }, payload) {
        //MENGECEK PAYLOAD ADA ATAU TIDAK
        return new Promise((resolve, reject) => {
            //REQUEST DATA DENGAN ENDPOINT /LIST ITEMS
            state.loading = true;
            $axios.get(`question`)
                .then((response) => {
                    //SIMPAN DATA KE STATE MELALUI MUTATIONS
                    commit('ASSIGN_DATA_QUESTION', response.data.data)
                    state.loading = false;
                    resolve(response.data)
                })
        })
    },
    //insert Konsinyasi Barang
    submitResult({ commit, state }) {
        commit("SET_SAVE", true, {
            root: true,
        });
        return new Promise((resolve, reject) => {
            $axios
                .post(`question/result`, {
                    question_1: state.find_car.question_1,
                    question_2: state.find_car.question_2,
                    question_3: state.find_car.question_3
                })
                .then((response) => {
                    commit("SET_SAVE", false, {
                        root: true,
                    });
                    commit('CLEAR_CAR_DATA')
                    commit('ASSIGN_DREAM_CAR', response.data.data.result)
                    commit('ASSIGN_DREAM_CAR_TOTAL', response.data.data.result_count)
                    resolve(response.data);
                })
                .catch((error) => {
                    commit("SET_SAVE", false, {
                        root: true,
                    });
                    if (error.response.status == 401) {
                        commit("SET_ERRORS", error.response.data.message, {
                            root: true,
                        });
                    } else if (error.response.status == 422) {
                        commit("SET_ERRORS", "Periksa kembali semua form", {
                            root: true,
                        });
                    } else if (error.response.status == 400) {
                        commit("SET_ERRORS", "Server Bermasalah", {
                            root: true,
                        });
                    }
                    reject(error);
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
